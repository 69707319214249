import React from "react";
import {
  Route,  Routes, Navigate,
  HashRouter,
} from "react-router-dom";
import {
  SideNavigationContainer,
  TopNavigationContainer,
} from "./shared/components/AppNavigation";

import redirect from "./hoc/redirect";
import Playground from "./modules/playground/Playground";
import GroupChat from "./modules/tickets/components/GroupChat/GroupChatContainer";
import NotFound from "./scenes/notFound/NotFound";
import { Signin, Logout } from "./scenes/authForms";
import { Tickets, TicketDetails } from "./scenes/tickets";

const RedirectedSignin = redirect(Signin);

const RoutesComponent = () => (
  <HashRouter>
    <Routes>
      <Route path="/" element={<Navigate to="/ticket" />} />
      <Route path="/signin" element={<RedirectedSignin />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/playground" element={<Playground />} />
      <Route path="/group-chat" element={<GroupChat />} />
      <Route path="*" element={<NotFound />} />
      <Route path="/ticket" element={
        <div className="main_template">
          <TopNavigationContainer />
          <div className="app_content_section">
            <SideNavigationContainer />
            <Tickets />
          </div>
        </div>
      } />
      <Route path="/ticket/:code/:conversationid" element={
        <div className="main_template">
          <TopNavigationContainer />
          <div className="app_content_section">
            <SideNavigationContainer />
            <TicketDetails />
          </div>
        </div>
      } />
    </Routes>
  </HashRouter>
);

export default RoutesComponent;
