import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ActivityTracker from './ActivityTracker';
import { actions as formActions } from '../../../modules/authForms';
import { hasMobileDevice, noop } from '../../../utils';
import {
  activityTrackerTotalTimeUpdateIntervalMilliSeconds,
} from '../../../config';

const ActivityTrackerContainer = React.memo(({
  setWorkingHoursDialog,
  timeTrackingStopped,
  setTimeTrackingForMobile,
  setTimeTracking,
  setLastPauseTime,
  getTotalTime,
  setPauseTracking,
  pauseTracking,
}) => {
  const fetchTimeTracking = () => {
    if (hasMobileDevice()) {
      setTimeTrackingForMobile();
    } else {
      // if (window.timeTrackingInterval) { clearTimeout(window.timeTrackingInterval); }
      setTimeTracking().then(() => {
        console.log('54-> ActivityTrackerContainer #fetchTimeTracking');
      }).catch(() => {
        console.log('54-> ActivityTrackerContainer #fetchTimeTracking');
      });
    }
  };

  const fetchTotalTime = () => {
    if (window.getTimeTrackingInterval) { clearTimeout(window.getTimeTrackingInterval); }
    getTotalTime().then(() => {
      window.getTimeTrackingInterval = setTimeout(() => fetchTotalTime(),
        activityTrackerTotalTimeUpdateIntervalMilliSeconds);
    });
  };

  const fetchTimeTrackingInterval = () => {
    console.log('Time-> fetchTimeTrackingInterval called');
    fetchTimeTracking();
    fetchTotalTime();
  };

  const handleTimeTracking = (e) => {
    if (e && e.altKey) {
      setWorkingHoursDialog(true);
    } else {
      console.log('Time-> handleTimeTracking pauseTracking', { pauseTracking: !pauseTracking });
      fetchTimeTracking();
      setPauseTracking(!pauseTracking);
      if (!pauseTracking) {
        console.log('Time-> handleTimeTracking if pauseTracking = true', { pauseTracking: !pauseTracking });
        setLastPauseTime();
        // clearTimeout(window.timeTrackingInterval);
        clearTimeout(window.getTimeTrackingInterval);
      } else {
        console.log('Time-> handleTimeTracking if pauseTracking = false', { pauseTracking: !pauseTracking });
        // clearTimeout(window.timeTrackingInterval);
        clearTimeout(window.getTimeTrackingInterval);
        fetchTotalTime();
      }
    }
  };

  useEffect(() => {
    fetchTimeTrackingInterval();
    return () => {
      // clearTimeout(window.timeTrackingInterval);
      clearTimeout(window.getTimeTrackingInterval);
    };
  }, []);

  return (
    <ActivityTracker
      handleTimeTracking={handleTimeTracking}
      timeTrackingStopped={timeTrackingStopped}
    />
  );
});

ActivityTrackerContainer.propTypes = {
  timeTrackingStopped: PropTypes.bool,
  pauseTracking: PropTypes.bool,
  setTimeTracking: PropTypes.func,
  setTimeTrackingForMobile: PropTypes.func,
  getTotalTime: PropTypes.func,
  setWorkingHoursDialog: PropTypes.func,
  setLastPauseTime: PropTypes.func,
  setPauseTracking: PropTypes.func,
};

ActivityTrackerContainer.defaultProps = {
  timeTrackingStopped: false,
  pauseTracking: false,
  setTimeTracking: noop,
  setTimeTrackingForMobile: noop,
  getTotalTime: noop,
  setWorkingHoursDialog: noop,
  setLastPauseTime: noop,
  setPauseTracking: noop,
};

const mapStateToProps = (state) => ({
  timeTrackingStopped: state.auth.timeTrackingStopped,
  pauseTracking: state.auth.pauseTracking,
});

const mapDispatchToProps = (dispatch) => ({
  setLastPauseTime: () => dispatch(formActions.setLastPauseTime()),
  setPauseTracking: (isPause) => dispatch(formActions.setPauseTracking(isPause)),
  setTimeTracking: () => dispatch(formActions.setTimeTracking()),
  setTimeTrackingForMobile: () => dispatch(formActions.setTimeTrackingForMobile()),
  getTotalTime: () => dispatch(formActions.getTotalTime()),
  setWorkingHoursDialog: (isVisible) => dispatch(formActions.setWorkingHoursDialog(isVisible)),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(ActivityTrackerContainer);
