/*eslint-disable no-unused-vars*/
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import BlockUi from '@availity/block-ui';

import TicketsTableMobile from './TicketTable/Mobile/TicketTableMobileContainer';
import TicketsDesktopTable from './TicketTable/Desktop/TicketTableContainer';
import { SpinnerLoader } from '../../../../shared/components/Loader';

const TicketListView = ({
  ticketLoading,
  deviceType,
  gridData,
  users,
}) => {


  return (
  <div className="tickets_right_section">
    <div className="tickets_right_container">
      <div className="tickets-grid-table">
        <BlockUi
          message=" "
          tag="div"
          blocking={ticketLoading && gridData.length === 0}
          renderChildren={false}
          className="full_height full_width block-ui-background"
          loader={<SpinnerLoader />}
        >
          {deviceType === 'desktop' && (
            <>
              {!ticketLoading && gridData.length > 0 ? (
                <TicketsDesktopTable gridData={gridData} />
              ) : (
                <p className="nodata_found">No Data available</p>
              )}
            </>
          )}
          {deviceType === 'mobile' && (
            <>
              {!ticketLoading && gridData.length > 0 ? (
                <TicketsTableMobile gridData={gridData} users={users} />
              ) : (
                <p className="nodata_found">No Data available</p>
              )}
            </>
          )}
        </BlockUi>
      </div>
    </div>
  </div>
);
};

TicketListView.propTypes = {
  deviceType: PropTypes.string,
  ticketLoading: PropTypes.bool,
  gridData: PropTypes.instanceOf(Array),
  users: PropTypes.instanceOf(Array),
};

TicketListView.defaultProps = {
  deviceType: '',
  ticketLoading: false,
  gridData: [],
  users: [],
};

export default TicketListView;
