import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import base64 from 'base-64';
import moment from 'moment';
import { connect } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import * as ticketActions from '../redux/actions';
import Tickets from './Tickets';
import useHotkeys from '../../../utils/useHotkeys';
import api from '../../../utils/api';
import { actions } from '../../authForms';
import { noop, deepCopy, keyGeneration } from '../../../utils';

import './tickets_container.scss';

const TicketListContainer = React.memo(({
  setPrevActiveTabInfo,
  setGroupChatLoading,
  getChatPreviewInfo,
  setGroupChatWidth,
  setTypingMessage,
  lastActiveTabKey,
  setChatInfoList,
  removeActiveTab,
  maxDateChanged,
  setGroupChatId,
  activeTabsInfo,
  setActiveTabs,
  groupChatInfo,
  getUserInfo,
  setMaxDate,
  chatInfo,
  children,
  userInfo,
  users,
}) => {
  const navigate = useNavigate();
  const matchParams = useParams();
  const location = useLocation();

  const [isStateLoading, setStateLoading] = useState(false);
  const [maxDateState, setMaxDateState] = useState('');
  const { search, pathname } = location;

  const keygen = keyGeneration(search);

  const handleActiveTab = (tabItem) => {
    if (tabItem.code) {
      navigate(`/ticket/${tabItem.code}/${tabItem.conversationid}`);
    } else if (tabItem.meta === 'd') {
      if (tabItem.lastDays) {
        navigate(`/ticket?q=${tabItem.subject}&status=${tabItem.status || ''}&agentid=${tabItem.agentid || ''}&unassigned=${tabItem.unassigned}&invert=${tabItem.invert}&date_start=${tabItem.dateStart}&date_end=${tabItem.dateEnd}&meta=d&tab_name=${tabItem.tabName || ''}&last_x_days=${tabItem.lastDays || ''}`);
      } else {
        navigate(`/ticket?q=${tabItem.subject}&status=${tabItem.status || ''}&agentid=${tabItem.agentid || ''}&unassigned=${tabItem.unassigned}&invert=${tabItem.invert}&date_start=${tabItem.dateStart}&date_end=${tabItem.dateEnd}&meta=d&tab_name=${tabItem.tabName || ''}`);
      }
    } else if (tabItem.meta === 'nd') {
      navigate(`/ticket?q=${tabItem.subject}&status=${tabItem.status || ''}&agentid=${tabItem.agentid || ''}&unassigned=${tabItem.unassigned}&invert=${tabItem.invert}&meta=nd&tab_name=${tabItem.tabName || ''}`);
    } else {
      navigate(`/ticket?q=${tabItem.subject}&tab_name=${tabItem.tabName || ''}`);
    }
  };

  const setActivetabUrlInfo = (tabInfo) => {
    if (tabInfo.type === 'ticket') {
      handleActiveTab({ code: tabInfo.code, conversationid: tabInfo.conversationid });
    } else if (tabInfo.type === 'search' && tabInfo.meta) {
      handleActiveTab({
        subject: tabInfo.subject,
        meta: tabInfo.meta,
        status: tabInfo.status,
        agentid: tabInfo.agentid,
        unassigned: tabInfo.unassigned,
        invert: tabInfo.invert,
        dateStart: tabInfo.dateStart,
        dateEnd: tabInfo.dateEnd,
        tabItem: tabInfo.tabName,
        lastDays: tabInfo.lastDays,
      });
    } else {
      handleActiveTab({ subject: tabInfo.subject, tabName: tabInfo.tabName });
    }
  };

  const tabRedirectHandler = (key) => {
    if (lastActiveTabKey) {
      if (!search && pathname === '/ticket') {
        navigate('/ticket');
      } else if (lastActiveTabKey && lastActiveTabKey === `${userInfo.agentid}HomeTab`) {
        navigate('/ticket');
      } else if (lastActiveTabKey && lastActiveTabKey !== key) {
        const activeTabs = deepCopy(activeTabsInfo);
        const tab = activeTabs.find((tabItem) => tabItem.key === lastActiveTabKey);
        if (tab) {
          setActivetabUrlInfo(tab);
        } else {
          navigate('/ticket');
        }
      } else {
        navigate('/ticket');
      }
    } else {
      navigate('/ticket');
    }
  };

  const handleCloseTab = (key, type, lastTab, tabsInfo) => {
    tabRedirectHandler(key, lastTab, tabsInfo);
    removeActiveTab(key, type);
    const prevTabInfo = activeTabsInfo.map(({
      ...rest
    }) => ({ ...rest }));
    const remainingTicket = prevTabInfo
      .filter((ticketInfo) => ticketInfo.key !== key);
    setActiveTabs(
      base64.encode(encodeURIComponent(JSON.stringify(remainingTicket))),
    );
  };

  const onCloseActiveTab = () => {
    if (matchParams.conversationid) {
      handleCloseTab(matchParams.conversationid, 'ticket', lastActiveTabKey, activeTabsInfo);
    } else if (search) {
      handleCloseTab(keygen, 'search', lastActiveTabKey, activeTabsInfo);
    }
  };

  const handleGetUsersAction = () => {
    setStateLoading(true);
    getUserInfo().finally(() => {
      setStateLoading(false);
    });
  };

  const fetchMaxDate = (newdate) => {
    const isTimeStamp = moment(maxDateState).isAfter(moment(newdate));
    if (!isTimeStamp) { setMaxDateState(newdate); }
  };

  const fetchChatBoxInfo = () => {
    setMaxDateState(maxDateChanged);
    api.get('command.jsp?command=get_group_chat_id').then((grpId) => {
      const code = grpId.data[0].code;
      api.get(`command.jsp?command=tickets&code=${code}`).then((codeRes) => {
        setGroupChatId(codeRes.data[0].conversationid, code);
        api.get(`command.jsp?command=ticket&conversationid=${codeRes.data[0].conversationid}`)
          .then(async (messageInfoRes) => {
            api.get(`command.jsp?command=ticket_item&conversationid=${codeRes.data[0].conversationid}&message_groupid=`)
              .then((resMsgGroup) => {
                Promise.all(messageInfoRes.data.map(async (messageItem) => {
                  fetchMaxDate(messageItem.datechanged);
                  const messageList = resMsgGroup.data.filter((groupMsgItem) => groupMsgItem
                    .message_groupid === messageItem.message_groupid);
                  return Promise.all(messageList.map(async (fileInfo) => {
                    if (fileInfo.rtype === 'F' || fileInfo.rtype === 'L') {
                      const fileRes = await api.get(`command.jsp?command=file_link_info&file=${fileInfo.message}`);
                      return { ...fileInfo, attachment: fileRes.data };
                    } return fileInfo;
                  })).then((msgres) => ({ ...messageItem, messages: msgres }));
                })).then(async (messages) => {
                  await setChatInfoList(codeRes.data[0].conversationid, messages, navigate);
                  await getChatPreviewInfo(code);
                  await setMaxDate(maxDateState);
                  await setTypingMessage(codeRes.data[0].conversationid, 'M', '');
                  setGroupChatLoading(false);
                  setTimeout(() => fetchChatBoxInfo(), 3600000);
                });
              });
          });
      });
    });
  };

  useEffect(() => {
    const activeChatInfo = chatInfo && chatInfo[groupChatInfo.groupChatId]
      && chatInfo[groupChatInfo.groupChatId].messageLoading;
    if (activeChatInfo === undefined || activeChatInfo === null) {
      fetchChatBoxInfo();
      setGroupChatLoading(true);
    }
    if (users.length === 0) {
      handleGetUsersAction();
    }
    const GroupChatHeight = localStorage.getItem('group-chat-width');
    if (GroupChatHeight) { setGroupChatWidth(JSON.parse(GroupChatHeight)); }
    return () => {
      if (search) {
        setPrevActiveTabInfo(keygen);
      } else if (matchParams.conversationid) {
        setPrevActiveTabInfo(matchParams.conversationid);
      } else {
        setPrevActiveTabInfo(`${userInfo.agentid}HomeTab`);
      }
    };
  }, [matchParams.conversationid]);

  useHotkeys('ctrl+q', () => onCloseActiveTab(), [lastActiveTabKey, activeTabsInfo]);
  return (
    <Tickets
      isLoading={isStateLoading}
      groupChatInfo={groupChatInfo}
      setGroupChatWidth={setGroupChatWidth}
    >
      {children}
    </Tickets>
  );
});

TicketListContainer.propTypes = {
  lastActiveTabKey: PropTypes.string,
  maxDateChanged: PropTypes.string,
  getUserInfo: PropTypes.func,
  removeActiveTab: PropTypes.func,
  setActiveTabs: PropTypes.func,
  setPrevActiveTabInfo: PropTypes.func,
  setGroupChatWidth: PropTypes.func,
  setMaxDate: PropTypes.func,
  setChatInfoList: PropTypes.func,
  setTypingMessage: PropTypes.func,
  setGroupChatId: PropTypes.func,
  setGroupChatLoading: PropTypes.func,
  getChatPreviewInfo: PropTypes.func,
  users: PropTypes.instanceOf(Array),
  activeTabsInfo: PropTypes.instanceOf(Array),
  groupChatInfo: PropTypes.instanceOf(Object),
  userInfo: PropTypes.instanceOf(Object),
  chatInfo: PropTypes.instanceOf(Object),
  children: PropTypes.oneOfType(
    [PropTypes.element, PropTypes.arrayOf(PropTypes.element)],
  ).isRequired,
};

TicketListContainer.defaultProps = {
  lastActiveTabKey: '',
  maxDateChanged: '',
  groupChatInfo: {},
  getUserInfo: noop,
  removeActiveTab: noop,
  setActiveTabs: noop,
  setPrevActiveTabInfo: noop,
  setGroupChatWidth: noop,
  setMaxDate: noop,
  setChatInfoList: noop,
  setTypingMessage: noop,
  setGroupChatId: noop,
  setGroupChatLoading: noop,
  getChatPreviewInfo: noop,
  users: [],
  activeTabsInfo: [],
  userInfo: {},
  chatInfo: {},
};

const mapStateToProps = (state) => ({
  chatInfo: state.tickets.chat,
  users: state.auth.users,
  activeTabsInfo: state.tickets.activeTabsInfo,
  lastActiveTabKey: state.tickets.lastActiveTabKey,
  userInfo: state.auth.userInfo,
  groupChatInfo: state.tickets.groupChatInfo,
  maxDateChanged: state.tickets.maxDateChanged,
});

const mapDispatchToProps = (dispatch) => ({
  setMaxDate: (date) => dispatch(ticketActions.setMaxDate(date)),
  getChatPreviewInfo: (code) => dispatch(ticketActions.getChatPreviewInfo(code)),
  getUserInfo: () => dispatch(actions.getUserInfo()),
  setActiveTabs: (tabs) => dispatch(ticketActions.setActiveTabs(tabs)),
  removeActiveTab: (key, type) => dispatch(ticketActions.removeActiveTab(key, type)),
  setPrevActiveTabInfo: (key) => dispatch(ticketActions.setPrevActiveTabInfo(key)),
  setGroupChatWidth: (width) => dispatch(ticketActions.setGroupChatWidth(width)),
  setChatInfoList: (conversationid, data, navigate) => dispatch(
    ticketActions.setChatInfoList(conversationid, data, navigate),
  ),
  setTypingMessage: (conversationid, type, message) => dispatch(
    ticketActions.setTypingMessage(conversationid, type, message),
  ),
  setGroupChatId: (id, code) => dispatch(ticketActions.setGroupChatId(id, code)),
  setGroupChatLoading: (isLoading) => dispatch(ticketActions.setGroupChatLoading(isLoading)),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(TicketListContainer);
