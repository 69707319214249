import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { noop } from '../../../utils';

import 'react-responsive-modal/styles.css';
import './custom_dialog.scss';

const CustomDialog = ({
  classNames,
  children,
  closeIcon,
  isOpen,
  toggle,
  title,
}) => (
  <Dialog
    open={isOpen}
    onClose={toggle}
    classes={{ paper: `dialog_section ${classNames}` }}
  >
    {closeIcon && (
      <IconButton
        aria-label="close"
        onClick={toggle}
        className="card_close"
        sx={{ position: 'absolute', right: 8, top: 8 }}
      >
        <CloseIcon />
      </IconButton>
    )}
    <DialogTitle>
      {title}
    </DialogTitle>
    <DialogContent>
      {children}
    </DialogContent>
  </Dialog>
);

CustomDialog.propTypes = {
  title: PropTypes.string,
  classNames: PropTypes.string,
  isOpen: PropTypes.bool,
  closeIcon: PropTypes.bool,
  toggle: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};

CustomDialog.defaultProps = {
  title: '',
  classNames: '',
  isOpen: false,
  closeIcon: true,
  toggle: noop,
  children: <></>,
};

export default CustomDialog;
