import React from 'react';
import PropTypes from 'prop-types';
import BlockUi from '@availity/block-ui';

import CustomDialog from '../../CustomDialog/CustomDialog';
import ChangePasswordForm from './ChangePasswordForm/ChangePasswordFormContainer';
import { SpinnerLoader } from '../../Loader';

const ChangePaawordDialog = ({
  setChangePasswordDialog,
  isModalVisible,
  isLoading,
}) => (
  <CustomDialog
    isOpen={isModalVisible}
    toggle={() => setChangePasswordDialog(false)}
    title="Change password"
  >
    <BlockUi
      message=" "
      tag="div"
      blocking={isLoading}
      loader={<SpinnerLoader />}
      className="full_height full_width block-ui-background"
    >
      <ChangePasswordForm />
    </BlockUi>
  </CustomDialog>
);

ChangePaawordDialog.propTypes = {
  isLoading: PropTypes.bool,
  isModalVisible: PropTypes.bool,
  setChangePasswordDialog: PropTypes.func,
};

ChangePaawordDialog.defaultProps = {
  isLoading: false,
  isModalVisible: false,
  setChangePasswordDialog: () => {},
};

export default ChangePaawordDialog;