import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { RenderInput, RenderButton } from '../../../FormControl';
import { actions } from '../../../../../modules/tickets';
import { noop } from '../../../../../utils';

import './internal_ticket_form.scss';

const InternalTicketForm = ({
  setToEmailRef,
  onSubmit,
  validate,
  initialValues,
}) => (
  <Form
    onSubmit={onSubmit}
    validate={validate}
    initialValues={initialValues}
    render={({ handleSubmit }) => (
      <form className="internal_ticket_form" onSubmit={handleSubmit}>
        <div className="ticket_input_wrapper">
          <p className="input_label">Subject: </p>
          <Field
            name="Subject"
            id="Subject"
            placeholder="Subject"
            type="text"
            component={RenderInput}
            className="create_ticket_input"
            innerRef={setToEmailRef}
          />
        </div>
        <p className="input_label">Message: </p>
        <Field
          name="Message"
          id="Message"
          placeholder="Message"
          type="textarea"
          component={RenderInput}
          className="create_ticket_textarea"
        />
        <RenderButton className="create_ticket_submit">
          Create
        </RenderButton>
      </form>
    )}
  />
);

InternalTicketForm.propTypes = {
  onSubmit: PropTypes.func,
  setToEmailRef: PropTypes.func,
  validate: PropTypes.func,
  initialValues: PropTypes.object,
};

InternalTicketForm.defaultProps = {
  onSubmit: noop,
  setToEmailRef: noop,
  validate: noop,
  initialValues: {},
};

const InternalTicketFormContainer = ({
  createNewTicketLoading,
  setNewInternalTicket,
  setNewTicketModal,
}) => {
  const navigate = useNavigate();
  const [toInternalEmailRef, setToInternalEmailRef] = useState(null);
  const [focus, setFocus] = useState(true);

  const onSubmit = (values) => {
    createNewTicketLoading(true);
    setNewInternalTicket(values.Subject, values.Message).then((internalRes) => {
      if (internalRes.value.data) {
        const res = internalRes.value.data[0];
        navigate(`/ticket/${res.code}/${res.conversationid}`);
        setNewTicketModal(false, undefined);
        createNewTicketLoading(false);
      }
    });
  };

  const validateForm = (values) => {
    const errors = {};
    if (!values.Subject) {
      errors.Subject = "Subject is required";
    }
    return errors;
  };

  useEffect(() => {
    if (toInternalEmailRef && focus) {
      toInternalEmailRef.focus();
      setFocus(false);
    }
  }, [toInternalEmailRef, focus]);

  return (
    <InternalTicketForm
      onSubmit={onSubmit}
      validate={validateForm}
      setToEmailRef={setToInternalEmailRef}
      initialValues={{ Subject: '', Message: '' }}
    />
  );
};

InternalTicketFormContainer.propTypes = {
  setNewTicketModal: PropTypes.func,
  createNewTicketLoading: PropTypes.func,
  setNewInternalTicket: PropTypes.func,
};

InternalTicketFormContainer.defaultProps = {
  setNewTicketModal: noop,
  createNewTicketLoading: noop,
  setNewInternalTicket: noop,
};

const mapDispatchToProps = (dispatch) => ({
  createNewTicketLoading: (isLoading) => dispatch(actions.createNewTicketLoading(isLoading)),
  setNewTicketModal: (isOpen, type) => dispatch(actions.setNewTicketModal(isOpen, type)),
  setNewInternalTicket: (subject, message) => dispatch(
    actions.setNewInternalTicket(subject, message),
  ),
});

export default connect(
  null, mapDispatchToProps,
)(InternalTicketFormContainer);