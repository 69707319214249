import React from 'react';
import { FaPlus, FaTrashAlt } from 'react-icons/fa';
import { noop } from '../../../utils';

const AddRemoveTodo = ({
  handleRemoveTodo = noop,
  handleAddTodo = noop,
  isTodoItem = [],
  id = '',
}) => (
  isTodoItem.length === 0 ? (
    <FaPlus onClick={handleAddTodo} />
  ) : (
    <FaTrashAlt onClick={(e) => handleRemoveTodo(e, id)} />
  )
);

export default AddRemoveTodo;
