import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';

import { RenderInput, RenderButton } from '../../../FormControl';
import { noop } from '../../../../../utils';

const ExternalTicketForm = ({
  setToEmailRef,
  handleEmail,
  bccemail,
  onSubmit,
  ccemail,
  validate,
  initialValues,
}) => (
  <Form
    onSubmit={onSubmit}
    validate={validate}
    initialValues={initialValues}
    render={({ handleSubmit }) => (
      <form className="external_ticket_form" onSubmit={handleSubmit}>
        <div className="ticket_input_wrapper">
          <p className="input_label fixed_width">To: </p>
          <Field
            name="To"
            id="To"
            placeholder="To"
            type="text"
            component={RenderInput}
            className="create_ticket_input"
            innerRef={setToEmailRef}
          />
          <p
            onClick={() => handleEmail('CC')}
            role="presentation"
            className="input_label"
          >
            CC
          </p>
          <p
            onClick={() => handleEmail('BCC')}
            role="presentation"
            className="input_label"
          >
            BCC
          </p>
        </div>
        {ccemail && (
          <div className="ticket_input_wrapper">
            <p className="input_label fixed_width">CC: </p>
            <Field
              name="CC"
              id="CC"
              placeholder="CC"
              type="text"
              component={RenderInput}
              className="create_ticket_input"
            />
          </div>
        )}
        {bccemail && (
          <div className="ticket_input_wrapper">
            <p className="input_label fixed_width">BCC: </p>
            <Field
              name="BCC"
              id="BCC"
              placeholder="BCC"
              type="text"
              component={RenderInput}
              className="create_ticket_input"
            />
          </div>
        )}
        <div className="ticket_input_wrapper">
          <p className="input_label fixed_width">Subject: </p>
          <Field
            name="Subject"
            id="Subject"
            placeholder="Subject"
            type="text"
            component={RenderInput}
            className="create_ticket_input"
          />
        </div>
        <RenderButton className="create_ticket_submit">
          Build
        </RenderButton>
      </form>
    )}
  />
);

ExternalTicketForm.propTypes = {
  bccemail: PropTypes.bool,
  ccemail: PropTypes.bool,
  onSubmit: PropTypes.func,
  handleEmail: PropTypes.func,
  setToEmailRef: PropTypes.func,
  validate: PropTypes.func,
  initialValues: PropTypes.object,
};

ExternalTicketForm.defaultProps = {
  bccemail: false,
  ccemail: false,
  onSubmit: noop,
  handleEmail: noop,
  setToEmailRef: noop,
  validate: noop,
  initialValues: {},
};

export default ExternalTicketForm;