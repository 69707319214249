import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import TextEditor from './TextEditor';
import * as actions from '../../../redux/actions';
import { noop } from '../../../../../utils';
import { withDeviceType } from '../../../../../hoc';

import './text_editor.scss';

let timerId;
const debounce = (fn, delay) => { // eslint-disable-line
  return ((...args) => {
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      fn(...args);
      clearTimeout(timerId);
      timerId = null;
    }, delay);
  })();
};

const TextEditorContainer = ({
  setForwardEmailInput = noop,
  setButtonInputClick = noop,
  setTypingMessage = noop,
  setInputMessage = noop,
  scrollToBottom = noop,
  forwardError = false,
  footerActions = {},
  deviceType = '',
  userInfo = {},
}) => {
  const matchParams = useParams();
  const [isFocus, setFocus] = useState(true);
  const [cursor, setCursor] = useState(true);
  const [inputRef, setInputRef] = useState(null);

  const onInputFocus = () => {
    if (footerActions.viewInputRef) {
      inputRef.focus();
      setTypingMessage(matchParams.conversationid, 'M', footerActions.inputMessage || '');
      setTimeout(() => {
        inputRef.selectionStart = 5;
        inputRef.selectionEnd = 5;
      }, 0);
    }
  };

  const onSetTypingMsg = (value) => debounce(() => setTypingMessage(matchParams.conversationid, 'M', value), 200);

  const setInputText = (e) => {
    const { value } = e.target;
    onSetTypingMsg(value);
    setInputMessage(value);
  };

  const handleForwardInput = (e) => {
    setForwardEmailInput(e.target.value);
  };

  useEffect(() => {
    if (!footerActions.inputMessage) {
      setInputMessage(
        `Hi,\n\n\n\nPlease note!  Due to a recent vulnerability, make certain you are using either CrushFTP v10.8.4+ or v11.3.1+.  Anything earlier is unsafe!\n\nThanks,\n${
          userInfo.firstname || ''
        }\n\n`,
        5,
      );
      setTypingMessage(
        matchParams.conversationid,
        'M',
        `Hi,\n\n\n\nPlease note!  Due to a recent vulnerability, make certain you are using either CrushFTP v10.8.4+ or v11.3.1+.  Anything earlier is unsafe!\n\nThanks,\n${
          userInfo.firstname || ''
        }\n\n`,
      );
    }
    scrollToBottom();
    return () => {
      setInputMessage('');
      setButtonInputClick(undefined);
    };
  }, []);

  useEffect(() => {
    if (inputRef && isFocus) {
      onInputFocus();
      setFocus(false);
    }
    if (footerActions.cursorLocation && inputRef && cursor) {
      inputRef.focus();
      inputRef.scrollTop = 0;
      inputRef.selectionStart = footerActions.cursorLocation;
      inputRef.selectionEnd = footerActions.cursorLocation;
      setCursor(false);
    }
  });

  return (
    <TextEditor
      deviceType={deviceType}
      setInputRef={setInputRef}
      setInputText={setInputText}
      footerActions={footerActions}
      forwardError={forwardError}
      handleForwardInput={handleForwardInput}
    />
  );
};

TextEditorContainer.propTypes = {
  deviceType: PropTypes.string,
  forwardError: PropTypes.bool,
  scrollToBottom: PropTypes.func,
  setTypingMessage: PropTypes.func,
  setButtonInputClick: PropTypes.func,
  setForwardEmailInput: PropTypes.func,
  setInputMessage: PropTypes.func,
  footerActions: PropTypes.instanceOf(Object),
  userInfo: PropTypes.instanceOf(Object),
};

const mapStateToProps = (state) => ({
  footerActions: state.tickets.footerView,
  userInfo: state.auth.userInfo,
});

const mapDispatchToProps = (dispatch) => ({
  setTypingMessage: (conversationid, type, message) => dispatch(
    actions.setTypingMessage(conversationid, type, message),
  ),
  setButtonInputClick: (isclick) => dispatch(actions.setButtonInputClick(isclick)),
  setForwardEmailInput: (message) => dispatch(actions.setForwardEmailInput(message)),
  setInputMessage: (message) => dispatch(actions.setInputMessage(message)),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(withDeviceType(TextEditorContainer));
