import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ChangePasswordForm from './ChangePasswordForm';
import { actions } from '../../../../../modules/authForms';
import { noop } from '../../../../../utils';
import { showNotification } from '../../../../../utils/Notifications';

import './change_password_form.scss';

const ChangePasswordFormContainer = ({
  setChangePasswordDialog,
  setChangeUserPassword,
}) => {
  const navigate = useNavigate();

  const onSubmit = (values) => {
    setChangeUserPassword(values.currentpassword, values.password, values.reenterpassword)
      .then((res) => {
        if (res.value.data.response_msg === 'Success') {
          alert('Your password is changed successfully, please login again');
          setChangePasswordDialog(false);
          navigate('/logout');
        } else {
          showNotification(res.value.data.response_msg, 'error', 5000);
        }
      });
  };

  const validateForm = (values) => {
    const errors = {};
    if (!values.currentpassword) {
      errors.currentpassword = "Password is required";
    }
    if (!values.password) {
      errors.password = "Password is required";
    }
    if (!values.reenterpassword) {
      errors.reenterpassword = "Password is required";
    }
    if (values.password && values.reenterpassword && values.password !== values.reenterpassword) {
      errors.reenterpassword = "Password and Re-Enter Password should match";
    }
    return errors;
  };

  return (
    <ChangePasswordForm
      onSubmit={onSubmit}
      validate={validateForm}
    />
  );
};

ChangePasswordFormContainer.propTypes = {
  setChangeUserPassword: PropTypes.func,
  setChangePasswordDialog: PropTypes.func,
};

ChangePasswordFormContainer.defaultProps = {
  setChangeUserPassword: noop,
  setChangePasswordDialog: noop,
};

const mapDispatchToProps = (dispatch) => ({
  setChangeUserPassword: (cpass, npass, rpass) => dispatch(
    actions.setChangeUserPassword(cpass, npass, rpass),
  ),
  setChangePasswordDialog: (isVisible) => dispatch(
    actions.setChangePasswordDialog(isVisible),
  ),
});

export default connect(
  null, mapDispatchToProps,
)(ChangePasswordFormContainer);