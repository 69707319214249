import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import WebNotification from './WebNotification';
import { actions as ticketAction } from '../../../modules/tickets';
import ResolvedSound from '../../../assets/music/resolved.wav';
import NewReplySound from '../../../assets/music/ding.wav';
import IdleSound from '../../../assets/music/idle.wav';
import SendSound from '../../../assets/music/send.flac';
import { noop } from '../../../utils';

const WebNotificationContainer = ({
  webNotificationState = {},
  setWebNotification = () => {},
  deviceType,
}) => {
  const playSound = () => {
    if (deviceType !== 'mobile' && webNotificationState.audible) {
    if (document.getElementById('success-sound')) {
      document.getElementById('success-sound').play();
    }
    }
  };

  const handleNotificationOnShow = () => {
    playSound();
  };

  const handleNotificationOnClose = () => {
    setWebNotification({});
  };

  const handleAudibleType = (type) => {
    if (deviceType === 'mobile') return;

    if (type === 'idle') {
      return (
        <audio id="success-sound" preload="auto">
          <source src={IdleSound} type="audio/mpeg" />
          <embed hidden autostart="false" loop={false} src={IdleSound} />
        </audio>
      );
    } if (type === 'resolved') {
      return (
        <audio id="success-sound" preload="auto">
          <source src={ResolvedSound} type="audio/mpeg" />
          <embed hidden autostart="false" loop={false} src={ResolvedSound} />
        </audio>
      );
    } if (type === 'newReply') {
      return (
        <audio id="success-sound" preload="auto">
          <source src={NewReplySound} type="audio/mpeg" />
          <embed hidden autostart="false" loop={false} src={NewReplySound} />
        </audio>
      );
    }
    return (
      <audio id="success-sound" preload="auto">
        <source src={SendSound} type="audio/mpeg" />
        <embed hidden autostart="false" loop={false} src={SendSound} />
      </audio>
    );
  };

  const handleNotificationOnClick = () => {
    webNotificationState?.onClickAction();
  };

  return (
    <WebNotification
      audible={webNotificationState.audible}
      timeout={webNotificationState.timeout}
      title={webNotificationState.title}
      notify={webNotificationState.notify}
      handleAudibleType={handleAudibleType}
      audibleType={webNotificationState.audibleType}
      handleNotificationOnShow={handleNotificationOnShow}
      handleNotificationOnClose={handleNotificationOnClose}
      handleNotificationOnClick={handleNotificationOnClick}
    />
  );
};


WebNotificationContainer.propTypes = {
  setWebNotification: PropTypes.func,
  webNotificationState: PropTypes.instanceOf(Object),
  deviceType: PropTypes.string,
};

WebNotificationContainer.defaultProps = {
  setWebNotification: noop,
  webNotificationState: {},
  deviceType: '',
};

const mapStateToProps = (state) => ({
  webNotificationState: state.tickets.webNotification,
  deviceType: state.deviceDetector.deviceType,
});

const mapDispatchToProps = (dispatch) => ({
  setWebNotification: (webInfo) => dispatch(ticketAction.setWebNotification(webInfo)),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(WebNotificationContainer);
