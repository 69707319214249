import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';

import { RenderInput, RenderButton } from '../../../FormControl';
import { noop } from '../../../../../utils';

const RecipientsForm = ({
  onSubmit,
  validate,
  initialValues,
}) => (
  <Form
    onSubmit={onSubmit}
    validate={validate}
    initialValues={initialValues}
    render={({ handleSubmit }) => (
      <form onSubmit={handleSubmit} className="participants_form">
        <div className="participants_input_wrapper">
          <span>To: </span>
          <Field
            autoFocus
            name="To"
            id="To"
            placeholder="To"
            type="text"
            component={RenderInput}
          />
        </div>
        <div className="participants_input_wrapper">
          <span>CC: </span>
          <Field
            name="CC"
            id="CC"
            placeholder="CC"
            type="text"
            component={RenderInput}
          />
        </div>
        <div className="participants_input_wrapper">
          <span>BCC: </span>
          <Field
            name="BCC"
            id="BCC"
            placeholder="BCC"
            type="text"
            component={RenderInput}
          />
        </div>
        <div className="participants_btn">
          <RenderButton>
            Submit
          </RenderButton>
        </div>
      </form>
    )}
  />
);

RecipientsForm.propTypes = {
  onSubmit: PropTypes.func,
  validate: PropTypes.func,
  initialValues: PropTypes.object,
};

RecipientsForm.defaultProps = {
  onSubmit: noop,
  validate: noop,
  initialValues: {},
};

export default RecipientsForm;