/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classnames from 'classnames';

import { noop } from '../../../utils';

const CustomDropZone = ({
  getInputProps = noop,
  getRootProps = noop,
  isDragActive = false,
  className = '',
  title = 'upload here',
  id = 'custom-dropzone',
}) => (
  <div {...getRootProps({ className: classnames(`custom_dropzone ${className}`, { dropzone_highlight: isDragActive }) })}>
    <input {...getInputProps()} id={id} />
    <p>{title}</p>
  </div>
);


export default CustomDropZone;
