import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import ActionFooter from './ActionFooter';
import * as actions from '../../redux/actions';
import { noop } from '../../../../utils';

import './action_footer.scss';

const ActionFooterContainer = ({
  setInputSubmitType,
  attchmentVisible,
  setInputMessage,
  scrollToBottom,
  setNoteMessage,
  footerActions,
  setInputType,
  typingInfo,
  chatInfo,
  userInfo,
  users,
}) => {
  const matchParams = useParams();
  const [forwardError, setForwardError] = useState(false);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [userList, setUserList] = useState([]);
  const [selectedTransfer, setSelectedTransfer] = useState([]);
  const [postponeDate, setPostponeDate] = useState(new Date());

  // const fetchRstatus = () => {
  //   if (chatInfo[matchParams.conversationid].previewInfo.rstatus === 'C') {
  //     return 'C';
  //   } if (chatInfo[matchParams.conversationid].previewInfo.rstatus === 'A') {
  //     return 'A';
  //   }
  //   return 'C';
  // };

  const [statusTicket, setStatusTicket] = useState('C');

  const setInputMode = () => {
    const typingDetail = typingInfo[matchParams.conversationid];
    if (typingDetail) {
      const typing = typingDetail.filter((item) => item.userid === userInfo.agentid);
      if (typing.length > 0) {
        setInputType(typing[0].type);
        if (typing[0].type === 'M') {
          setInputMessage(typing[0].text.replace(/\n/g, '\n'));
        } else if (typing[0].type === 'N') {
          setNoteMessage(typing[0].text.replace(/\n/g, '\n'));
        }
      }
    } else {
      setInputMessage(
        `Hi,\n\n\n\nPlease note!  Due to a recent vulnerability, make certain you are using either CrushFTP v10.8.4+ or v11.3.1+.  Anything earlier is unsafe!\n\nThanks,\n${
          userInfo.firstname || ''
        }\n\n`,
        5,
      );
    }
  };

  const _draggleListener = () => {
    const textEditor = document.getElementById('msg-text-editor');
    const noteEditor = document.getElementById('note-editor');
    const id = document.getElementById('chatsection-dropzone');
    if ((textEditor || noteEditor) && !id) { attchmentVisible(true); }
  };

  const previewAdded = (fileItem) => fileItem.map(
    (file) => Object.assign(file, { preview: URL.createObjectURL(file) }),
  );

  const fetchFile = (prevState, fileinfo) => {
    const fileItems = previewAdded(fileinfo);
    if (prevState) { fileItems.push(...prevState); }
    return previewAdded(fileItems);
  };

  const _pasteListener = (e) => {
    const textEditor = document.getElementById('msg-text-editor');
    const noteEditor = document.getElementById('note-editor');
    if (textEditor || noteEditor) {
      const items = (e.clipboardData || e.originalEvent.clipboardData).items;
      const item = items[0];
      const file = item.getAsFile();
      if (file && (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg')) {
        setUploadFiles((prevState) => fetchFile(prevState, [file]));
        attchmentVisible(true);
      }
    }
  };

  useEffect(() => {
    const ticketSection = document.getElementById('ticket-messages-container');
    if (ticketSection) {
      ticketSection.addEventListener('paste', _pasteListener);
      ticketSection.addEventListener('dragover', _draggleListener);
    }
    setUserList([{
      value: '',
      label: 'Unassigned',
      agentid: '',
    }, ...users.reduce((init, currentValue) => ([...init, {
      value: currentValue.firstname,
      label: currentValue.firstname,
      agentid: currentValue.accountuserid,
    }]), [])]);
    setInputMode();
    setInputSubmitType('');
    return () => {
      setInputType('');
      attchmentVisible(false);
      window.removeEventListener('dragover', _draggleListener);
      window.removeEventListener('paste', _pasteListener);
    };
  }, []);

  return (
    <ActionFooter
      userList={userList}
      uploadFiles={uploadFiles}
      postponeDate={postponeDate}
      statusTicket={statusTicket}
      forwardError={forwardError}
      footerActions={footerActions}
      scrollToBottom={scrollToBottom}
      setUploadFiles={setUploadFiles}
      setPostponeDate={setPostponeDate}
      setStatusTicket={setStatusTicket}
      setForwardError={setForwardError}
      selectedTransfer={selectedTransfer}
      setSelectedTransfer={setSelectedTransfer}
      footerActionLoading={chatInfo[matchParams.conversationid].submitLoader}
    />
  );
};

ActionFooterContainer.propTypes = {
  setInputType: PropTypes.func,
  setInputSubmitType: PropTypes.func,
  scrollToBottom: PropTypes.func,
  setInputMessage: PropTypes.func,
  setNoteMessage: PropTypes.func,
  attchmentVisible: PropTypes.func,
  users: PropTypes.instanceOf(Array),
  footerActions: PropTypes.instanceOf(Object),
  typingInfo: PropTypes.instanceOf(Object),
  chatInfo: PropTypes.instanceOf(Object),
  userInfo: PropTypes.instanceOf(Object),
};

ActionFooterContainer.defaultProps = {
  setInputType: noop,
  setInputSubmitType: noop,
  scrollToBottom: noop,
  setInputMessage: noop,
  setNoteMessage: noop,
  attchmentVisible: noop,
  users: [],
  footerActions: {},
  typingInfo: {},
  chatInfo: {},
  userInfo: {},
};

const mapStateToProps = (state) => ({
  footerActions: state.tickets.footerView,
  typingInfo: state.tickets.typingInfo,
  users: state.auth.users,
  userInfo: state.auth.userInfo,
  chatInfo: state.tickets.chat,
});

const mapDispatchToProps = (dispatch) => ({
  setInputType: (type) => dispatch(actions.setInputType(type)),
  setInputSubmitType: (type) => dispatch(actions.setInputSubmitType(type)),
  setInputMessage: (message) => dispatch(actions.setInputMessage(message)),
  setNoteMessage: (message) => dispatch(actions.setNoteMessage(message)),
  attchmentVisible: (isAttchment) => dispatch(actions.attchmentVisible(isAttchment)),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(ActionFooterContainer);
