import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import BlockUi from '@availity/block-ui';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import RequestStates from '../../../../utils/request-states';
import { SpinnerLoader } from '../../../../shared/components/Loader';
import { userLogout } from '../../redux/actions';
import { noop } from '../../../../utils';

const Logout = React.memo(({ _userLogout, LoadingState }) => {
  const navigate = useNavigate();

  const handlLogoutAction = () => {
    _userLogout()
      .then(() => {
        navigate('/signin');
      }).catch(() => {
        navigate('/signin');
      });
  };

  useEffect(() => {
    handlLogoutAction();
  }, []);

  return (
    <BlockUi
      message=" "
      tag="div"
      blocking={LoadingState}
      className="full_height full_width block-ui-background"
      loader={<SpinnerLoader />}
    />
  );
});

Logout.propTypes = {
  _userLogout: PropTypes.func,
  LoadingState: PropTypes.bool,
};

Logout.defaultProps = {
  _userLogout: noop,
  LoadingState: false,
};

const mapStateToProps = (state) => ({
  LoadingState: state.auth.requestState === RequestStates.loading,
});

const mapDispatchToProps = (dispatch) => ({
  _userLogout: () => dispatch(userLogout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Logout);