import React from 'react';
import PropTypes from 'prop-types';
import BlockUi from '@availity/block-ui';

import TypingDraft from './TypingDraft/TypingDraftContainer';
import InputBoxContainer from './InputBox/InputBoxContainer';
import Header from './Header/HeaderContainer';
import ChatContentSection from './ChatContentSection/ChatContentSectionContainer';
import { SpinnerLoader } from '../../../../shared/components/Loader';
import { noop } from '../../../../utils';

const GroupChat = ({
  onScrollMessageList,
  setMessagesRead,
  ticketStatus,
  isGroupLoading,
  scrollToBottom,
  chatContentRef,
  groupChatInfo,
  messageList,
}) => {
  let newMsg;
  if (messageList && messageList.length > 0) {
    const msgData = messageList.filter((messageItem) => messageItem.newMessage);
    newMsg = msgData.length;
  } else {
    newMsg = undefined;
  }

  const removeNewMsg = () => {
    if (newMsg) { setMessagesRead(groupChatInfo.groupChatId); }
    const selectionText = window.getSelection().toString();
    if (!selectionText) {
      const inputArea = document.getElementById('gorup_chat_textarea');
      inputArea.focus();
    }
  };

  return (
    <div className="group_chat_section" id="group_chat_container">
      <BlockUi
        message=" "
        tag="div"
        blocking={isGroupLoading}
        className="full_height full_width block-ui-background"
        renderChildren
        loader={<SpinnerLoader />}
      >
        <div className="group_chat_container">
          <Header
            newMsg={newMsg}
            removeNewMsg={removeNewMsg}
            scrollToBottom={scrollToBottom}
          />
          <div
            id="group-chat-content"
            className="group_chat_content"
            ref={chatContentRef}
            onClick={removeNewMsg}
            role="presentation"
            onScroll={onScrollMessageList}
          >
            <ChatContentSection />
          </div>
          <TypingDraft />
          <InputBoxContainer
            ticketStatus={ticketStatus}
            removeNewMsg={removeNewMsg}
            scrollToBottom={scrollToBottom}
          />
        </div>
      </BlockUi>
    </div>
  );
};

GroupChat.propTypes = {
  ticketStatus: PropTypes.string,
  isGroupLoading: PropTypes.bool,
  onScrollMessageList: PropTypes.func,
  setMessagesRead: PropTypes.func,
  scrollToBottom: PropTypes.func,
  messageList: PropTypes.instanceOf(Array),
  groupChatInfo: PropTypes.instanceOf(Object),
  chatContentRef: PropTypes.instanceOf(Object).isRequired,
};

GroupChat.defaultProps = {
  ticketStatus: 'C',
  isGroupLoading: false,
  onScrollMessageList: noop,
  setMessagesRead: noop,
  scrollToBottom: noop,
  messageList: [],
  groupChatInfo: {},
};

export default GroupChat;
