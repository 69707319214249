/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { useTable, useBlockLayout } from 'react-table';
import { FixedSizeList } from 'react-window';
import { useNavigate } from 'react-router-dom';

import { noop } from '../../../../../../../utils';

import './react_table.scss';

const ReactTable = ({
  RefTicketListDesktop,
  onScrollTicketList,
  hiddenColumn,
  columns,
  data,
}) => {
  const navigate = useNavigate();

  const {
    getTableProps,
    headerGroups,
    rows,
    totalColumnsWidth,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: hiddenColumn,
      },
    },
    useBlockLayout,
  );

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);
      return (
        <tr
          className="rt-tr"
          {...row.getRowProps({
            style,
          })}
          onClick={() => {
            navigate(`/ticket/${row.original.code}/${row.original.conversationid}`);
          }}
        >
          {row.cells.map((cell) => (
            <td {...cell.getCellProps()} className="rt-td">
              {cell.render('Cell')}
            </td>
          ))}
        </tr>
      );
    },
    [prepareRow, rows],
  );
  return (
    <table {...getTableProps()} className="ticket_list_table">
      {headerGroups.map((headerGroup) => (
        <thead className="tr-header" key={headerGroup.headers[0].id}>
          <tr className="rt-tr">
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()} className="rt-th" key={column.id}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        </thead>
      ))}
      <tbody className="rt-tbody">
        <FixedSizeList
          height={5000}
          itemCount={rows.length}
          itemSize={60}
          width={totalColumnsWidth}
          style={{ width: '100%' }}
          outerRef={RefTicketListDesktop}
          onScroll={onScrollTicketList}
        >
          {RenderRow}
        </FixedSizeList>
      </tbody>
    </table>
  );
};

ReactTable.propTypes = {
  onScrollTicketList: PropTypes.func,
  hiddenColumn: PropTypes.instanceOf(Array),
  columns: PropTypes.instanceOf(Array),
  data: PropTypes.instanceOf(Array),
  RefTicketListDesktop: PropTypes.instanceOf(Object),
};

ReactTable.defaultProps = {
  onScrollTicketList: noop,
  hiddenColumn: [],
  columns: [],
  data: [],
  RefTicketListDesktop: {},
};

export default ReactTable;
