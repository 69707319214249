import React from 'react';
import PropTypes from 'prop-types';
import BlockUi from '@availity/block-ui';
import { FaRegPaperPlane, FaPaperclip } from 'react-icons/fa';
import { MdCancel } from 'react-icons/md';

import TicketDropZone from '../../TicketDropZone/TicketDropZoneContainer';
import { noop } from '../../../../../utils';
import { SpinnerLoader } from '../../../../../shared/components/Loader';

const InputBox = ({
  handleAttachmentClick,
  handleInputChange,
  setUploadFiles,
  chatInputValue,
  handleKeyDown,
  onChatSubmit,
  uploadFiles,
  isDropZone,
  loading,
}) => (
  <>
    <BlockUi
      message=" "
      tag="div"
      blocking={loading}
      renderChildren
      loader={<SpinnerLoader width={40} height={40} />}
    >
      {isDropZone && (
      <div className="dropzone_wrapper">
        <TicketDropZone
          uploadFiles={uploadFiles}
          setUploadFiles={setUploadFiles}
          id="group-chatsection-dropzone"
        />
        <MdCancel className="dropzone_close_icon" onClick={handleAttachmentClick} />
      </div>
      )}
      <div className="group_chat_footer">
        <div className="footer_wrapper">
          <FaPaperclip className="send_icon" onClick={handleAttachmentClick} />
          <textarea
            name="text"
            id="gorup_chat_textarea"
            value={chatInputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
          />
          <FaRegPaperPlane className="send_icon" onClick={onChatSubmit} />
        </div>
      </div>
    </BlockUi>
  </>
);

InputBox.propTypes = {
  chatInputValue: PropTypes.string,
  isDropZone: PropTypes.bool,
  loading: PropTypes.bool,
  handleInputChange: PropTypes.func,
  onChatSubmit: PropTypes.func,
  setUploadFiles: PropTypes.func,
  handleKeyDown: PropTypes.func,
  handleAttachmentClick: PropTypes.func,
  uploadFiles: PropTypes.instanceOf(Array),
};

InputBox.defaultProps = {
  chatInputValue: '',
  isDropZone: false,
  loading: false,
  handleInputChange: noop,
  onChatSubmit: noop,
  setUploadFiles: noop,
  handleKeyDown: noop,
  handleAttachmentClick: noop,
  uploadFiles: [],
};

export default InputBox;
