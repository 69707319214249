/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import BlockUi from '@availity/block-ui';
import PropTypes from 'prop-types';
import moment from 'moment';
import base64 from 'base-64';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import api from '../utils/api';
import MessageSnippet from '../shared/data/MessageSnippet.json';
import { showNotification } from '../utils/Notifications';
import { actions as authActions } from '../modules/authForms';
import { actions as ticketAction } from '../modules/tickets';
import { SpinnerLoader } from '../shared/components/Loader';
import { noop, deepCopy } from '../utils';

const withAuthentication = (ComposedComponent) => {
  let initTimeout;
  let initTimeoutMessage;
  let getTicketPolling = 1;
  const pollingCount = 5;

  class Authentication extends Component {
    constructor(props) {
      super(props);
      this.state = {
        pageAccessible: false,
        maxDate: '',
      };
      this.onGetTickets = this.onGetTickets.bind(this);
      this.onSetOffsetTime = this.onSetOffsetTime.bind(this);
      this.fetchChatInfo = this.fetchChatInfo.bind(this);
      this.fetchMaxDate = this.fetchMaxDate.bind(this);
      this.handleTicketsCall = this.handleTicketsCall.bind(this);
      this.handleChatInfoCall = this.handleChatInfoCall.bind(this);
      this.fetchActiveTab = this.fetchActiveTab.bind(this);
    }

    componentWillMount() {
      const {
        getUserInfoAction,
        getLoginStatus,
        user,
        navigate,
        loginStatus,
        setMessageSnippet,
      } = this.props;
      if (loginStatus === 'LOGGED_OUT') {
        this.setState({ pageAccessible: true });
        getLoginStatus().then((res) => {
          if (res.value.data.response_msg === 'LOGGED_IN') {
            setMessageSnippet(MessageSnippet);
            if (user.length === 0) { getUserInfoAction(); }
            this.fetchActiveTab();
          } else {
            this.setState({ pageAccessible: false });
            navigate('/signin');
          }
        });
      }
    }

    async componentDidMount() {
      await this.handleTicketsCall();
      await this.handleChatInfoCall();
    }

    componentWillReceiveProps(nextProps) {
      const prevTabs = this.props.activeTabsInfo.filter((data) => data.type === 'ticket');
      const nextPropsTabs = nextProps.activeTabsInfo.filter((data) => data.type === 'ticket');
      if (prevTabs.length !== nextPropsTabs.length) {
        clearTimeout(window.fetChatDetails);
        this.fetchChatInfo();
      }
    }

    componentDidUpdate(prevProps) {
      const prevTabs = this.props.activeTabsInfo.filter((data) => data.type === 'ticket');
      const prevPropsTabs = prevProps.activeTabsInfo.filter((data) => data.type === 'ticket');
      if (prevPropsTabs.length !== prevTabs.length) {
        clearTimeout(window.fetChatDetails);
        this.fetchChatInfo();
      }
    }

    componentWillUnmount() {
      clearTimeout(window.fetChatDetails);
      clearTimeout(initTimeout);
      clearTimeout(initTimeoutMessage);
      window.fetChatDetails = false;
    }

    async handleChatInfoCall() {
      window.fetChatDetails = 1;
      if (window.fetChatDetails) { clearTimeout(window.fetChatDetails); }
      initTimeoutMessage = setTimeout(() => {
        if (window.fetChatDetails) { this.fetchChatInfo(); }
      }, 1500);
    }

    async handleTicketsCall() {
      this.onGetTickets();
    }

    onSetOffsetTime(serverTime) {
      const { setOffsetTime, setServerTime } = this.props;
      const currentTime = moment(new Date());
      const duration = moment.duration(moment(serverTime).diff(currentTime));
      const hours = duration.asHours();
      setServerTime(serverTime);
      setOffsetTime(hours.toFixed(1));
    }

    onGetTickets() {
      const { getTickets } = this.props;
      getTickets();
    }

    fetchActiveTab() {
      const { getActiveTabs, setViewStatusAgent } = this.props;
      if (getTicketPolling < pollingCount) {
        getActiveTabs().then(async (response) => {
          if (response.value.status === 200) {
            if (response.value.data.response_msg === 'OK') {
              const activeTabs = JSON.parse(decodeURIComponent(
                base64.decode(response.value.data.saved_state),
              ));
              const filteredTickets = activeTabs && activeTabs.filter((tab) => tab.type === 'ticket');
              const ticketsCode = filteredTickets && filteredTickets.map((ticket) => ticket.code);
              if (ticketsCode && ticketsCode.length > 0) {
                setViewStatusAgent(ticketsCode.join(','));
              }
              this.onSetOffsetTime(response.value.data.now);
              getTicketPolling = pollingCount;
            }
          } else {
            setTimeout(() => {
              this.fetchActiveTab();
              getTicketPolling += 1;
            }, 1000);
            if (getTicketPolling === pollingCount) {
              showNotification('All tries failed, please refresh the page or contact developer', 'error', false);
            } else {
              showNotification(`Retrying fetching state, try ${getTicketPolling}`, 'error', 1500);
            }
          }
        });
      } else {
        getActiveTabs().catch((error) => {
          console.error('Error fetching active tabs:', error);
          showNotification('Error fetching active tabs, please try again later', 'error', false);
        });
      }
    }

    fetchMaxDate(newdate) {
      const { maxDate } = this.state;
      const isTimeStamp = moment(maxDate).isAfter(moment(newdate));
      if (!isTimeStamp) { this.setState({ maxDate: newdate }); }
    }

    continuePolling() {
      setTimeout(() => {
        this.setState({ pageAccessible: false });
      }, 1500);
      window.fetChatDetails = setTimeout(() => {
        if (window.fetChatDetails) { this.fetchChatInfo(); }
      }, 5000);
    }

    fetchChatInfo() {
      const {
        activeTabsInfo, setChatInfoList, setMaxDate, maxDateChanged,
        groupChatInfo, navigate,
      } = this.props;
      this.setState({ maxDate: maxDateChanged });
      if (window.fetChatDetails) { clearTimeout(window.fetChatDetails); }
      const ticketList = activeTabsInfo && activeTabsInfo.filter((tabItem) => tabItem.type === 'ticket');
      let conversationIdList = ticketList && ticketList
        .reduce((init, current) => [...init, current.conversationid], []);
      if (groupChatInfo.groupChatId) {
        conversationIdList = [...conversationIdList, groupChatInfo.groupChatId];
      }
      if (conversationIdList.length > 0) {
        api.get(`command.jsp?command=ticket&conversationid=${conversationIdList.toString()}&datechanged=${maxDateChanged}`)
          .then((conversationRes) => {
            if (conversationRes.data.length > 0) {
              const conversationIdInfo = deepCopy(conversationRes.data);
              const chatDetails = Promise.all(conversationIdList.map(async (filterId) => {
                const filterdChatDetails = conversationIdInfo && conversationIdInfo
                  .filter((conversationItem) => conversationItem.conversationid === filterId);
                return api.get(`command.jsp?command=ticket_item&conversationid=${filterId}&message_groupid=`)
                  .then((messageInfoRes) => {
                    const messageInfoList = deepCopy(messageInfoRes.data);
                    return Promise.all(filterdChatDetails.map(async (chatItem) => {
                      this.fetchMaxDate(chatItem.datechanged);
                      const messages = messageInfoList.filter((messageItem) => messageItem
                        .message_groupid === chatItem.message_groupid);
                      return Promise.all(messages.map(async (fileInfo) => {
                        if (fileInfo.rtype === 'F' || fileInfo.rtype === 'L') {
                          const fileRes = await api.get(`command.jsp?command=file_link_info&file=${fileInfo.message}`);
                          return { ...fileInfo, attachment: fileRes.data };
                        } return fileInfo;
                      })).then((msgres) => ({ ...chatItem, messages: msgres }));
                    }));
                  }).then(async (finalMsgRes) => {
                    if (finalMsgRes.length > 0) {
                      if (filterId === groupChatInfo.groupChatId) {
                        await setChatInfoList(filterId, finalMsgRes, navigate);
                      } else {
                        api.get('command.jsp?command=get_state').then(async (tabsRes) => {
                          const tabs = tabsRes.data.saved_state;
                          if (tabs) {
                            const tabList = JSON.parse(decodeURIComponent(base64.decode(tabs)));
                            const findIndex = tabList && tabList.findIndex((tab) => tab.type === 'ticket' && tab.conversationid === filterId);
                            if (findIndex !== -1) {
                              await setChatInfoList(filterId, finalMsgRes, navigate);
                            }
                          } else {
                            await setChatInfoList(filterId, finalMsgRes, navigate);
                          }
                        });
                      }
                      return { filterId, finalMsgRes };
                    } return [];
                  });
              }));
              chatDetails.then(() => {
                this.continuePolling();
                if (maxDateChanged !== this.state.maxDate) {
                  setMaxDate(this.state.maxDate);
                }
              }).catch(() => {
                this.continuePolling();
              });
            } else {
              this.continuePolling();
            }
          }).catch(() => {
            this.continuePolling();
          });
      } else {
        this.continuePolling();
      }
    }

    render() {
      const { pageAccessible } = this.state;
      return (
        <BlockUi
          message=" "
          tag="div"
          blocking={pageAccessible}
          loader={<SpinnerLoader />}
          renderChildren={false}
          className="full_height full_width block-ui-background"
        >
          <ComposedComponent {...this.props} />
        </BlockUi>
      );
    }
  }

  Authentication.propTypes = {
    loginStatus: PropTypes.string,
    maxDateChanged: PropTypes.string,
    groupChatInfo: PropTypes.instanceOf(Object),
    getUserInfoAction: PropTypes.func,
    getLoginStatus: PropTypes.func,
    getActiveTabs: PropTypes.func,
    getTickets: PropTypes.func,
    setOffsetTime: PropTypes.func,
    setChatInfoList: PropTypes.func,
    setMaxDate: PropTypes.func,
    setServerTime: PropTypes.func,
    setMessageSnippet: PropTypes.func,
    setViewStatusAgent: PropTypes.func,
    user: PropTypes.instanceOf(Object),
    activeTabsInfo: PropTypes.instanceOf(Array),
    match: PropTypes.instanceOf(Object).isRequired,
  };

  Authentication.defaultProps = {
    loginStatus: 'LOGGED_OUT',
    maxDateChanged: '',
    groupChatInfo: {},
    getUserInfoAction: noop,
    getLoginStatus: noop,
    getActiveTabs: noop,
    getTickets: noop,
    setOffsetTime: noop,
    setChatInfoList: noop,
    setMaxDate: noop,
    setServerTime: noop,
    setMessageSnippet: noop,
    setViewStatusAgent: noop,
    user: {},
    activeTabsInfo: [],
  };

  const mapStateToProps = (state) => ({
    user: state.auth.users,
    loginStatus: state.auth.loginStatus,
    activeTabsInfo: state.tickets.activeTabsInfo,
    maxDateChanged: state.tickets.maxDateChanged,
    groupChatInfo: state.tickets.groupChatInfo,
  });

  const mapDispatchToProps = (dispatch) => ({
    getUserInfoAction: () => dispatch(authActions.getUserInfo()),
    getLoginStatus: () => dispatch(authActions.getLoginStatus()),
    setOffsetTime: (offset) => dispatch(authActions.setOffsetTime(offset)),
    setServerTime: (date) => dispatch(authActions.setServerTime(date)),
    setChatInfoList: (id, messages, navigate) => dispatch(ticketAction.setChatInfoList(id, messages, navigate)),
    setMaxDate: (date) => dispatch(ticketAction.setMaxDate(date)),
    getActiveTabs: () => dispatch(ticketAction.getActiveTabs()),
    getTickets: () => dispatch(ticketAction.getTickets()),
    setMessageSnippet: (messages) => dispatch(ticketAction.setMessageSnippet(messages)),
    setViewStatusAgent: (idList) => dispatch(ticketAction.setViewStatusAgent(idList)),
  });

  const withNavigate = (props) => {
    const navigate = useNavigate();
    const location = useLocation(); 
    const params = useParams();
    return <Authentication {...props} navigate={navigate} location={location} params={params} />;
  };

  return connect(mapStateToProps, mapDispatchToProps)(withNavigate);
};

export default withAuthentication;

