import React from 'react';
import PropTypes from 'prop-types';

import CustomDialog from '../../CustomDialog/CustomDialog';
import { noop } from '../../../../utils';

const ShortcutsDialog = ({
  handleModal,
  isModal,
}) => (
  <CustomDialog
    isOpen={isModal}
    toggle={handleModal}
    title="App Shortcuts"
  >
    <div>
      <h6>Navigational Shortcuts:</h6>
      <ul>
        <li><code>(Ctrl + Q)</code> - Close the active tab</li>
        <li><code>(Ctrl + A)</code> - Next Tab</li>
        <li><code>(Ctrl + D)</code> - Prev Tab</li>
        <li><code>(Ctrl + /)</code> - Focus on search</li>
        <li><code>(Ctrl + W)</code> - Open Chat and focus on input</li>
      </ul>

      <h6>Action Shortcuts:</h6>
      <ul>
        <li><code>(Ctrl + R)</code> - Add a note</li>
        <li><code>(Ctrl + X)</code> - Postpone</li>
        <li><code>(Ctrl + T)</code> - Transfer</li>
        <li><code>(Ctrl + S + Shift)</code> - Submit Reply</li>
        <li><code>(Ctrl + S)</code> - Submit Reply & Close Active Tab</li>
        <li><code>(Ctrl + E + Shift)</code> - Resolve</li>
        <li><code>(Ctrl + E)</code> - Resolve & Close Active Tab</li>
        <li><code>(Ctrl + C + Shift)</code> - Cancel current action</li>
        <li><code>(Ctrl + G)</code> - Search within the loaded ticket</li>
        <li><code>(Ctrl + F)</code> - Add a file</li>
      </ul>
    </div>
  </CustomDialog>
);

ShortcutsDialog.propTypes = {
  isModal: PropTypes.bool,
  handleModal: PropTypes.func,
};

ShortcutsDialog.defaultProps = {
  isModal: false,
  handleModal: noop,
};

export default ShortcutsDialog;
