import React from 'react';
import { Form, Field } from 'react-final-form';

import CustomDialog from '../../CustomDialog/CustomDialog';
import { RenderInput, RenderButton } from '../../FormControl';
import { noop } from '../../../../utils';

const TodoDialog = ({
  handleModal = noop,
  isModal = false,
  onSubmit = noop,
  validate = noop,
  initialValues = {},
}) => (
  <CustomDialog
    isOpen={isModal}
    toggle={handleModal}
    title="Add Todo"
    classNames="todo_dialog"
  >
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className="todo_form">
          <div className="todo_input_wrapper">
            <span>Title: </span>
            <Field
              name="title"
              id="title"
              placeholder="Title"
              type="text"
              component={RenderInput}
            />
          </div>
          <div className="todo_input_wrapper">
            <span>Description: </span>
            <Field
              name="description"
              id="description"
              placeholder="Description"
              type="text"
              component={RenderInput}
            />
          </div>
          <RenderButton type="submit">
            Add
          </RenderButton>
        </form>
      )}
    />
  </CustomDialog>
);

export default TodoDialog;