import React from 'react';
import PropTypes from 'prop-types';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { noop } from '../../../utils';

const WebNotification = ({
  handleNotificationOnClose = noop,
  handleNotificationOnClick = noop,
  handleNotificationOnShow = noop,
  handleAudibleType = noop,
  audibleType = 'sent',
  timeout = 5000,
  audible = true,
  notify = false,
  title = '',
}) => {
  React.useEffect(() => {
    if (notify) {
      toast(title, {
        onOpen: handleNotificationOnShow,
        onClose: handleNotificationOnClose,
        onClick: handleNotificationOnClick,
        autoClose: timeout,
      });
    }
  }, [notify, title, timeout, handleNotificationOnShow, handleNotificationOnClose, handleNotificationOnClick]);

  return (
    <>
      <ToastContainer />
      {(() => {
        if (notify && audible && audibleType) {
          return handleAudibleType(audibleType);
        }
      })()}
    </>
  );
};

WebNotification.propTypes = {
  title: PropTypes.string,
  audibleType: PropTypes.string,
  timeout: PropTypes.number,
  audible: PropTypes.bool,
  notify: PropTypes.bool,
  handleAudibleType: PropTypes.func,
  handleNotificationOnShow: PropTypes.func,
  handleNotificationOnClose: PropTypes.func,
  handleNotificationOnClick: PropTypes.func,
};

export default WebNotification;