import React from 'react';
import PropTypes from 'prop-types';
import BlockUi from '@availity/block-ui';
import { Resizable } from 're-resizable';

import TabRenderedContainer from './TabRendered/TabRenderedContainer';
import FaviconTicket from './FaviconTicket';
import GroupChat from './GroupChat/GroupChatContainer';
import { CreateTicketDialog } from '../../../shared/components/Dialogs';
import { SpinnerLoader } from '../../../shared/components/Loader';
import { withDeviceType } from '../../../hoc';
import { noop } from '../../../utils';

const Tickets = ({
  setGroupChatWidth,
  groupChatInfo,
  isLoading,
  children,
}) => (
  <>
    <div className="tickets_wrapper_section">
      <div className="tickets_wrapper_row">
        <BlockUi
          message=" "
          tag="div"
          blocking={isLoading}
          className="ticket_wrapper_loader block-ui-background"
          loader={<SpinnerLoader />}
        >
          <TabRenderedContainer />
          {children}
        </BlockUi>
      </div>
      {groupChatInfo.groupChatDialog && (
        <div className="group_chatbox_wrapper">
          <Resizable
            maxHeight="98%"
            minHeight="98%"
            defaultSize={{
              width: groupChatInfo.width,
              height: groupChatInfo.height,
            }}
            onResizeStop={(e, direction, ref, d) => {
              setGroupChatWidth(groupChatInfo.width + d.width);
              localStorage.setItem('group-chat-width', JSON.stringify(groupChatInfo.width + d.width));
            }}
          >
            <GroupChat />
          </Resizable>
        </div>
      )}
    </div>
    <CreateTicketDialog />
    <FaviconTicket />
  </>
);

Tickets.propTypes = {
  isLoading: PropTypes.bool,
  setGroupChatWidth: PropTypes.func,
  groupChatInfo: PropTypes.instanceOf(Object),
  children: PropTypes.oneOfType(
    [PropTypes.element, PropTypes.arrayOf(PropTypes.element)],
  ).isRequired,
};

Tickets.defaultProps = {
  isLoading: false,
  setGroupChatWidth: noop,
  groupChatInfo: {},
};

export default withDeviceType(Tickets);
