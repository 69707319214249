import React, { useEffect } from 'react';
import { LogoutContainer } from '../../modules/authForms';

const Logout = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <LogoutContainer />
  );
};

export default Logout;