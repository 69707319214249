/* eslint-disable dot-notation */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import union from 'lodash.union';
import { connect } from 'react-redux';

import GroupChat from './GroupChat';
import * as ticketActions from '../../redux/actions';
import api from '../../../../utils/api';
import { noop } from '../../../../utils';

import './group_chat.scss';

let timerId;
const debounce = (fn, delay) => { // eslint-disable-line
  return ((...args) => {
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      fn(...args);
      clearTimeout(timerId);
      timerId = null;
    }, delay);
  })();
};

const preventRendering = (prevProps, nextProps) => {
  const nextGroupChatInfo = nextProps.groupChatInfo;
  const prevGroupChatInfo = prevProps.groupChatInfo;
  if (JSON.stringify(prevGroupChatInfo) !== JSON.stringify(nextGroupChatInfo)) {
    return false;
  }
  if (nextGroupChatInfo.groupChatId && prevProps && nextProps
    && JSON.stringify(prevProps.chatInfo[nextGroupChatInfo.groupChatId])
    !== JSON.stringify(nextProps.chatInfo[nextGroupChatInfo.groupChatId])) {
    return false;
  }
  return true;
};

const GroupChatContainer = React.memo(({
  getViewStatusOfAgent,
  setGroupChatDialog,
  setViewStatusAgent,
  setScrollPostion,
  getTypingMessage,
  setMessagesRead,
  groupChatInfo,
  chatInfo,
  userInfo,
}) => {
  let liveStatusTimeOut;
  const chatContentRef = useRef(null);

  const fetchViewStatusOfAgentPolling = () => {
    window.liveStatusPolling = 1;
    if (window.liveStatusPolling) { clearTimeout(window.liveStatusPolling); }
    getViewStatusOfAgent().then(() => {
      liveStatusTimeOut = setTimeout(() => {
        if (window.liveStatusPolling) { fetchViewStatusOfAgentPolling(); }
      }, 30000);
    }).catch(() => {
      clearTimeout(window.liveStatusPolling);
      clearTimeout(liveStatusTimeOut);
      window.liveStatusPolling = false;
    });
  };

  const setLiveTicketStatus = async () => {
    const email = userInfo.email;
    api.get('command.jsp?command=get_group_chat_id').then((groupId) => {
      const code = groupId.data[0].code;
      getViewStatusOfAgent().then((res) => {
        const viewStatusList = (res.value.data && res.value.data[0] && res.value.data[0][email])
          || [];
        let viewAgentInfo = [];
        if (viewStatusList && viewStatusList.length > 0) {
          viewAgentInfo = viewStatusList && viewStatusList.split(',');
        }
        setViewStatusAgent(union([code], viewAgentInfo).join(','));
      });
    });
  };

  const fetchTypingMessage = () => {
    window.chatTypingPolling = 1;
    if (window.chatTypingPolling) { clearTimeout(window.chatTypingPolling); }
    getTypingMessage(groupChatInfo.groupChatId)
      .then(() => {
        window.chatTypingPolling = setTimeout(() => {
          if (window.chatTypingPolling) { fetchTypingMessage(); }
        }, 1500);
      });
  };

  const scrollToBottom = () => {
    chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
    const { clientHeight, scrollHeight } = chatContentRef.current;
    chatContentRef.current.scrollTop = scrollHeight - clientHeight;
  };

  const setPreviousScrollPosition = () => {
    if (!chatContentRef.current) return;
    if (chatInfo[groupChatInfo.groupChatId] && chatInfo[groupChatInfo.groupChatId].scrollTo) {
      chatContentRef.current.scrollTop = chatInfo[groupChatInfo.groupChatId].scrollTo;
    }
  };

  const onScrollDebounce = () => {
    setScrollPostion('ticket', groupChatInfo.groupChatId,
      chatContentRef.current && chatContentRef.current.scrollTop);
  };

  const onScrollMessageList = () => {
    debounce(() => onScrollDebounce(), 500);
  };

  useEffect(() => {
    setLiveTicketStatus();
    fetchViewStatusOfAgentPolling();
    setPreviousScrollPosition();
    return () => {
      clearTimeout(window.chatTypingPolling);
      clearTimeout(window.liveStatusPolling);
      clearTimeout(liveStatusTimeOut);
      window.chatTypingPolling = false;
      window.liveStatusPolling = false;
    };
  }, []);

  useEffect(() => {
    if (chatInfo[groupChatInfo.groupChatId]
      && chatInfo[groupChatInfo.groupChatId].scrollTo === undefined) {
      scrollToBottom();
    }
  });

  useEffect(() => {
    const isLoading = chatInfo && chatInfo[groupChatInfo.groupChatId]
      && chatInfo[groupChatInfo.groupChatId].messageLoading;
    if (isLoading !== undefined && !isLoading) {
      clearTimeout(window.chatTypingPolling);
      fetchTypingMessage();
    }
  }, [(chatInfo[groupChatInfo.groupChatId] && chatInfo[groupChatInfo.groupChatId].messageLoading)]);

  return (
    <GroupChat
      chatContentRef={chatContentRef}
      isGroupLoading={groupChatInfo.isLoading}
      setGroupChatDialog={setGroupChatDialog}
      setMessagesRead={setMessagesRead}
      scrollToBottom={scrollToBottom}
      onScrollMessageList={onScrollMessageList}
      groupChatInfo={groupChatInfo}
      ticketStatus={chatInfo && chatInfo[groupChatInfo.groupChatId]
        && chatInfo[groupChatInfo.groupChatId].previewInfo
        && chatInfo[groupChatInfo.groupChatId].previewInfo.rstatus}
      searchValue={chatInfo && chatInfo[groupChatInfo.groupChatId]
        && chatInfo[groupChatInfo.groupChatId].messageInfo
        && chatInfo[groupChatInfo.groupChatId].searchValue}
      messageList={chatInfo && chatInfo[groupChatInfo.groupChatId]
        && chatInfo[groupChatInfo.groupChatId].messageInfo
        && chatInfo[groupChatInfo.groupChatId].messageInfo
          .filter((msgData) => msgData.rtype === 'I' || msgData.rtype === 'N')}
    />
  );
},
(prevProps, nextProps) => preventRendering(prevProps, nextProps));

GroupChatContainer.propTypes = {
  setGroupChatDialog: PropTypes.func,
  getViewStatusOfAgent: PropTypes.func,
  setViewStatusAgent: PropTypes.func,
  getTypingMessage: PropTypes.func,
  setScrollPostion: PropTypes.func,
  setMessagesRead: PropTypes.func,
  chatInfo: PropTypes.instanceOf(Object),
  userInfo: PropTypes.instanceOf(Object),
  groupChatInfo: PropTypes.instanceOf(Object),
};

GroupChatContainer.defaultProps = {
  setGroupChatDialog: noop,
  getViewStatusOfAgent: noop,
  setViewStatusAgent: noop,
  getTypingMessage: noop,
  setScrollPostion: noop,
  setMessagesRead: noop,
  chatInfo: {},
  userInfo: {},
  groupChatInfo: {},
};

const mapStateToProps = (state) => ({
  chatInfo: state.tickets.chat,
  userInfo: state.auth.userInfo,
  groupChatInfo: state.tickets.groupChatInfo,
});

const mapDispatchToProps = (dispatch) => ({
  setScrollPostion: (type, key, position) => dispatch(ticketActions
    .setScrollPostion(type, key, position)),
  getTypingMessage: (conversationid) => dispatch(ticketActions.getTypingMessage(conversationid)),
  setViewStatusAgent: (idList) => dispatch(ticketActions.setViewStatusAgent(idList)),
  getViewStatusOfAgent: () => dispatch(ticketActions.getViewStatusOfAgent()),
  setGroupChatDialog: (isOpen) => dispatch(ticketActions.setGroupChatDialog(isOpen)),
  setMessagesRead: (conversationid) => dispatch(ticketActions.setMessagesRead(conversationid)),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(GroupChatContainer);