import React from "react";
import Clipboard from "react-clipboard.js";
import {
  FaRegCopy,
  FaExternalLinkAlt,
  FaSearch,
  FaTimes,
  FaDesktop,
} from "react-icons/fa";

import RecipientInfo from "./RecipientInfo/RecipientInfoContainer";
import HiddenFeature from "../../../../../shared/components/HiddenFeature";
import FlagStar from "../../../../../shared/components/FlagStar/FlagStar";
import AddRemoveTodo from "../../../../../shared/components/AddRemoveTodo/AddRemoveTodoContainer";
import SearchMoveContainer from "../../SearchMove/SearchMoveContainer";
import { noop, splitString, linkifyString } from "../../../../../utils";
import Tooltip from "../../../../../shared/Tooltip";
import { useParams } from "react-router";

const MessageHeader = ({
  handleSearchValue = noop,
  fetchHighLightMsg = noop,
  setSearchRef = noop,
  handleSetTooltip = noop,
  fetchAgentName = noop,
  publicURLCode = "",
  handleIsSearch = noop,
  handleKeyUp = noop,
  searchValue = "",
  tooltipShow = false,
  headerInfo = {},
  isSearch = false,
  isFlag = false,
  location = {},
  isTodo = false,
  // matchParams = {},
}) => {
  const params = useParams();
  return (
    <div className="chat_header_section">
      <div className="chat_header_container">
        <div className="chat_header_left">
          {headerInfo.subject && (
            <p className="chat_title">{headerInfo.subject}</p>
          )}
          {headerInfo.preview && (
            <p className="chat_preview">
              {linkifyString(headerInfo.preview.replace(/<br\s*[//]?>/gi, ""))}
            </p>
          )}
        </div>
        <div className="chat_header_right">
          <RecipientInfo />
          {headerInfo.agentid && (
            <div className="agent_badge">
              {fetchAgentName(headerInfo.agentid)}
            </div>
          )}
          <div className="ticket_info">
            {headerInfo.code && (
              <div className="ticket_id">
                <span>{headerInfo.code}</span>
                <Clipboard
                  data-clipboard-text={headerInfo.code}
                  title={headerInfo.code}
                  className="clipboard_copy_btn"
                  onClick={handleSetTooltip}
                >
                  <Tooltip
                    placement="bottom"
                    trigger="hover"
                    tooltip="Copy ticket ID"
                  >
                    <FaRegCopy />
                  </Tooltip>
                  {tooltipShow && <span className="tooltip_text">Copied!</span>}
                </Clipboard>
                <Tooltip
                  placement="bottom"
                  trigger="hover"
                  tooltip="Start screen sharing"
                >
                  <a
                    className="ext_link screen_share"
                    href={`https://screens.crushftp.com/#${params.code}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaDesktop />
                  </a>{" "}
                  {/*eslint-disable-line*/}
                </Tooltip>
                {
                  publicURLCode && (
                    <Tooltip
                      placement="bottom"
                      trigger="hover"
                      tooltip="Open in customer support app"
                    >
                      <a
                        className="ext_link"
                        href={`/support/ticket_${publicURLCode}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaExternalLinkAlt />
                      </a>
                    </Tooltip>
                  ) /*eslint-disable-line*/
                }
              </div>
            )}
            <div className="social_info">
              {headerInfo.emails && (
                <p className="email_domain">
                  {splitString(headerInfo.emails, "@")}
                </p>
              )}
              <FaSearch className="search_message" onClick={handleIsSearch} />
              <HiddenFeature>
                <div className="todo_icons">
                  <HiddenFeature hiddenChildValue={isTodo}>
                    <AddRemoveTodo
                      subject={headerInfo.subject}
                      id={location.pathname}
                      url={location.pathname}
                    />
                  </HiddenFeature>
                  <HiddenFeature hiddenChildValue={isFlag}>
                    <FlagStar conversationid={params.conversationid} />
                  </HiddenFeature>
                </div>
              </HiddenFeature>
            </div>
          </div>
        </div>
      </div>
      {isSearch && (
        <div className="header_search_section">
          <input
            type="text"
            className="header_search"
            value={searchValue}
            ref={setSearchRef}
            onChange={handleSearchValue}
            onKeyUp={handleKeyUp}
          />
          <FaTimes onClick={handleIsSearch} />
        </div>
      )}
      {searchValue && (
        <SearchMoveContainer
          eleId="ticket-messages"
          count={fetchHighLightMsg()}
        />
      )}
    </div>
  );
};

export default MessageHeader;
