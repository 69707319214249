import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  FaCaretDown, FaArrowLeft, FaArrowRight, FaRegEdit,
} from 'react-icons/fa';

import TabTitleInput from '../Dialogs/TabTitleInput/TabTitleInputConatiner';
import { RenderDropDown } from '../FormControl';
import { noop } from '../../../utils';

const CustomBadge = ({
  handleCloseEditable = noop,
  activeTabsLength = 0,
  linearLoading = false,
  handleMoveTab = noop,
  tabIndexState = 0,
  rightHandler = noop,
  leftHandler = noop,
  totalcount = '',
  rightIcon = false,
  className = '',
  editable = false,
  keyText = '',
  tabType = '',
  active = false,
  length = 0,
  title = '',
}) => (
  <>
    <div
      className={classnames(
        `badge_item ${className}`,
        { active },
        { totalcount },
      )}
      key={title}
    >
      <div className="badge_wrapper">
        {totalcount && (totalcount !== '0' || totalcount !== 0) && (
          <span className="active_count">{totalcount}</span>
        )}
        {(tabType === 'search' || tabIndexState !== activeTabsLength - 1 || tabIndexState !== 0) && (
          <RenderDropDown
            icon={<FaCaretDown />}
            id={keyText}
            className="tab_menu_items"
            popoverClass="tab_menu_items_popover"
          >
            <>
              {tabType === 'search' && (
                <li onClick={(e) => leftHandler(e)} role="presentation">
                  <FaRegEdit />
                  Rename tab
                </li>
              )}
              {tabIndexState !== activeTabsLength - 1 && (
                <li onClick={() => handleMoveTab('right', keyText)} role="presentation">
                  <FaArrowRight />
                  Move right
                </li>
              )}
              {tabIndexState !== 0 && (
                <li onClick={() => handleMoveTab('left', keyText)} role="presentation">
                  <FaArrowLeft />
                  Move left
                </li>
              )}
            </>
          </RenderDropDown>
        )}
        {title && (
          <button type="button" className="badge_btn badge_title ">
            {title}
          </button>
        )}
        {length !== 0 && length && <span className="items_length">{length}</span>}
        {rightIcon && (
          <p
            className="close_icon"
            role="presentation"
            onClick={(e) => rightHandler(e)}
          >
            {rightIcon}
          </p>
        )}
      </div>
      {linearLoading && <mwc-linear-progress indeterminate />}
    </div>
    <TabTitleInput
      toggleModal={handleCloseEditable}
      inputModal={editable}
      keyText={keyText}
      title={title}
    />
  </>
);

CustomBadge.propTypes = {
  title: PropTypes.string,
  totalcount: PropTypes.string,
  className: PropTypes.string,
  length: PropTypes.number,
  keyText: PropTypes.string,
  tabType: PropTypes.string,
  activeTabsLength: PropTypes.number,
  tabIndexState: PropTypes.number,
  linearLoading: PropTypes.bool,
  active: PropTypes.bool,
  editable: PropTypes.bool,
  leftHandler: PropTypes.func,
  handleCloseEditable: PropTypes.func,
  rightHandler: PropTypes.func,
  handleMoveTab: PropTypes.func,
  rightIcon: PropTypes.oneOfType([PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)]).isRequired,
};

export default CustomBadge;
