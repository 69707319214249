import React from 'react';
import PropTypes from 'prop-types';
import BlockUi from '@availity/block-ui';

import ExternalTicketForm from './ExternalTicketForm/ExternalTicketFormContainer';
import InternalTicketForm from './InternalTicketForm/InternalTicketFormContainer';
import CustomDialog from '../../CustomDialog/CustomDialog';
import { SpinnerLoader } from '../../Loader';

import { noop } from '../../../../utils';

const CreateTicketDialog = ({
  handleModalShow,
  newTicketModal,
  ticketType,
  isLoading,
}) => (
  <CustomDialog
    isOpen={newTicketModal}
    toggle={isLoading ? noop : handleModalShow}
    title={`Compose ${ticketType === 'External' ? '' : ticketType} Message`}
    classNames="create_new_ticket_dialog"
  >
    <BlockUi
      message=" "
      tag="div"
      blocking={isLoading}
      loader={<SpinnerLoader />}
      className="full_height full_width block-ui-background"
    >
      {ticketType === 'External' ? (
        <ExternalTicketForm />
      ) : (
        <InternalTicketForm />
      )}
    </BlockUi>
  </CustomDialog>
);

CreateTicketDialog.propTypes = {
  ticketType: PropTypes.string,
  newTicketModal: PropTypes.bool,
  isLoading: PropTypes.bool,
  handleModalShow: PropTypes.func,
};

CreateTicketDialog.defaultProps = {
  ticketType: 'External',
  newTicketModal: false,
  isLoading: false,
  handleModalShow: noop,
};

export default CreateTicketDialog;