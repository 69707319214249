import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import NoteEditor from './NoteEditor';
import * as actions from '../../../redux/actions';
import { withDeviceType } from '../../../../../hoc';
import { noop } from '../../../../../utils';

import './note_editor.scss';

let timerId;
const debounce = (fn, delay) => { // eslint-disable-line
  return ((...args) => {
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      fn(...args);
      clearTimeout(timerId);
      timerId = null;
    }, delay);
  })();
};

const NoteEditorContainer = ({
  setSelectedTransfer = noop,
  setButtonInputClick = noop,
  selectedTransfer = [],
  scrollToBottom = noop,
  setTypingMessage = noop,
  setPostponeDate = noop,
  setNoteMessage = noop,
  setStatusTicket = noop,
  postponeDate = '',
  statusTicket = 'C',
  deviceType = '',
  footerActios = {},
  userList = [],
  chatInfo = {},
}) => {
  const matchParams = useParams();
  const [isFocus, setFocus] = useState(true);
  const [noteRef, setNoteRef] = useState(null);

  const onInputFocus = () => {
    if (footerActios.viewInputRef) { noteRef.focus(); }
  };

  const onChangePostpone = (value) => {
    const isDate = new Date();
    if (value === '5 min') {
      return setPostponeDate(new Date(isDate.setMinutes(isDate.getMinutes() + 5)));
    } if (value === '10 min') {
      return setPostponeDate(new Date(isDate.setMinutes(isDate.getMinutes() + 10)));
    } if (value === '30 min') {
      return setPostponeDate(new Date(isDate.setMinutes(isDate.getMinutes() + 30)));
    } if (value === '1 hour') {
      return setPostponeDate(new Date(isDate.setHours(isDate.getHours() + 1)));
    } if (value === '4 hours') {
      return setPostponeDate(new Date(isDate.setHours(isDate.getHours() + 4)));
    } if (value === '8 hours') {
      return setPostponeDate(new Date(isDate.setHours(isDate.getHours() + 8)));
    } if (value === '24 hours') {
      return setPostponeDate(new Date(isDate.setDate(isDate.getDate() + 1)));
    } if (value === '2 days') {
      return setPostponeDate(new Date(isDate.setDate(isDate.getDate() + 2)));
    } if (value === '5 days') {
      return setPostponeDate(new Date(isDate.setDate(isDate.getDate() + 5)));
    } if (value === '7 days') {
      return setPostponeDate(new Date(isDate.setDate(isDate.getDate() + 7)));
    } if (value === '14 days') {
      return setPostponeDate(new Date(isDate.setDate(isDate.getDate() + 14)));
    } if (value === '30 days') {
      return setPostponeDate(new Date(isDate.setMonth(isDate.getMonth() + 1)));
    } return null;
  };

  const onSetTypingMsg = (value) => debounce(() => setTypingMessage(matchParams.conversationid, 'N', value), 500);

  const setInputText = (e) => {
    onSetTypingMsg(e.target.value);
    setNoteMessage(e.target.value);
  };

  const onDefaultTransfer = (agentid) => {
    if (agentid) {
      const defaultAgent = userList.find((userItem) => userItem && userItem.agentid === agentid);
      if (defaultAgent && Object.keys(defaultAgent).length > 0) {
        setSelectedTransfer({
          value: defaultAgent.value,
          label: defaultAgent.label,
          agentid: defaultAgent.agentid,
        });
      }
    } if (!agentid) {
      setSelectedTransfer({
        value: '',
        label: 'Unassigned',
        agentid: '',
      });
    }
  };

  useEffect(() => {
    setStatusTicket('C');
    scrollToBottom();
    if (footerActios.submitType === 'transfer') {
      onDefaultTransfer(chatInfo[matchParams.conversationid].previewInfo.agentid);
    }
    return () => {
      setButtonInputClick(undefined);
      setNoteMessage('');
    };
  }, []);

  useEffect(() => {
    if (noteRef && isFocus) {
      onInputFocus();
      setFocus(false);
    }
  });

  return (
    <NoteEditor
      userList={userList}
      deviceType={deviceType}
      setNoteRef={setNoteRef}
      setInputText={setInputText}
      footerActios={footerActios}
      postponeDate={postponeDate}
      statusTicket={statusTicket}
      setPostponeDate={setPostponeDate}
      setStatusTicket={setStatusTicket}
      selectedTransfer={selectedTransfer}
      onChangePostpone={onChangePostpone}
      setSelectedTransfer={setSelectedTransfer}
    />
  );
};


const mapStateToProps = (state) => ({
  footerActios: state.tickets.footerView,
  chatInfo: state.tickets.chat,
});

const mapDispatchToProps = (dispatch) => ({
  setTypingMessage: (conversationid, type, message) => dispatch(
    actions.setTypingMessage(conversationid, type, message),
  ),
  setButtonInputClick: (isclick) => dispatch(actions.setButtonInputClick(isclick)),
  setNoteMessage: (isclick) => dispatch(actions.setNoteMessage(isclick)),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(withDeviceType(NoteEditorContainer));