/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { useIdleTimer } from "react-idle-timer";
import { noop } from "../../../utils";

const IdleTimerSection = ({
  pauseTracking,
  debounce,
  onActive,
  onAction,
  timeout,
  onIdle,
}) => {
  // Add reference to track visibility
  const pageVisibleRef = React.useRef(true);

  // Handle visibility change
  React.useEffect(() => {
    const handleVisibilityChange = () => {
      pageVisibleRef.current = document.visibilityState === "visible";
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () =>
      document.removeEventListener("visibilitychange", handleVisibilityChange);
  }, []);

  const { pause, resume } = useIdleTimer({
    disabled: pauseTracking,
    timeout,
    onActive,
    onIdle,
    onAction: (event) => {
      // Only track activity when page is visible and event is legitimate
      if (pageVisibleRef.current && event?.type) {
        onAction(event);
      }
    },
    // Expand tracked events for better accuracy
    events: [
      "keydown",
      "mousedown",
      "mousemove",
      "wheel",
      "mousewheel",
      "scroll",
      "touchstart",
      "touchmove",
    ],
  });

  return <></>;
};

IdleTimerSection.propTypes = {
  pauseTracking: PropTypes.bool,
  debounce: PropTypes.number,
  timeout: PropTypes.number,
  onActive: PropTypes.func,
  onIdle: PropTypes.func,
  onAction: PropTypes.func,
};

IdleTimerSection.defaultProps = {
  pauseTracking: false,
  debounce: 2000,
  timeout: 10000,
  onActive: noop,
  onIdle: noop,
  onAction: noop,
};

export default IdleTimerSection;
