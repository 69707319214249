import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactDom from 'react-dom';

const portalRoot = document.getElementById('portal');
const ele = document.getElementById('root');

const Portal = ({ children }) => {
  const el = useRef(document.createElement('div'));

  useEffect(() => {
    ele.style.overflow = 'hidden';
    portalRoot.appendChild(el.current);

    return () => {
      ele.removeAttribute('style');
      portalRoot.removeChild(el.current);
    };
  }, []);

  return ReactDom.createPortal(children, el.current);
};

Portal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};

Portal.defaultProps = {
  children: <></>,
};

export default Portal;