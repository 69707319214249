import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FaSearch, FaPlus } from 'react-icons/fa';

import * as actions from '../../../../modules/tickets/redux/actions';
import { noop } from '../../../../utils';
import useHotkeys from '../../../../utils/useHotkeys';

import './search_input.scss';

const SearchInput = ({
  onCustomSearch,
  ticketList,
  getTickets,
  isfocus,
}) => {
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [search, setSearch] = useState();

  const onSubmit = () => {
    setSearch('');
    if (search && search !== undefined && search.length > 0) {
      navigate(`/ticket?q=${search.trim()}&tab_name=`);
    } else if (ticketList.length > 0) {
      getTickets();
    }
  };

  const onCancel = () => {
    setSearch('');
    getTickets();
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setSearch(value);
  };

  const handleKeyUp = (e) => {
    if (e.keyCode === 13) onSubmit();
    if (e.keyCode === 27) onCancel();
  };

  useHotkeys('ctrl+/', () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  return (
    <div className="search_input">
      <input
        type="text"
        name="search"
        ref={inputRef}
        value={search}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        placeholder="Search...."
        autoComplete="off"
        autoFocus={isfocus}
      />
      <FaSearch onClick={onSubmit} />
      <span className="divider" />
      <FaPlus onClick={onCustomSearch} />
    </div>
  );
};

SearchInput.propTypes = {
  isfocus: PropTypes.bool,
  getTickets: PropTypes.func,
  onCustomSearch: PropTypes.func,
  ticketList: PropTypes.instanceOf(Object),
};

SearchInput.defaultProps = {
  isfocus: false,
  getTickets: noop,
  onCustomSearch: noop,
  ticketList: {},
};

const mapStateToProps = (state) => ({
  ticketList: state.tickets.ticketList,
});

const mapDispatchToProps = (dispatch) => ({
  getTickets: () => dispatch(actions.getTickets()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchInput);