import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import CustomDialog from '../../CustomDialog/CustomDialog';
import { RenderButton } from '../../FormControl';
import { noop } from '../../../../utils';

const ErrorHandlingDialog = ({
  errorModal,
  submitHandler,
}) => (
  <CustomDialog
    isOpen={errorModal}
    toggle={noop}
    title="Sorry, due to inactivity your login session is expired"
    classNames="error_handling"
    closeIcon={false}
  >
    <Box display="flex" flexDirection="column" alignItems="center">
      <p className="error_message">Please login again to use the app.</p>
      <RenderButton
        onClick={submitHandler}
      >
        Login
      </RenderButton>
    </Box>
  </CustomDialog>
);

ErrorHandlingDialog.propTypes = {
  errorModal: PropTypes.bool,
  submitHandler: PropTypes.func,
};

ErrorHandlingDialog.defaultProps = {
  errorModal: false,
  submitHandler: noop,
};

export default ErrorHandlingDialog;
