import React, { useState, useEffect } from 'react';
import Clipboard from 'react-clipboard.js';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';

import ReactTable from './ReactTable/ReactTable';
import FilterdTickets from '../../FilterdTickets/FilterdTicketsContainer';
import Rstatus from '../../../../../../shared/data/Rstatus.json';
import UserProfile from '../../../../../../shared/components/UserProfile/UserProfile';
import FlagStar from '../../../../../../shared/components/FlagStar/FlagStar';
import AddRemoveTodo from '../../../../../../shared/components/AddRemoveTodo/AddRemoveTodoContainer';
import {
  splitString, noop, getTimeValue, deepCopy, keyGeneration,
} from '../../../../../../utils';
import EnvelopeOpen from '../../../../../../shared/components/EnvelopeOpen/EnvelopeOpen';
import HiddenFeature from '../../../../../../shared/components/HiddenFeature';

const TicketTable = ({
  offsetTime = '',
  isSearchPage = false,
  tooltipShow = false,
  isFlagOnTop = false,
  isOpenTicket = false,
  isHiddenFeatures = false,
  isTodoOnTop = false,
  onScrollTicketList = noop,
  handleCodeCopy = noop,
  gridData = [],
  users = [],
  todoList = [],
  RefTicketListDesktop = {},
  flags = {},
}) => {
  const location = useLocation();

  const { search } = location;
  const [filterType, setFilterType] = useState({ type: undefined, column: undefined });

  const keyGen = keyGeneration(search);

  const fetchReadableTicketOrNot = (id, date) => {
    const readableTicket = localStorage.getItem('readableTicket');
    if (isHiddenFeatures && readableTicket) {
      const tickets = JSON.parse(readableTicket);
      if (tickets[id]) {
        if (tickets[id].date === date) {
          return false;
        }
        delete tickets[id];
        localStorage.setItem('readableTicket', JSON.stringify(tickets));
        return true;
      } return true;
    } return true;
  };

  const removeFilter = () => {
    const key = keyGen !== '' ? keyGen : 'own-assign';
    setFilterType({ type: undefined, column: undefined });
    const prevFilter = localStorage.getItem('filter');
    if (prevFilter) {
      const prevFilterData = JSON.parse(prevFilter);
      if (prevFilterData[key]) {
        delete prevFilterData[key];
        localStorage.setItem('filter', JSON.stringify(prevFilterData));
      }
    }
  };

  const getLocalStroge = () => {
    const key = keyGen !== '' ? keyGen : 'own-assign';
    const prevFilter = localStorage.getItem('filter');
    if (prevFilter) {
      const prevFilterData = JSON.parse(prevFilter);
      if (prevFilterData[key]) {
        setFilterType({ type: prevFilterData[key].type, column: prevFilterData[key].column });
      }
    }
  };

  const setLocalStorage = () => {
    const key = keyGen !== '' ? keyGen : 'own-assign';
    const prevFilter = localStorage.getItem('filter');
    if (prevFilter) {
      localStorage.setItem('filter', JSON.stringify({ ...JSON.parse(prevFilter), [key]: { type: filterType.type, column: filterType.column } }));
    } else {
      localStorage.setItem('filter', JSON.stringify({ [key]: { type: filterType.type, column: filterType.column } }));
    }
  };

  const handleSorting = () => {
    const gridList = deepCopy(gridData);
    const FlagTodo = [];
    let unfilterData = [];
    if (isHiddenFeatures) {
      setLocalStorage();
      if (isFlagOnTop || isTodoOnTop) {
        if (isFlagOnTop) {
          let data = [];
          gridList.map((gridItem) => {
            if (flags[gridItem.conversationid]) {
              FlagTodo.push(gridItem);
            } else {
              data = [
                ...data,
                gridItem,
              ];
            }
            return null;
          });
          unfilterData = data;
        } else { unfilterData = gridList; }
        if (isTodoOnTop) {
          let data = [];
          unfilterData.map((gridItem) => {
            const id = `/ticket/${gridItem.code}/${gridItem.conversationid}`;
            const findIndex = todoList && todoList.findIndex((todo) => todo.id === id);
            if (findIndex !== -1) {
              FlagTodo.push(gridItem);
            } else {
              data = [
                ...data,
                gridItem,
              ];
            }
            return null;
          });
          unfilterData = data;
        }
      } else { unfilterData = gridList; }
      if (filterType.type && filterType.column
        && (filterType.column === 'datechanged' || filterType.column === 'rstatus')) {
        const filterInfo = unfilterData.sort((a, b) => {
          if (a[filterType.column] < b[filterType.column]) {
            return filterType.type === 'asc' ? -1 : 1;
          }
          if (a[filterType.column] > b[filterType.column]) {
            return filterType.type === 'asc' ? 1 : -1;
          }
          return 0;
        });
        unfilterData = filterInfo;
      }
      return FlagTodo.concat(unfilterData);
    }
    return gridList;
  };

  let columns = [];
  columns = (agentInfoo) => [
    {
      Header: () => null,
      id: 'header',
      columns: [
        {
          Header: 'Flag',
          accessor: 'flag',
          width: 200,
          Cell: (row) => (
            <>
              <div className="flag_actions">
                <FlagStar conversationid={row.row.original.conversationid} />
                <AddRemoveTodo subject={row.row.original.subject} id={`/ticket/${row.row.original.code}/${row.row.original.conversationid}`} />
                <HiddenFeature hiddenChildValue={isOpenTicket}>
                  <EnvelopeOpen
                    conversationid={row.row.original.conversationid}
                    code={row.row.original.code}
                  />
                </HiddenFeature>
              </div>
            </>
          ),
        },
        {
          Header: 'User',
          accessor: 'user',
          width: `${window.outerWidth / 3}`,
          Cell: (data) => (
            <div style={{ height: '50px', display: 'flex' }}>
              <div style={{
                position: 'absolute',
                left: '1px',
                borderRadius: '10px',
                height: '100%',
                top: '0px',
                width: '5px',
                marginRight: '0.2rem',
                backgroundColor: `${(Rstatus[data && data.row.original.rstatus].color) || '#ffffff'}`,
              }}
              />
              <UserProfile
                title={data.row.original.firstname}
                subtitle={splitString(data.row.original.emails, '@')}
                classname="profile_margin_left"
              />
            </div>
          ),
        },
        {
          Header: 'Ticket',
          accessor: 'subject',
          width: `${window.outerWidth}`,
          Cell: (row) => {
            let info = [];
            if (row.row.original.agentid) {
              info = agentInfoo
                .find((agent) => agent.accountuserid === row.row.original.agentid);
            }
            return (
              <>
                <div className="d_flex">
                  <p className={classnames('text_ellipsis justify_start', { text_bolder: fetchReadableTicketOrNot(row.row.original.conversationid, row.row.original.datechanged) })}>{row.row.original.subject}</p>
                  {row.row.original.agentid && (
                    <div className="agent_badge justify_end">
                      {info && info.firstname && info.firstname}
                    </div>
                  )}
                </div>
                {row.row.original.preview && <p className="text_ellipsis">{row.row.original.preview}</p>}
              </>
            );
          },
        },
        {
          Header: 'Changed',
          accessor: 'datechanged',
          minWidth: `${window.outerWidth / 5}`,
          Cell: (row) => (
            <p className="ticket_table_date_changed">
              <span>{getTimeValue(row.row.original.datechanged, 'datetime', offsetTime)}</span>
              <span>{getTimeValue(row.row.original.datechanged, 'fromnow', offsetTime)}</span>
            </p>
          ),
        },
        {
          Header: 'Ticketid',
          accessor: 'code',
          width: 350,
          Cell: (row) => (
            <Clipboard
              data-clipboard-text={row.row.original.code}
              title={`Click to copy to the clipboard ${row.row.original.code}`}
              className="clipboard_copy_btn"
              onClick={handleCodeCopy}
            >
              {row.row.original.code}
            </Clipboard>
          ),
        },
        {
          Header: 'Status',
          accessor: 'rstatus',
          width: `${window.outerWidth / 9}`,
          Cell: (data) => {
            let rstatus = '';
            if (data && data.row) {
              rstatus = Rstatus[data && data.row.original.rstatus].title;
            }
            return <span className="status_info">{rstatus}</span>;
          },
        },
      ],
    },
  ];

  const fetchHiddenColumn = () => {
    const hidden = [];
    if (!isSearchPage) {
      hidden.push('rstatus');
    }
    if (!isHiddenFeatures) {
      hidden.push('flag');
    }
    return hidden;
  };

  useEffect(() => {
    getLocalStroge();
  }, []);

  return (
    <div className="ticket_list_section">
      <div className="react_table">
        {isHiddenFeatures && (
          <FilterdTickets
            setFilterType={setFilterType}
            removeFilter={removeFilter}
            filterType={filterType}
          />
        )}
        <ReactTable
          columns={columns(users)}
          data={handleSorting()}
          RefTicketListDesktop={RefTicketListDesktop}
          onScrollTicketList={onScrollTicketList}
          hiddenColumn={fetchHiddenColumn()}
        />
        {tooltipShow && (
          <span
            className="tooltip_text"
            style={
              tooltipShow.y
                ? {
                  top: tooltipShow.y - 100,
                } : {}
            }
          >Copied!
          </span>
        )}
      </div>
    </div>
  );
};

export default TicketTable;
