import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import AddRemoveTodo from './AddRemoveTodo';
import { TodoDialog } from '../Dialogs';
import * as localInfoActions from '../../TodoRedux/actions';
import { noop } from '../../../utils';

const AddRemoveTodoContainer = React.memo(({
  removeTodo = noop,
  todoList = [],
  subject = '',
  url = '',
  id = '',
}) => {
  const [todoModal, setTodoModal] = useState(false);
  const handleRemoveTodo = (e, Uid) => {
    e.stopPropagation();
    const prevTodoList = JSON.parse(localStorage.getItem('todo-list'));
    const filterTodo = prevTodoList.filter((todo) => todo.id !== Uid);
    localStorage.setItem('todo-list', filterTodo.length > 0 ? JSON.stringify(filterTodo) : []);
    removeTodo(Uid);
  };

  const handleAddTodo = (e) => {
    e.stopPropagation();
    setTodoModal(true);
  };

  return (
    <>
      <AddRemoveTodo
        handleRemoveTodo={handleRemoveTodo}
        isTodoItem={todoList.length > 0
          ? todoList.filter((todo) => todo.id === id) : []}
        id={id}
        handleAddTodo={handleAddTodo}
      />
      {todoModal && (
        <TodoDialog
          isModal={todoModal}
          handleModal={() => setTodoModal(false)}
          subject={subject}
          id={id}
          url={url}
        />
      )}
    </>
  );
});

AddRemoveTodoContainer.propTypes = {
  subject: PropTypes.string,
  url: PropTypes.string,
  id: PropTypes.string,
  removeTodo: PropTypes.func,
  todoList: PropTypes.instanceOf(Array),
};

const mapStateToProps = (state) => ({
  todoList: state.localInfo.todoList,
});

const mapDispatchToProps = (dispatch) => ({
  removeTodo: (id) => dispatch(localInfoActions.removeTodo(id)),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(AddRemoveTodoContainer);
