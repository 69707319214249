export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGIN_LOADING = 'USER_LOGIN_LOADING';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR';

export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_LOGOUT_LOADING = 'USER_LOGOUT_LOADING';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_ERROR = 'USER_LOGOUT_ERROR';

export const GET_USER_INFO = 'GET_USER_INFO';
export const GET_USER_INFO_LOADING = 'GET_USER_INFO_LOADING';
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';
export const GET_USER_INFO_ERROR = 'GET_USER_INFO_ERROR';

export const GET_LOGIN_STATUS = 'GET_LOGIN_STATUS';
export const GET_LOGIN_STATUS_LOADING = 'GET_LOGIN_STATUS_LOADING';
export const GET_LOGIN_STATUS_SUCCESS = 'GET_LOGIN_STATUS_SUCCESS';
export const GET_LOGIN_STATUS_ERROR = 'GET_LOGIN_STATUS_ERROR';

export const SET_TIME_TRACKING = 'SET_TIME_TRACKING';
export const SET_TIME_TRACKING_LOADING = 'SET_TIME_TRACKING_LOADING';
export const SET_TIME_TRACKING_SUCCESS = 'SET_TIME_TRACKING_SUCCESS';
export const SET_TIME_TRACKING_ERROR = 'SET_TIME_TRACKING_ERROR';

export const SET_TIME_TRACKING_FOR_MOBILE = 'SET_TIME_TRACKING_FOR_MOBILE';
export const SET_TIME_TRACKING_FOR_MOBILE_LOADING = 'SET_TIME_TRACKING_FOR_MOBILE_LOADING';
export const SET_TIME_TRACKING_FOR_MOBILE_SUCCESS = 'SET_TIME_TRACKING_FOR_MOBILE_SUCCESS';
export const SET_TIME_TRACKING_FOR_MOBILE_ERROR = 'SET_TIME_TRACKING_FOR_MOBILE_ERROR';

export const SET_TOTAL_TIME_TRACKING = 'SET_TOTAL_TIME_TRACKING';
export const SET_TOTAL_TIME_TRACKING_LOADING = 'SET_TOTAL_TIME_TRACKING_LOADING';
export const SET_TOTAL_TIME_TRACKING_SUCCESS = 'SET_TOTAL_TIME_TRACKING_SUCCESS';
export const SET_TOTAL_TIME_TRACKING_ERROR = 'SET_TOTAL_TIME_TRACKING_ERROR';

export const GET_WORKING_HOURS_INFO = 'GET_WORKING_HOURS_INFO';
export const GET_WORKING_HOURS_INFO_LOADING = 'GET_WORKING_HOURS_INFO_LOADING';
export const GET_WORKING_HOURS_INFO_SUCCESS = 'GET_WORKING_HOURS_INFO_SUCCESS';
export const GET_WORKING_HOURS_INFO_ERROR = 'GET_WORKING_HOURS_INFO_ERROR';

export const GET_USER_STATUS = 'GET_USER_STATUS';
export const GET_USER_STATUS_LOADING = 'GET_USER_STATUS_LOADING';
export const GET_USER_STATUS_SUCCESS = 'GET_USER_STATUS_SUCCESS';
export const GET_USER_STATUS_ERROR = 'GET_USER_STATUS_ERROR';

export const SET_CHANGE_USER_PASSWORD = 'SET_CHANGE_USER_PASSWORD';
export const SET_CHANGE_USER_PASSWORD_LOADING = 'SET_CHANGE_USER_PASSWORD_LOADING';
export const SET_CHANGE_USER_PASSWORD_SUCCESS = 'SET_CHANGE_USER_PASSWORD_SUCCESS';
export const SET_CHANGE_USER_PASSWORD_ERROR = 'SET_CHANGE_USER_PASSWORD_ERROR';

export const SET_INVOICE = 'SET_INVOICE';
export const SET_INVOICE_LOADING = 'SET_INVOICE_LOADING';
export const SET_INVOICE_SUCCESS = 'SET_INVOICE_SUCCESS';
export const SET_INVOICE_ERROR = 'SET_INVOICE_ERROR';

export const SET_OFFSET_TIME = 'SET_OFFSET_TIME';
export const SET_SERVER_TIME = 'SET_SERVER_TIME';
export const SHOW_ERROR_MODAL = 'SHOW_ERROR_MODAL';
export const SET_TRACKING_MODAL = 'SET_TRACKING_MODAL';
export const SET_WORKING_HOURS_DIALOG = 'SET_WORKING_HOURS_DIALOG';
export const SET_CHANGE_PASSWORD_DIALOG = 'SET_CHANGE_PASSWORD_DIALOG';
export const SET_MULTI_THEME_MODAL = 'SET_MULTI_THEME_MODAL';
export const SET_APP_SHORTCUTS_MODAL = 'SET_APP_SHORTCUTS_MODAL';
export const SET_AGENT_TICKET_COUNT = 'SET_AGENT_TICKET_COUNT';
export const STE_PAUSE_TRACKING = 'STE_PAUSE_TRACKING';
export const STE_LAST_PAUSE_TIME = 'STE_LAST_PAUSE_TIME';
