import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import NoteSection from './NoteSection';
import ImageLightBox from '../../../../../shared/components/ImageLightBox/ImageLightBoxContainer';
import { BASE_URL } from '../../../../../config';
import { getMimeType, noop } from '../../../../../utils';
import * as actions from '../../../redux/actions';

import './note_section.scss';

const NoteSectionContainer = ({
  searchValue = '',
  messageGroup = [],
  setEditNote = noop,
  uniqueId = '',
  offsetTime = '',
  msgInfo = {},
}) => {
  const navigate = useNavigate();
  const [lightBoxPreview, setLightBoxPreview] = useState(false);
  const [imgIndex, setImgIndex] = useState(0);

  const handleEditNote = (id, note) => {
    let msg = '';
    if (note && note.length > 0) {
      msg = note[0].message;
    }
    setEditNote(id, msg, true, 'N', 'OK');
  };

  const toastMsg = messageGroup
    && messageGroup.find(
      (msgData) => msgData.rtype === 'T',
    );

  const noteMsg = messageGroup
    && messageGroup.filter(
      (msgData) => msgData.rtype === 'N' || msgData.rtype === 'U' || msgData.rtype === 'S',
    );

  const fileAttachment = messageGroup && messageGroup.filter((msgData) => msgData.rtype === 'L');

  const handleAttachmentDownload = (link) => {
    const a = document.createElement('a');
    a.href = `${BASE_URL}command.jsp?command=file_link&file=${link}&download=true`;
    a.click();
  };

  const handleViewImgCick = (fileName, link, type) => {
    const ext = fileName.split('.').pop();
    const mimeType = getMimeType(ext, type);
    window.open(
      `${BASE_URL}command.jsp?command=file_link&file=${link}&mimetype=${mimeType}`,
    );
  };

  const handleClick = (evt) => {
    const { target } = evt;
    if (target && target.classList.contains('ticket-code')) {
      const code = target.innerText;
      navigate(`/ticket?q=${code}`);
    }
  };

  const fetchImageUrls = () => {
    if (fileAttachment && fileAttachment.length > 0) {
      const urls = fileAttachment.map((fileItem) => ({
        src: `${BASE_URL}command.jsp?command=file_link&file=${fileItem.message}`,
        alt: fileItem.attachment[0].filename,
      }));
      return urls;
    } return [];
  };

  const handleLightBoxPreview = (id) => {
    setImgIndex(id);
    setLightBoxPreview(true);
  };

  const handlePreviewClose = () => {
    const previewDiv = document.getElementsByClassName('react-viewer')[0].parentNode;
    previewDiv.remove();
    setLightBoxPreview(false);
  };

  return (
    <>
      <NoteSection
        noteMsg={noteMsg}
        msgInfo={msgInfo}
        toastMsg={toastMsg}
        offsetTime={offsetTime}
        handleClick={handleClick}
        searchValue={searchValue}
        fileAttachment={fileAttachment}
        handleEditNote={handleEditNote}
        handleViewImgCick={handleViewImgCick}
        handleLightBoxPreview={handleLightBoxPreview}
        key={toastMsg ? toastMsg.messageid : ''}
        handleAttachmentDownload={handleAttachmentDownload}
        uniqueId={uniqueId}
      />
      {lightBoxPreview && fetchImageUrls().length > 0 && (
        <ImageLightBox
          images={fetchImageUrls()}
          visible={lightBoxPreview}
          onClose={handlePreviewClose}
          activeIndex={imgIndex}
        />
      )}
    </>
  );
};


const mapStateToProps = (state) => ({
  offsetTime: state.auth.offsetTime,
});

const mapDispatchToProps = (dispatch) => ({
  setEditNote: (id, msg, editMode, inputType, ref) => dispatch(
    actions.setEditNote(id, msg, editMode, inputType, ref),
  ),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(NoteSectionContainer);