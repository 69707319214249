/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { usePopperTooltip } from 'react-popper-tooltip';

import 'react-popper-tooltip/dist/styles.css';

const Tooltip = ({
  children, tooltip, hideArrow, classNames, ...props
}) => {
  const {
    getTooltipProps,
    getArrowProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip();

  return (
    <div>
      <span ref={setTriggerRef} {...props} className={`trigger ${classNames}`}>
        {children}
      </span>
      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            ref: setTooltipRef,
            className: 'tooltip-container',
          })}
        >
          {!hideArrow && (
            <div
              {...getArrowProps({
                ref: setTooltipRef,
                className: 'tooltip-arrow',
              })}
            />
          )}
          {tooltip}
        </div>
      )}
    </div>
  );
};

Tooltip.propTypes = {
  tooltip: PropTypes.string,
  classNames: PropTypes.string,
  hideArrow: PropTypes.bool,
  children: PropTypes.oneOfType(
    [PropTypes.element, PropTypes.arrayOf(PropTypes.element)],
  ).isRequired,
};

Tooltip.defaultProps = {
  tooltip: '',
  classNames: '',
  hideArrow: false,
};

export default Tooltip;