/* eslint-disable react/jsx-props-no-spreading */
/*eslint-disable */
import React, { useState, useEffect } from 'react';
import BlockUi from '@availity/block-ui';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SpinnerLoader } from '../shared/components/Loader';
import { actions } from '../modules/authForms';
import { noop } from '../utils';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    return <Component navigate={navigate} location={location} params={params} {...props} />;
  };

  return Wrapper;
};

const withRedirect = (ComposedComponent) => {
  const Redirect = ({ getLoginStatus }) => {
    const [pageAccessible, setPageAccessible] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
      const checkLoginStatus = async () => {
        try {
          const res = await getLoginStatus();
          if (res.value.data.response_msg === 'LOGGED_IN') {
            navigate('/ticket');
            setPageAccessible(false);
          } else {
            navigate('/signin');
            setPageAccessible(false);
          }
        } catch (error) {
          console.error('Error checking login status:', error);
          setPageAccessible(false);
        }
      };

      checkLoginStatus();
    }, [getLoginStatus, navigate]);

    return (
      <BlockUi
        message=" "
        tag="div"
        blocking={pageAccessible}
        loader={<SpinnerLoader />}
        renderChildren={false}
        className="full_height full_width block-ui-background"
      >
        <ComposedComponent />
      </BlockUi>
    );
  };

  Redirect.propTypes = {
    getLoginStatus: PropTypes.func,
  };

  Redirect.defaultProps = {
    getLoginStatus: noop,
  };

  const mapDispatchToProps = (dispatch) => ({
    getLoginStatus: () => dispatch(actions.getLoginStatus()),
  });

  return connect(null, mapDispatchToProps)(withRouter(Redirect));
};

export default withRedirect;