import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ShortcutsDialog from './ShortcutsDialog';
import { actions as authFormsActions } from '../../../../modules/authForms';
import { noop } from '../../../../utils';

const ShortcutsDialogContainer = ({
  setAppShortcutsModal,
  appShortcutsModal,
}) => {
  const handleModal = () => {
    setAppShortcutsModal(false);
  };

  return (
    <ShortcutsDialog
      isModal={appShortcutsModal}
      handleModal={handleModal}
    />
  );
};

ShortcutsDialogContainer.propTypes = {
  appShortcutsModal: PropTypes.bool,
  setAppShortcutsModal: PropTypes.func,
};

ShortcutsDialogContainer.defaultProps = {
  appShortcutsModal: false,
  setAppShortcutsModal: noop,
};

const mapStateToProps = (state) => ({
  appShortcutsModal: state.auth.appShortcutsModal,
});

const mapDispatchToProps = (dispatch) => ({
  setAppShortcutsModal: (isOpen) => dispatch(authFormsActions.setAppShortcutsModal(isOpen)),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(ShortcutsDialogContainer);
