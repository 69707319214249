import moment from 'moment-timezone';
import * as actionTypes from './actionTypes';
import RequestStates from '../../../utils/request-states';
import { ServerDateTimeWithoutSecondFormat, deepCopy, formatTime } from '../../../utils';

const INITIAL_STATE = {
  version: '1.1.1',
  requestState: RequestStates.init,
  loginSuccess: '',
  userInfo: {},
  logoutSuccess: '',
  users: [],
  agentList: [],
  loginStatus: 'LOGGED_OUT',
  serverTime: '',
  offsetTime: '0',
  errorModal: false,
  timeTrackingStopped: false,
  pauseTracking: false,
  serverTimeTrackingState: RequestStates.init,
  serverTimeTracking: undefined,
  serverTotalTimeTrackingState: RequestStates.init,
  serverTotalTimeTracking: undefined,
  workingHoursModal: false,
  workingHoursInfoState: RequestStates.init,
  workingHoursInfo: [],
  changePasswordState: RequestStates.init,
  changePassword: [],
  changePasswordDialog: false,
  invoiceState: RequestStates.init,
  lastPauseTime: undefined,
  multiThemeModal: false,
  appShortcutsModal: false,
  userStatusLoading: false,
  userStatusInfo: [],
  lastActivityTime: undefined,
};

export default (state = INITIAL_STATE, action) => {
  const payload = action.payload;
  switch (action.type) {
    case actionTypes.STE_LAST_PAUSE_TIME:
      return {
        ...state,
        lastPauseTime: state.serverTimeTracking ? formatTime(state.serverTimeTracking) : undefined,
      };
    case actionTypes.STE_PAUSE_TRACKING:
      return {
        ...state,
        pauseTracking: payload,
      };
    case actionTypes.SET_AGENT_TICKET_COUNT: {
      const prevUsers = deepCopy(state.users);
      let userList = [];
      if (prevUsers.length > 0) {
        userList = prevUsers.map((user) => (
          user.accountuserid === payload.id
            ? ({
              ...user,
              totalTicket: payload.count,
            }) : user));
      }
      return {
        ...state,
        users: userList,
      };
    }
    case actionTypes.GET_USER_STATUS_LOADING:
      return {
        ...state,
        userStatusLoading: RequestStates.loading,
      };
    case actionTypes.GET_USER_STATUS_SUCCESS: {
      const prevUsers = deepCopy(state.users);
      let userList = [];
      if (prevUsers.length > 0 && payload && payload.data && payload.data.length > 0) {
        userList = prevUsers.map((user) => {
          const userStatusInfo = payload.data.find((item) => item.email === user.username);
          if (userStatusInfo && Object.keys(userStatusInfo).length > 0) {
            const lessThanOneHourAgo = moment(Number(userStatusInfo.last_activity)).isAfter(moment().subtract(5, 'minutes'));
            if (lessThanOneHourAgo) {
              return ({
                ...user,
                ...userStatusInfo,
                last_activity: moment(Number(userStatusInfo.last_activity))
                  .format(ServerDateTimeWithoutSecondFormat),
                isActive: true,
              });
            }
            return ({
              ...user,
              ...userStatusInfo,
              last_activity: moment(Number(userStatusInfo.last_activity))
                .format(ServerDateTimeWithoutSecondFormat),
              isActive: false,
            });
          }
          return (
            user
          );
        });
      }
      return {
        ...state,
        users: userList,
        userStatusLoading: RequestStates.success,
      };
    }
    case actionTypes.GET_USER_STATUS_ERROR:
      return {
        ...state,
        userStatusLoading: RequestStates.success,
      };
    case actionTypes.USER_LOGIN_LOADING:
      return {
        ...state,
        requestState: RequestStates.loading,
      };
    case actionTypes.USER_LOGIN_SUCCESS:
      return {
        ...state,
        requestState: RequestStates.success,
        loginSuccess: payload.data.response_msg,
      };
    case actionTypes.USER_LOGIN_ERROR:
      return {
        ...state,
        requestState: RequestStates.error,
      };
    case actionTypes.USER_LOGOUT_LOADING:
      return {
        ...state,
        requestState: RequestStates.loading,
      };
    case actionTypes.USER_LOGOUT_SUCCESS:
      return {
        ...state,
        requestState: RequestStates.success,
        logoutSuccess: payload.data.response_msg,
      };
    case actionTypes.USER_LOGOUT_ERROR:
      return {
        ...state,
        requestState: RequestStates.error,
        logoutSuccess: 'Login failed',
      };
    case actionTypes.GET_USER_INFO_LOADING:
      return {
        ...state,
        requestState: RequestStates.loading,
      };
    case actionTypes.GET_USER_INFO_SUCCESS: {
      const userName = payload.data.find((user) => user.username === state.userInfo.email);
      if (state.users.length > 0) {
        return state;
      }
      return {
        ...state,
        requestState: RequestStates.success,
        users: payload.data,
        agentList: payload.data,
        userInfo: {
          ...state.userInfo,
          firstname: userName.firstname,
        },
      };
    }
    case actionTypes.GET_USER_INFO_ERROR:
      return {
        ...state,
        requestState: RequestStates.error,
      };
    case actionTypes.GET_LOGIN_STATUS_LOADING:
      return {
        ...state,
        requestState: RequestStates.loading,
      };
    case actionTypes.GET_LOGIN_STATUS_SUCCESS:
      return {
        ...state,
        requestState: RequestStates.success,
        loginStatus: payload.data.response_msg,
        userInfo: {
          ...state.userInfo,
          email: payload.data.email,
          agentid: payload.data.accountuserid,
        },
      };
    case actionTypes.GET_LOGIN_STATUS_ERROR:
      return {
        ...state,
        requestState: RequestStates.error,
      };
    case actionTypes.SET_OFFSET_TIME:
      return {
        ...state,
        offsetTime: payload,
      };
    case actionTypes.SET_SERVER_TIME:
      return {
        ...state,
        serverTime: payload,
      };
    case actionTypes.SHOW_ERROR_MODAL:
      return {
        ...state,
        errorModal: payload,
      };
    case actionTypes.SET_TRACKING_MODAL:
      return {
        ...state,
        timeTrackingStopped: payload,
      };
    case actionTypes.SET_TIME_TRACKING_LOADING:
      return {
        ...state,
        serverTimeTrackingState: RequestStates.loading,
      };
    case actionTypes.SET_TIME_TRACKING_SUCCESS: {
      const totalSecs = payload.data[0].total_secs;
      const totalMobileSecs = payload.data[0].total_mobile_secs;
      const totalMobileSecsIgnored = payload.data[0].total_mobile_secs_ignored;
      return {
        ...state,
        serverTimeTrackingState: RequestStates.success,
        serverTimeTracking: totalSecs - totalMobileSecs,
        serverMobileSecs: totalMobileSecs,
        serverMobileSecsIgnored: totalMobileSecsIgnored,
        lastActivityTime: moment(),
      };
    }
    case actionTypes.SET_TIME_TRACKING_ERROR:
      return {
        ...state,
        serverTimeTrackingState: RequestStates.error,
      };
    case actionTypes.SET_TIME_TRACKING_FOR_MOBILE_LOADING:
      return {
        ...state,
        serverTimeTrackingState: RequestStates.loading,
      };
    case actionTypes.SET_TIME_TRACKING_FOR_MOBILE_SUCCESS:
      return {
        ...state,
        serverTimeTrackingState: RequestStates.success,
        serverTimeTracking: payload && payload.data[0] && payload.data[0].total_secs,
        lastActivityTime: moment(),
      };
    case actionTypes.SET_TIME_TRACKING_FOR_MOBILE_ERROR:
      return {
        ...state,
        serverTimeTrackingState: RequestStates.error,
      };
    case actionTypes.SET_TOTAL_TIME_TRACKING_LOADING:
      return {
        ...state,
        serverTotalTimeTrackingState: RequestStates.loading,
      };
    case actionTypes.SET_TOTAL_TIME_TRACKING_SUCCESS: {
      const totalSecs = payload.data[0].total_secs;
      const totalMobileSecs = payload.data[0].total_mobile_secs;
      const totalMobileSecsIgnored = payload.data[0].total_mobile_secs_ignored;
      return {
        ...state,
        serverTotalTimeTrackingState: RequestStates.success,
        serverTotalTimeTracking: totalSecs - totalMobileSecs,
        serverTotalMobileSecs: totalMobileSecs,
        serverTotalMobileSecsIgnored: totalMobileSecsIgnored,
      };
    }
    case actionTypes.SET_TOTAL_TIME_TRACKING_ERROR:
      return {
        ...state,
        serverTotalTimeTrackingError: RequestStates.error,
      };
    case actionTypes.GET_WORKING_HOURS_INFO_LOADING:
      return {
        ...state,
        workingHoursInfoState: RequestStates.loading,
      };
    case actionTypes.GET_WORKING_HOURS_INFO_SUCCESS:
      return {
        ...state,
        workingHoursInfoState: RequestStates.success,
        workingHoursInfo: payload.data.reverse(),
      };
    case actionTypes.GET_WORKING_HOURS_INFO_ERROR:
      return {
        ...state,
        workingHoursInfoError: RequestStates.error,
      };
    case actionTypes.SET_WORKING_HOURS_DIALOG:
      return {
        ...state,
        workingHoursModal: payload,
      };
    case actionTypes.SET_CHANGE_PASSWORD_DIALOG:
      return {
        ...state,
        changePasswordDialog: payload,
      };
    case actionTypes.SET_CHANGE_USER_PASSWORD_LOADING:
      return {
        ...state,
        changePasswordState: RequestStates.loading,
      };
    case actionTypes.SET_CHANGE_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        changePasswordState: RequestStates.success,
        changePassword: payload,
      };
    case actionTypes.SET_CHANGE_USER_PASSWORD_ERROR:
      return {
        ...state,
        workingHoursInfoError: RequestStates.error,
      };
    case actionTypes.SET_INVOICE_LOADING:
      return {
        ...state,
        invoiceState: RequestStates.loading,
      };
    case actionTypes.SET_INVOICE_SUCCESS:
      return {
        ...state,
        invoiceState: RequestStates.success,
      };
    case actionTypes.SET_INVOICE_ERROR:
      return {
        ...state,
        invoiceState: RequestStates.error,
      };
    case actionTypes.SET_MULTI_THEME_MODAL:
      return {
        ...state,
        multiThemeModal: payload,
      };
    case actionTypes.SET_APP_SHORTCUTS_MODAL:
      return {
        ...state,
        appShortcutsModal: payload,
      };
    default:
      return state;
  }
};
