import React from 'react';
import classnames from 'classnames';
import moment from 'moment';
import { MdMoreVert } from 'react-icons/md';

import SantizingHtml from '../../../../../shared/components/SanitizingHtml/SanitizingHtml';
import AttachmentInfo from '../../../../../shared/components/AttachmentInfo/AttachmentInfo';
import Note from '../../../../../shared/components/Note/Note';
import {
  formatHTML, noop, getTimeValue, displayDateTimeFormat,
} from '../../../../../utils';
import { BASE_URL } from '../../../../../config';
import { RenderDropDown } from '../../../../../shared/components/FormControl';

const NoteSection = ({
  handleAttachmentDownload = noop,
  handleLightBoxPreview = noop,
  handleViewImgCick = noop,
  uniqueId = '',
  fileAttachment = {},
  handleEditNote = noop,
  searchValue = undefined,
  handleClick = noop,
  offsetTime = '',
  toastMsg = {},
  noteMsg = {},
  msgInfo = {},
}) => (
  <>
    <div
      key={`${toastMsg.messageid}-notesection-component`}
      className={classnames('notesection', {
        new_messagges: msgInfo.newMessage,
      })}
    >
      <div className="noteheader">
        <div className="header_left">
          <strong className="user_name">{msgInfo.user_full_name}</strong>
          {toastMsg.message && (
            <SantizingHtml
              className="note_content"
              htmlToRender={formatHTML(
                toastMsg.message.replace(/#/g, ''),
                toastMsg.format,
                offsetTime,
              )}
            />
          )}
        </div>
        {toastMsg.datecreated && (
          <div className="header_right">
            {msgInfo.isPushedRecord
              && moment(toastMsg.datecreated).format(displayDateTimeFormat)}
            {msgInfo.isPushedRecord
              && `${' '}(${moment(msgInfo.datecreated).fromNow()})`}
            {!msgInfo.isPushedRecord
              && getTimeValue(toastMsg.datecreated, 'both', offsetTime)}
          </div>
        )}
        <RenderDropDown
          icon={<MdMoreVert />}
          id={uniqueId}
          className="messgae_box_more_options"
          popoverClass="more_options_list"
        >
          <li role="presentation" onClick={() => handleEditNote(uniqueId, noteMsg)}>
            Edit
          </li>
          <li role="presentation">
            Delete
          </li>
        </RenderDropDown>
      </div>
      {msgInfo.rtype === 'R' && noteMsg && noteMsg.length === 0 && (
        <Note>resolved the ticket</Note>
      )}
      {noteMsg && noteMsg.length > 0 && (
        <Note>
          <>
            {noteMsg && noteMsg.map((messageItem) => (
              messageItem.message && (
                <SantizingHtml
                  key={`${messageItem.message_groupid}-SantizingHtml`}
                  onClick={(evt) => handleClick(evt)}
                  className="note_content"
                  htmlToRender={formatHTML(
                    messageItem.message,
                    messageItem.format,
                    offsetTime,
                    searchValue,
                  )}
                />
              )
            ))}
            {fileAttachment
              && fileAttachment.length !== 0
              && fileAttachment.map(
                (file, index) => file.attachment
                  && file.attachment.map((attachmentItem) => (
                    <AttachmentInfo
                      src={`${BASE_URL}command.jsp?command=file_link&file=${file.message}`}
                      fileName={attachmentItem.filename}
                      btntitle="download"
                      onClick={() => handleAttachmentDownload(file.message)}
                      handleViewClick={() => handleViewImgCick(
                        attachmentItem.filename,
                        file.message,
                        attachmentItem.filetype,
                      )}
                      handleImageClick={() => handleLightBoxPreview(index)}
                      fileType={attachmentItem.filetype}
                      fileSize={attachmentItem.filesize}
                    />
                  )),
              )}
          </>
        </Note>
      )}
    </div>
  </>
);

export default NoteSection;
