import React from 'react';
import Select from 'react-select';
import { DateRangePicker } from 'react-date-range';

import CustomDialog from '../../CustomDialog/CustomDialog';
import { SimpleInput, RenderButton } from '../../FormControl';
import { noop } from '../../../../utils';

const CustomSearchDialog = ({
  ticketStatus = [],
  handleStatusSelect = noop,
  selectedStatus = [],
  selectedAgents = [],
  searchString = '',
  setSearchString = noop,
  setSearchTabName = noop,
  agentsListAll = [],
  handleAgentsSelect = noop,
  unassignChecked = false,
  setUnassignChecked = noop,
  setInvertChecked = noop,
  invertChecked = false,
  customSearchModal = false,
  setCustomSearchModal = noop,
  handleCustomSearch = noop,
  dateRangePicker = {},
  setDateRangePicker = noop,
  isDateEnable = false,
  setDateEnable = noop,
  setSubjectRef = noop,
  handleCancel = noop,
  searchTabName = '',
}) => (
  <CustomDialog
    isOpen={customSearchModal}
    toggle={setCustomSearchModal}
    title="Custom Search"
    classNames="custom_search_dialog"
  >
    <form className="search_dialog_container">
      <SimpleInput
        type="text"
        value={searchString}
        onChange={(e) => setSearchString(e.target.value)}
        placeholder="Search........"
        className="search_key"
        innerRef={setSubjectRef}
        onKeyUp={handleCancel}
      />
      <div className="status_agent_select">
        <div className="status_select">
          <span>Status:</span>
          <Select
            isMulti
            options={ticketStatus}
            value={selectedStatus}
            onChange={handleStatusSelect}
            onKeyDown={handleCancel}
            className="status_react_select"
            classNamePrefix="react-select"
          />
        </div>
        <div className="agent_select">
          <span>Agents:</span>
          <Select
            isMulti
            options={agentsListAll}
            value={selectedAgents}
            onChange={handleAgentsSelect}
            onKeyDown={handleCancel}
            // menuIsOpen={true}
            classNamePrefix="react-select"
          />
        </div>
      </div>
      <div className="date_range_picker">
        <label className="daterange_picker_checkbox">
          <input
            type="checkbox"
            checked={isDateEnable}
            onChange={() => setDateEnable(!isDateEnable)}
          />
          <span>Search By Date</span>
        </label>
        {isDateEnable && (
          <DateRangePicker
            onChange={(item) => setDateRangePicker([item.selection])}
            showSelectionPreview
            moveRangeOnFirstSelection={false}
            ranges={dateRangePicker}
            direction="horizontal"
            maxDate={new Date()}
          />
        )}
      </div>
      <div className="checkbox_items">
        <label className="unassigned_checkbox">
          <input
            type="checkbox"
            checked={unassignChecked}
            onChange={() => setUnassignChecked(!unassignChecked)}
          />
          <span>Include unassigned</span>
        </label>
        <label className="invert_checkbox">
          <input
            type="checkbox"
            checked={invertChecked}
            onChange={() => setInvertChecked(!invertChecked)}
          />
          <span>Invert Search</span>
        </label>
      </div>
      <div className="search_tab_name">
        <span className="text_bolder">Search name (optional):</span>
        <SimpleInput
          type="text"
          value={searchTabName}
          onChange={(e) => setSearchTabName(e.target.value)}
          placeholder="Tab name"
          className="search_key"
        />
      </div>
      <div className="search_dialog_button">
        <RenderButton
          type="submit"
          onClick={handleCustomSearch}
        >
          submit
        </RenderButton>
      </div>
    </form>
  </CustomDialog>
);

export default CustomSearchDialog;
