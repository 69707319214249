import * as actionTypes from './actionTypes';
import api from '../../../utils/api';
import { store } from '../../../index';

export const setCustomSearchModal = () => ({
  type: actionTypes.SET_CUSTOM_SEARCH_MODAL,
});

export const setNewTicketModal = (isOpen, type) => ({
  type: actionTypes.SET_NEW_TICKET_MODAL,
  payload: { isOpen, type },
});

export const getTickets = () => ({
  type: actionTypes.GET_TICKETS,
  payload: api.get('command.jsp?command=tickets'),
});

export const setSearchKeyword = (q) => ({
  type: actionTypes.SET_SEARCH_KEYWORD,
  payload: { keyword: q, type: 'search' },
});

export const setRecipients = (q) => ({
  type: actionTypes.SET_SEARCH_KEYWORD,
  payload: { keyword: q, type: 'search' },
});

export const getSearchTickets = (q) => ({
  type: actionTypes.GET_SEARCH_TICKETS,
  payload: api.get(`command.jsp?command=tickets&q=${encodeURIComponent(q)}`),
});

export const setSearchedTickets = (key, data) => ({
  type: actionTypes.SET_SEARCHED_TICKETS,
  payload: { key, data },
});

export const removeActiveTab = (key, type) => ({
  type: actionTypes.REMOVE_ACTIVE_TAB,
  payload: new Promise((resolve) => {
    resolve({ key, type });
  }),
});

export const setInputType = (type) => ({
  type: actionTypes.SET_INPUT_TYPE,
  payload: type,
});

export const setInputSubmitType = (type) => ({
  type: actionTypes.SET_INPUT_SUBMIT_TYPE,
  payload: type,
});

export const setButtonInputClick = (isclick) => ({
  type: actionTypes.SET_BUTTON_INPUT_CLICK,
  payload: isclick,
});

export const setLoadingState = (key, isLoading) => ({
  type: actionTypes.SET_LOADING_STATE,
  payload: new Promise((resolve) => {
    resolve({ key, isLoading });
  }),
});

export const setActiveTabLoading = (key, isLoading) => ({
  type: actionTypes.SET_ACTIVE_TAB_LOADING,
  payload: new Promise((resolve) => {
    resolve({ key, isLoading });
  }),
});

// pushed into reducer

export const updateMessageData = (conversationid, name, date, rtype, message, groupid) => ({
  type: actionTypes.UPDATE_MESSAGE_DATA,
  payload: {
    conversationid, name, date, rtype, message, groupid,
  },
});

export const updateNoteData = (conversationid, name, date, rtype, message, rstatus, groupid) => ({
  type: actionTypes.UPDATE_NOTE_DATA,
  payload: {
    conversationid, name, date, rtype, message, rstatus, groupid,
  },
});

export const updateReopenTicket = (conversationid, name, date, rtype, message, groupid) => ({
  type: actionTypes.UPDATE_REOPEN_TICKET,
  payload: {
    conversationid, name, date, rtype, message, groupid,
  },
});

export const updateTransferTicket = (conversationid, name, userId, date,
  userName, message, rstatus, groupid) => ({
  type: actionTypes.UPDATE_TRANSFER_TICKET,
  payload: {
    conversationid, name, userId, date, userName, message, rstatus, groupid,
  },
});

export const updatePostponeTicket = (conversationid, name, date, message,
  postPoneDate, rstatus, groupid) => ({
  type: actionTypes.UPDATE_POSTPONE_TICKET,
  payload: {
    conversationid, name, date, message, postPoneDate, rstatus, groupid,
  },
});

export const updateResolvedTicket = (conversationid, date, firstname, groupid) => ({
  type: actionTypes.UPDATE_RESOLVED_TICKET,
  payload: {
    conversationid, date, firstname, groupid,
  },
});

export const setMessagesRead = (conversationid) => ({
  type: actionTypes.SET_MESSAGES_READ,
  payload: { id: conversationid },
});

export const setActiveTabs = (tabs) => {
  const storeItem = store.getState();
  if (storeItem && storeItem.tickets && !storeItem.tickets.fetchedState) {
    return {
      type: 'noop',
      payload: new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            data: {
              response_msg: 'SAVED',
            },
          });
        }, 500);
      }),
    };
  }
  const fileData = new FormData();
  fileData.append('saved_state', tabs);
  fileData.append('command', 'save_state');
  return ({
    type: actionTypes.SET_ACTIVE_TABS,
    payload: api.post('command.jsp', fileData),
  });
};

export const pushActiveTabList = (tabList) => ({
  type: actionTypes.PUSH_ACTIVE_TAB_LIST,
  payload: tabList,
});

export const pushActiveTabs = (tabInfo) => ({
  type: actionTypes.PUSH_ACTIVE_TABS,
  payload: {
    type: tabInfo.type,
    key: tabInfo.key,
    subject: tabInfo.subject,
    code: tabInfo.code,
    conversationid: tabInfo.conversationid,
    status: tabInfo.status,
    agentid: tabInfo.agentid,
    unassigned: tabInfo.unassigned,
    invert: tabInfo.invert,
    dateStart: tabInfo.dateStart,
    dateEnd: tabInfo.dateEnd,
    meta: tabInfo.meta,
    length: tabInfo.length,
    lastDays: tabInfo.lastDays,
    tabName: tabInfo.tabName,
  },
});

export const getActiveTabs = () => ({
  type: actionTypes.GET_ACTIVE_TABS,
  payload: api.get('command.jsp?command=get_state'),
});

export const setScrollPostion = (type, key, position) => ({
  type: actionTypes.SET_SCROLL_POSITION,
  payload: { type, key, position },
});

export const setMainTicketScroll = (position) => ({
  type: actionTypes.SET_MAIN_TICKET_SCROLL,
  payload: position,
});

export const getTypingMessage = (conversationid) => ({
  type: actionTypes.GET_TYPING_MESSAGE,
  meta: conversationid,
  payload: api.get(`command.jsp?command=draft&conversationid=${conversationid}`),
});

export const removeTypingMessage = (conversationid) => ({
  type: actionTypes.REMOVE_TYPING_MESSAGE,
  payload: conversationid,
});

export const setTypingMessage = (conversationid, type, message) => ({
  type: actionTypes.SET_TYPING_MESSAGE,
  payload: api.get(`command.jsp?command=draft&action=set&conversationid=${conversationid}&type=${type}&text=${encodeURIComponent(message)}`),
});

// New api

export const setChatInfoList = (id, message, navigate) => ({
  type: actionTypes.SET_CHAT_INFO_LIST,
  payload: new Promise((resolve) => {
    resolve({ id, message, navigate });
  }),
});

export const getChatPreviewInfo = (code) => ({
  type: actionTypes.GET_CHAT_PREVIEW_INFO,
  payload: api.get(`command.jsp?command=tickets&code=${code}`),
});

export const setTicketLoading = (key, type, isLoading) => ({
  type: actionTypes.SET_TICKET_LOADING,
  payload: { key, type, isLoading },
});

export const setMaxDate = (date) => ({
  type: actionTypes.SET_MAX_DATE,
  payload: date,
});

export const setViewStatusAgent = (idList) => ({
  type: actionTypes.SET_VIEW_STATUS_AGENT,
  payload: api.get(`command.jsp?command=view_state&view_state=${idList || ''}`),
});

export const getViewStatusOfAgent = () => ({
  type: actionTypes.GET_VIEW_STATUS_OF_AGENT,
  payload: api.get('command.jsp?command=view_state'),
});

export const setForwardEmailInput = (message) => ({
  type: actionTypes.SET_FORWARD_EMAIL_INPUT,
  payload: message,
});

export const setInputMessage = (message, cursorLocation) => ({
  type: actionTypes.SET_INPUT_MESSAGE,
  payload: { message, cursorLocation },
});

export const setNoteMessage = (message) => ({
  type: actionTypes.SET_NOTE_MESSAGE,
  payload: message,
});

export const setMessageSnippet = (messages) => ({
  type: actionTypes.SET_MESSAGE_SNIPPET,
  payload: messages,
});

export const setTabTitle = (key, title) => ({
  type: actionTypes.SET_TAB_TITLE,
  payload: { key, title },
});

export const setUploadFileInfo = (conversationid, fileInfo) => {
  const fileData = new FormData();
  fileData.append('upload_file', fileInfo);
  fileData.append('command', 'upload');
  return ({
    type: actionTypes.SET_UPLOAD_FILE_INFO,
    meta: conversationid,
    payload: api.post('command.jsp', fileData),
  });
};

export const attchmentVisible = (isAttchment) => ({
  type: actionTypes.ATTCHMENT_VISIBLE,
  payload: isAttchment,
});

export const removeAttchmentItem = (conversationid) => ({
  type: actionTypes.REMOVE_ATTCHMENT_ITEM,
  payload: { id: conversationid },
});

export const footerActionsLoading = (id, isLoading) => ({
  type: actionTypes.FOOTER_ACTIONS_LOADING,
  payload: { id, isLoading },
});

export const setNewExternalTicket = (name, to, subject, bcc, cc) => ({
  type: actionTypes.SET_NEW_EXTERNAL_TICKET,
  payload: api.get(`command.jsp?command=add_ticket&full_name=${name || ''}&to=${to || ''}&subject=${subject || ''}&bcc=${bcc || ''}&cc=${cc || ''}`),
});

export const setNewInternalTicket = (subject, message) => ({
  type: actionTypes.SET_NEW_INTERNAL_TICKET,
  payload: api.get(`command.jsp?command=add_ticket&subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(message || '')}&ticket_type=internal`),
});

export const createNewTicketLoading = (isLoading) => ({
  type: actionTypes.CREATE_NEW_TICKET_LOADING,
  payload: isLoading,
});

export const setForwardMessageInfo = (id, email) => ({
  type: actionTypes.SET_FORWARD_MESSAGE_INFO,
  payload: { id, email },
});

export const getParticipants = (id) => ({
  type: actionTypes.GET_PARTICIPANTS,
  meta: id,
  payload: api.get(`command.jsp?command=get_participants&conversationid=${id}`),
});

export const setParticipantsModal = (isVisible) => ({
  type: actionTypes.SET_PARTICIPANTS_MODAL,
  payload: isVisible,
});

export const setParticipants = (cid, to, cc, bcc) => ({
  type: actionTypes.SET_PARTICIPANTS,
  meta: {
    cid, To: to, CC: cc, BCC: bcc,
  },
  payload: api.get(`command.jsp?command=set_participants&conversationid=${cid}&to=${to}&cc=${cc}&bcc=${bcc}`),
});

export const setSplitTicket = (cid, gid) => ({
  type: actionTypes.SET_SPLIT_TICKET,
  payload: api.get(`command.jsp?command=split_ticket&conversationid=${cid}&message_groupid=${gid}`),
});

export const setTransferTicket = (cid, agentName, status, agentId, body, attachment) => ({
  type: actionTypes.SET_TRANSFER_TICKET,
  payload: api.get(`command.jsp?command=update_ticket&conversationid=${cid}&status=T&agentname2=${agentName || ''}&rstatus=${status}&agentid2=${agentId || ''}&message=${encodeURIComponent(body || '')}&filelinks=${attachment || ''}`),
});

export const setWebNotification = (webInfo) => ({
  type: actionTypes.SET_WEB_NOTIFICATION,
  payload: {
    notify: webInfo.notify,
    audible: webInfo.audible,
    title: webInfo.title,
    timeout: webInfo.timeout,
    audibleType: webInfo.audibleType,
    onClickAction: webInfo?.onClickAction,
  },
});

export const setPrevActiveTabInfo = (tab) => ({
  type: actionTypes.SET_PREV_ACTIVE_TAB_INFO,
  payload: tab,
});

export const setGroupChatLoading = (isLoading) => ({
  type: actionTypes.SET_GROUP_CHAT_LOADING,
  payload: isLoading,
});

export const getTempCodeInfo = (name, email) => ({
  type: actionTypes.GET_TEMP_CODE_INFO,
  payload: api.get(`command.jsp?command=temp_code&name=${name}&email=${email}&expire=30`),
});

export const getUploadLink = (code) => ({
  type: actionTypes.GET_UPLOAD_LINK,
  payload: api.get(`command.jsp?command=get_upload_link&code=${code}`),
});

export const getCustomSearchTicket = (q, status,
  agentid, unassigned, invert, startDate, endDate) => ({
  type: actionTypes.GET_CUSTOM_SEARCH_TICKET,
  payload: endDate ? api.get(`command.jsp?command=tickets&q=${encodeURIComponent(q)}&status=${status || ''}&assigned=${agentid || ''}&unassigned=${unassigned || ''}&not=${invert || ''}&date_start=${startDate || ''}&date_end=${endDate || ''}`) : api.get(`command.jsp?command=tickets&q=${encodeURIComponent(q)}&status=${status || ''}&assigned=${agentid || ''}&unassigned=${unassigned || ''}&not=${invert || ''}&date_start=${startDate || ''}`),
});

export const saveAssignTicketsLength = (length) => ({
  type: actionTypes.SAVE_ASSIGN_TICKETS_LENGTH,
  payload: length,
});

export const setGroupChatDialog = (isOpen) => ({
  type: actionTypes.SET_GROUP_CHAT_DIALOG,
  payload: isOpen,
});

export const setGroupChatInfo = (conversationid, data) => ({
  type: actionTypes.SET_GROUP_CHAT_INFO,
  payload: { conversationid, messages: data },
});

export const pushedGroupChatMessage = (conversationid, name, date, groupid, message, userid) => ({
  type: actionTypes.PUSHED_GROUP_CHAT_MESSAGE,
  payload: {
    conversationid, name, date, groupid, message, userid,
  },
});

export const changeRenderMessageType = (cid, msgId) => ({
  type: actionTypes.CHANGE_RENDER_MESSAGE_TYPE,
  payload: { conversationid: cid, message_groupid: msgId },
});

export const setGroupChatId = (id, code) => ({
  type: actionTypes.SET_GROUP_CHAT_ID,
  payload: { id, code },
});

// export const removeChatObject = () => ({
//   type: actionTypes.REMOVE_CHAT_OBJECT,
// });

export const setTicketSearchValue = (id, value) => ({
  type: actionTypes.SET_TICKET_SEARCH_VALIUE,
  payload: { id, value },
});

export const setGroupChatWidth = (width) => ({
  type: actionTypes.SET_GROUP_CHAT_WIDTH,
  payload: width,
});

export const setEditNote = (id, msg, editMode, inputType, ref) => ({
  type: actionTypes.SET_EDIT_NOTE,
  payload: {
    id, msg, editMode, inputType, ref,
  },
});

export const setGroupChatInputvalue = (value) => ({
  type: actionTypes.SET_GROUP_CHAT_INPUT_VALIUE,
  payload: value,
});
