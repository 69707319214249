import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

import * as actions from '../../../../redux/actions';
import TicketTable from './TicketTable';

import { noop, getOffset } from '../../../../../../utils';

import './ticket_table.scss';

let timerId;
const debounce = (fn, delay) => { // eslint-disable-line
  return ((...args) => {
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      fn(...args);
      clearTimeout(timerId);
      timerId = null;
    }, delay);
  })();
};

const preventRendering = (prevProps, nextProps) => {
  if (JSON.stringify(prevProps) !== JSON.stringify(nextProps)) {
    return false;
  }
  return true;
};

const TicketTableContainer = React.memo(({
  setMainTicketScroll,
  mainTicketScrollto,
  setScrollPostion,
  isHiddenFeatures,
  isTodoOnTop,
  isFlagOnTop,
  isOpenTicket,
  offsetTime,
  todoList,
  gridData,
  tickets,
  flags,
  users,
}) => {
  const location = useLocation();

  const [tooltipShow, setTooltipShow] = useState(false);
  const RefTicketListDesktop = useRef(null);
  const { search } = location;
  const params = new URLSearchParams(search);
  const q = params.get('q');
  const meta = params.get('meta');

  const onScrollTicketListDebounce = () => {
    if (search) {
      if (meta) {
        if (tickets[`${q}-cs`] && !tickets[`${q}-cs`].isLoading) {
          setScrollPostion(
            'search', `${q}-cs`, RefTicketListDesktop.current && RefTicketListDesktop.current.scrollTop,
          );
        }
      } else if (tickets[q] && !tickets[q].isLoading) {
        setScrollPostion(
          'search', q, RefTicketListDesktop.current && RefTicketListDesktop.current.scrollTop,
        );
      }
    } else {
      setMainTicketScroll(RefTicketListDesktop.current && RefTicketListDesktop.current.scrollTop);
    }
  };

  const onScrollTicketList = () => debounce(() => onScrollTicketListDebounce(), 500);

  const setPreviousScrollPosition = () => {
    if (search) {
      if (!RefTicketListDesktop.current) return;
      RefTicketListDesktop.current.scrollTop = tickets[q]
      && tickets[q].scrollTo;
    } else {
      if (!RefTicketListDesktop.current) return;
      RefTicketListDesktop.current.scrollTop = mainTicketScrollto;
    }
  };

  const handleCodeCopy = (e) => {
    e.stopPropagation();
    if (e.target) {
      const offset = getOffset(e.target);
      setTooltipShow({ x: e.pageX, y: offset.top });
    } else {
      setTooltipShow({});
    }
    setTimeout(() => {
      setTooltipShow(false);
    }, 1000);
  };

  useEffect(() => {
    setPreviousScrollPosition();
  }, []);

  return (
    <>
    <TicketTable
      flags={flags}
      users={users}
      gridData={gridData}
      isSearchPage={search}
      offsetTime={offsetTime}
      todoList={todoList}
      tooltipShow={tooltipShow}
      isTodoOnTop={isTodoOnTop}
      isFlagOnTop={isFlagOnTop}
      isOpenTicket={isOpenTicket}
      handleCodeCopy={handleCodeCopy}
      onScrollTicketList={onScrollTicketList}
      RefTicketListDesktop={RefTicketListDesktop}
      isHiddenFeatures={isHiddenFeatures}
    />
    </>
  );
},
(prevProps, nextProps) => preventRendering(prevProps, nextProps));

TicketTableContainer.propTypes = {
  offsetTime: PropTypes.string,
  isHiddenFeatures: PropTypes.bool,
  isFlagOnTop: PropTypes.bool,
  isTodoOnTop: PropTypes.bool,
  isOpenTicket: PropTypes.bool,
  mainTicketScrollto: PropTypes.number,
  setScrollPostion: PropTypes.func,
  setMainTicketScroll: PropTypes.func,
  gridData: PropTypes.instanceOf(Array),
  users: PropTypes.instanceOf(Array),
  todoList: PropTypes.instanceOf(Array),
  tickets: PropTypes.instanceOf(Object),
  flags: PropTypes.instanceOf(Object),
};

TicketTableContainer.defaultProps = {
  offsetTime: '',
  mainTicketScrollto: 0,
  setScrollPostion: noop,
  setMainTicketScroll: noop,
  isHiddenFeatures: false,
  isFlagOnTop: false,
  isTodoOnTop: false,
  isOpenTicket: false,
  gridData: [],
  users: [],
  todoList: [],
  tickets: {},
  flags: {},
};

const mapStateToProps = (state) => ({
  users: state.auth.users,
  tickets: state.tickets.tickets,
  offsetTime: state.auth.offsetTime,
  mainTicketScrollto: state.tickets.mainTicketScrollto,
  isHiddenFeatures: state.localInfo.isHiddenFeatures,
  isFlagOnTop: state.localInfo.isFlagOnTop,
  isTodoOnTop: state.localInfo.isTodoOnTop,
  isOpenTicket: state.localInfo.isOpenTicket,
  flags: state.localInfo.flags,
  todoList: state.localInfo.todoList,
});

const mapDispatchToProps = (dispatch) => ({
  setScrollPostion: (type, key, position) => dispatch(actions
    .setScrollPostion(type, key, position)),
  setMainTicketScroll: (position) => dispatch(actions.setMainTicketScroll(position)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketTableContainer);
