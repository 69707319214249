/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import BlockUi from "@availity/block-ui";
import { Form, Field } from "react-final-form";

import CrushftpLogo from "../../../../assets/images/crushftp-logo.png";
import {
  RenderButton,
  RenderInput,
} from "../../../../shared/components/FormControl";
import { SpinnerLoader } from "../../../../shared/components/Loader";
const Signin = ({
  onSubmit, // Enable the onSubmit prop to be passed and used
  LoadingState,
  isRememberMe,
  handleRememberMe,
  initialValues,
  validate,
}) => (
  // <BlockUi
  //   message=" "
  //   tag="div"
  //   blocking={LoadingState}
  //   className="full_height full_width block-ui-background"
  //   loader={<SpinnerLoader />}
  // >
    <div className="signin_section">
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
      >
        {({ handleSubmit, submitting }) => (
          <form className="form_section" onSubmit={handleSubmit}>
            <img alt="logo" src={CrushftpLogo} className="logo" />
            <div className="form_input">
              <Field
                name="username"
                id="username"
                placeholder="Email"
                type="text"
                component={RenderInput}
              />
              <Field
                name="password"
                id="password"
                placeholder="Password"
                type="password"
                // component={RenderInput}
              >
                {(props) => (
                  <div>
                    <RenderInput {...props} />
                  </div>
                )}
              </Field>
            </div>
            <label className="remember_me">
              <input
                type="checkbox"
                checked={isRememberMe}
                onChange={(e) => handleRememberMe(e.target.checked)}
              />
              <span>Remember me</span>
            </label>
            <div className="form_btn">
              <RenderButton type="submit" disabled={submitting}>
                Login
              </RenderButton>
            </div>
          </form>
        )}
      </Form>
    </div>
  // </BlockUi>
);

Signin.propTypes = {
  onSubmit: PropTypes.func.isRequired, // Make onSubmit a required prop
  handleRememberMe: PropTypes.func.isRequired,
  LoadingState: PropTypes.bool,
  isRememberMe: PropTypes.bool,
  initialValues: PropTypes.object,
  validate: PropTypes.func,
};

export default Signin;

const MyForm = ({ onSubmit }) => {
  return (
    <Form
      initialValues={{
        firstName: "Dan",
      }}
      onSubmit={onSubmit}
      validate={(values) => {
        // do validation here, and return errors object
      }}
    >
      {({ handleSubmit, pristine, form, submitting }) => (
        <form onSubmit={handleSubmit}>
          <div>
            <Field
              name="firstName"
              component="input"
              type="text"
              placeholder="First Name"
            />
          </div>
          <div>
            <label>Last Name</label>
            <Field
              name="lastName"
              component="input"
              type="text"
              placeholder="Last Name"
            />
          </div>
          <div>
            <button type="submit" disabled={submitting}>
              Submit
            </button>
            <button
              type="button"
              disabled={pristine || submitting}
              onClick={form.reset}
            >
              Clear Values
            </button>
          </div>
        </form>
      )}
    </Form>
  );
};

export { MyForm };
