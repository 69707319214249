import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import TodoList from './TodoList';
import * as localInfoActions from '../../../../../TodoRedux/actions';
import { TodoDialog } from '../../../../Dialogs';
import { noop } from '../../../../../../utils';

import './todo_list.scss';

const TodoListContainer = ({
  removeTodo,
  setTodoList,
  todoList,
}) => {
  const navigate = useNavigate();

  const [isTodoDialog, setTodoDialog] = useState(false);
  const handlePushTodo = (link) => {
    if (link) { navigate(link); }
  };

  const handleRemoveTodo = (id) => {
    const prevTodoList = JSON.parse(localStorage.getItem('todo-list'));
    const filterTodo = prevTodoList.filter((todo) => todo.id !== id);
    localStorage.setItem('todo-list', filterTodo.length > 0 ? JSON.stringify(filterTodo) : []);
    removeTodo(id);
  };

  useEffect(() => {
    const prevTodoList = localStorage.getItem('todo-list') ? JSON.parse(localStorage.getItem('todo-list')) : '';
    if (prevTodoList.length > 0 && todoList.length === 0) {
      setTodoList(prevTodoList);
    }
  }, []);

  return (
    <>
      <TodoList
        handleRemoveTodo={handleRemoveTodo}
        handlePushTodo={handlePushTodo}
        setTodoDialog={setTodoDialog}
        todoList={todoList.reverse()}
      />
      {isTodoDialog && (
        <TodoDialog
          isModal={isTodoDialog}
          handleModal={() => setTodoDialog(false)}
        />
      )}
    </>
  );
};

TodoListContainer.propTypes = {
  setTodoList: PropTypes.func,
  removeTodo: PropTypes.func,
  todoList: PropTypes.instanceOf(Array),
};

TodoListContainer.defaultProps = {
  setTodoList: noop,
  removeTodo: noop,
  todoList: [],
};

const mapStateToProps = (state) => ({
  todoList: state.localInfo.todoList,
});

const mapDispatchToProps = (dispatch) => ({
  setTodoList: (todo) => dispatch(localInfoActions.setTodoList(todo)),
  removeTodo: (id) => dispatch(localInfoActions.removeTodo(id)),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(TodoListContainer);
