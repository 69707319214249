import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import RecipientsForm from './RecipientsForm';
import { actions } from '../../../../../modules/tickets';
import { noop, validateMultiEmails } from '../../../../../utils';
import { showNotification } from '../../../../../utils/Notifications';

import './recipients_form.scss';

const RecipientsFormContainer = ({
  setParticipantsModal,
  setParticipants,
  chatInfo,
}) => {
  const { conversationid } = useParams();

  const participantsInfo = chatInfo[conversationid].participants;
  const onSubmit = (values, form) => {
    setParticipants(conversationid, values.To, values.CC, values.BCC)
      .then((res) => {
        if (res.value.data.response_msg === 'Success') {
          setParticipantsModal(false);
          showNotification('Recipients updated successfully', 'success', 5000);
          form.reset();
        } else {
          showNotification(res.value.data.response_msg, 'error', 5000);
        }
      });
  };

  const validateForm = (values) => {
    const errors = {};
    if (values.To && !validateMultiEmails(values.To)) {
      errors.To = "Invalid email address";
    }
    if (values.CC && !validateMultiEmails(values.CC)) {
      errors.CC = "Invalid email address";
    }
    if (values.BCC && !validateMultiEmails(values.BCC)) {
      errors.BCC = "Invalid email address";
    }
    return errors;
  };

  const ToInfo = participantsInfo.filter((participantsItem) => participantsItem.role.toLowerCase() === 'to');
  const CCInfo = participantsInfo.filter((participantsItem) => participantsItem.role.toLowerCase() === 'cc');
  const BCCInfo = participantsInfo.filter((participantsItem) => participantsItem.role.toLowerCase() === 'bcc');

  return (
    <RecipientsForm
      onSubmit={onSubmit}
      validate={validateForm}
      initialValues={{
        To: ToInfo ? ToInfo.map((ccemail) => ccemail.emails).join(',') : '',
        CC: CCInfo ? CCInfo.map((ccemail) => ccemail.emails).join(',') : '',
        BCC: BCCInfo ? BCCInfo.map((ccemail) => ccemail.emails).join(',') : '',
      }}
    />
  );
};

RecipientsFormContainer.propTypes = {
  setParticipants: PropTypes.func,
  setParticipantsModal: PropTypes.func,
  chatInfo: PropTypes.instanceOf(Array),
};

RecipientsFormContainer.defaultProps = {
  setParticipants: noop,
  setParticipantsModal: noop,
  chatInfo: {},
};

const mapStateToProps = (state) => ({
  chatInfo: state.tickets.chat,
});

const mapDispatchToProps = (dispatch) => ({
  setParticipants:
    (cid, to, cc, bcc) => dispatch(actions.setParticipants(cid, to, cc, bcc)),
  setParticipantsModal:
    (isVisible) => dispatch(actions.setParticipantsModal(isVisible)),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(RecipientsFormContainer);