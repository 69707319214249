import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import TopNavigation from './TopNavigation';
import * as localInfoActions from '../../../../TodoRedux/actions';
import useDoubleClick from '../../../DoubleClick';
import { CustomSearchDialog, ChangePaawordDialog, HiddenFeatureDialog, ShortcutsDialog } from '../../../Dialogs';
import { actions as ticketActions } from '../../../../../modules/tickets';
import { actions as authFormsActions } from '../../../../../modules/authForms';
import { setMobileSideNavbar } from '../../redux/actions';
import { withDeviceType } from '../../../../../hoc';
import { hiddenFeatureOptions, noop } from '../../../../../utils';

import './top_navigation.scss';

const TopNavigationContainer = React.memo(({
  setChangePasswordDialog,
  setHiddenFeaturesModel,
  handleMobileExpanded,
  changePasswordDialog,
  setCustomSearchModal,
  setGroupChatDialog,
  setMultiThemeModal,
  customSearchModal,
  handleHiddenFeatures,
  setMessagesRead,
  setAppShortcutsModal,
  isMobileExpanded,
  groupChatInfo,
  deviceType,
  setFlags,
  userInfo,
  isTheme,
  isTodo,
  flags,
  chat,
  fetchedState,
}) => {
  const [isSearch, setSearch] = useState(false);
  const profilInfoRef = useRef();

  const handleHeaderExpander = () => (
    handleMobileExpanded(!isMobileExpanded)
  );

  const handleGroupChatDialog = () => {
    if (!groupChatInfo.groupChatDialog) {
      setMessagesRead(groupChatInfo.groupChatId);
      setGroupChatDialog(true);
      localStorage.setItem('group-chat', JSON.stringify(true));
      setTimeout(() => {
        const inputArea = document.getElementById('gorup_chat_textarea');
        inputArea.focus();
      }, 100);
    }
  };

  const handleSearch = () => (
    setSearch(true)
  );

  useEffect(() => {
    const groupChat = localStorage.getItem('group-chat');
    if (groupChat) { setGroupChatDialog(JSON.parse(groupChat)); }
    const localflags = localStorage.getItem('flags');

    hiddenFeatureOptions.forEach((feature) => {
      const isFeature = localStorage.getItem(feature);
      handleHiddenFeatures(feature, isFeature ? JSON.parse(isFeature) : false);
    });
    if (localflags && JSON.stringify(localflags) !== JSON.stringify(flags)) {
      setFlags(JSON.parse(localflags));
    }
  }, []);

  useDoubleClick({
    onSingleClick: () => {},
    onDoubleClick: (e) => {
      if (e.altKey) { setHiddenFeaturesModel(true); }
    },
    ref: profilInfoRef,
    latency: 250,
  });

  const fetchGroupChatLength = () => {
    if (chat && chat[groupChatInfo.groupChatId] && chat[groupChatInfo.groupChatId].messageInfo) {
      return chat[groupChatInfo.groupChatId].messageInfo.filter((msg) => msg.newMessage).length;
    }
    return 0;
  };

  return (
    <>
      <TopNavigation
        userInfo={userInfo}
        isSearch={isSearch}
        setSearch={setSearch}
        deviceType={deviceType}
        isTheme={isTheme}
        handleSearch={handleSearch}
        profilInfoRef={profilInfoRef}
        setAppShortcutsModal={setAppShortcutsModal}
        setMultiThemeModal={setMultiThemeModal}
        setCustomSearchModal={setCustomSearchModal}
        handleGroupChatDialog={handleGroupChatDialog}
        handleHeaderExpander={handleHeaderExpander}
        isTodo={isTodo}
        setChangePasswordDialog={setChangePasswordDialog}
        groupChatCount={fetchGroupChatLength().toString()}
        fetchedState={fetchedState}
      />
      {customSearchModal && <CustomSearchDialog />}
      {changePasswordDialog && <ChangePaawordDialog />}
      <HiddenFeatureDialog />
      <ShortcutsDialog />
    </>
  );
});

TopNavigationContainer.propTypes = {
  deviceType: PropTypes.string,
  isMobileExpanded: PropTypes.bool,
  changePasswordDialog: PropTypes.bool,
  customSearchModal: PropTypes.bool,
  isTodo: PropTypes.bool,
  isTheme: PropTypes.bool,
  handleMobileExpanded: PropTypes.func,
  setCustomSearchModal: PropTypes.func,
  setChangePasswordDialog: PropTypes.func,
  setMultiThemeModal: PropTypes.func,
  setGroupChatDialog: PropTypes.func,
  handleHiddenFeatures: PropTypes.func,
  setFlags: PropTypes.func,
  setHiddenFeaturesModel: PropTypes.func,
  setMessagesRead: PropTypes.func,
  setAppShortcutsModal: PropTypes.func,
  flags: PropTypes.instanceOf(Object),
  userInfo: PropTypes.instanceOf(Object),
  groupChatInfo: PropTypes.instanceOf(Object),
  chat: PropTypes.instanceOf(Object),
  fetchedState: PropTypes.bool,
};

TopNavigationContainer.defaultProps = {
  deviceType: '',
  isMobileExpanded: false,
  changePasswordDialog: false,
  customSearchModal: false,
  isTodo: false,
  isTheme: false,
  handleMobileExpanded: noop,
  setCustomSearchModal: noop,
  setChangePasswordDialog: noop,
  setMultiThemeModal: noop,
  setGroupChatDialog: noop,
  handleHiddenFeatures: noop,
  setFlags: noop,
  setHiddenFeaturesModel: noop,
  setMessagesRead: noop,
  setAppShortcutsModal: noop,
  userInfo: {},
  flags: {},
  groupChatInfo: {},
  chat: {},
  fetchedState: false,
};

const mapStateToProps = (state) => ({
  isMobileExpanded: state.sideNavbar.isMobileExpanded,
  changePasswordDialog: state.auth.changePasswordDialog,
  customSearchModal: state.tickets.customSearchModal,
  userInfo: state.auth.userInfo,
  flags: state.localInfo.flags,
  isTodo: state.localInfo.isTodo,
  isTheme: state.localInfo.isTheme,
  groupChatInfo: state.tickets.groupChatInfo,
  chat: state.tickets.chat,
  fetchedState: state.tickets.fetchedState,
});

const mapDispatchToProps = (dispatch) => ({
  setHiddenFeaturesModel: (isOpen) => dispatch(localInfoActions.setHiddenFeaturesModel(isOpen)),
  handleMobileExpanded:
  (isMobileExpanded) => dispatch(setMobileSideNavbar(isMobileExpanded)),
  setCustomSearchModal: () => dispatch(ticketActions.setCustomSearchModal()),
  setChangePasswordDialog:
    (isVisible) => dispatch(authFormsActions.setChangePasswordDialog(isVisible)),
  setAppShortcutsModal: (isOpen) => dispatch(authFormsActions.setAppShortcutsModal(isOpen)),
  setMultiThemeModal:
    (isOpen) => dispatch(authFormsActions.setMultiThemeModal(isOpen)),
  setGroupChatDialog: (isOpen) => dispatch(ticketActions.setGroupChatDialog(isOpen)),
  handleHiddenFeatures: (type, isChecked) => dispatch(
    localInfoActions.handleHiddenFeatures(type, isChecked),
  ),
  setFlags: (data) => dispatch(localInfoActions.setFlags(data)),
  setMessagesRead: (conversationid) => dispatch(ticketActions.setMessagesRead(conversationid)),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(withDeviceType(TopNavigationContainer));