import React from "react";
import PropTypes from "prop-types";
import { Form, Field } from "react-final-form";

import { RenderInput, RenderButton } from "../../../FormControl";
import { noop } from "../../../../../utils";

const ChangePasswordForm = ({ onSubmit, validate }) => (
  <Form
    onSubmit={onSubmit}
    validate={validate}
    render={({ handleSubmit }) => (
      <form onSubmit={handleSubmit} className="change_password_form">
        <div className="password_input_wrapper">
          <span>Current Password: </span>
          <Field
            name="currentpassword"
            id="currentpassword"
            placeholder="Current Password"
            type="password"
            component={RenderInput}
          />
        </div>
        <div className="password_input_wrapper">
          <span>Password: </span>
          <Field
            name="password"
            id="password"
            placeholder="Password"
            type="password"
            component={RenderInput}
          />
        </div>
        <div className="password_input_wrapper">
          <span>Re-enter Password: </span>
          <Field
            name="reenterpassword"
            id="reenterpassword"
            placeholder="Re-enter Password"
            type="password"
            component={RenderInput}
          />
        </div>
        <div className="change_password_btn">
          <RenderButton>Submit</RenderButton>
        </div>
      </form>
    )}
  />
);

ChangePasswordForm.propTypes = {
  onSubmit: PropTypes.func,
  validate: PropTypes.func,
};

ChangePasswordForm.defaultProps = {
  onSubmit: noop,
  validate: noop,
};

export default ChangePasswordForm;
