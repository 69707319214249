import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import IdleActivityDialog from './IdleActivityDialog';
import { actions } from '../../../../modules/authForms';
import { actions as ticketAction } from '../../../../modules/tickets';
import { hasMobileDevice, noop } from '../../../../utils';

import './idle_activity_dialog.scss';

const IdleActivityDialogContainer = React.memo(({
  setTrackingModal,
  timeTrackingStopped,
  setPauseTracking,
  getTickets,
  setTimeTracking,
  setTimeTrackingForMobile,
}) => {
  const handleReconnect = () => {
    getTickets();
    setPauseTracking(false);
    setTrackingModal(false);
    setTimeout(() => {
      if (hasMobileDevice()) {
        setTimeTrackingForMobile();
      } else {
        setTimeTracking().then(() => {
          console.log('54-> IdleActivityDialogContainer #setTimeTracking');
        }).catch(() => {
          console.log('54-> IdleActivityDialogContainer #setTimeTracking');
        });
      }
      console.log('54-> IdleActivityDialogContainer #setTimeTracking');
    }, 1000);
  };
  return (
    <IdleActivityDialog
      handleReconnect={handleReconnect}
      timeTrackingStopped={timeTrackingStopped}
    />
  );
});

IdleActivityDialogContainer.propTypes = {
  timeTrackingStopped: PropTypes.bool,
  setTrackingModal: PropTypes.func,
  getTickets: PropTypes.func,
  setPauseTracking: PropTypes.func,
  setTimeTracking: PropTypes.func,
  setTimeTrackingForMobile: PropTypes.func,
};

IdleActivityDialogContainer.defaultProps = {
  timeTrackingStopped: false,
  setTrackingModal: noop,
  getTickets: noop,
  setPauseTracking: noop,
  setTimeTracking: noop,
  setTimeTrackingForMobile: noop,
};

const mapStateToProps = (state) => ({
  timeTrackingStopped: state.auth.timeTrackingStopped,
});

const mapDispatchToProps = (dispatch) => ({
  setTrackingModal: (isTracking) => dispatch(actions.setTrackingModal(isTracking)),
  setPauseTracking: (isPause) => dispatch(actions.setPauseTracking(isPause)),
  getTickets: () => dispatch(ticketAction.getTickets()),
  setTimeTracking: () => dispatch(actions.setTimeTracking()),
  setTimeTrackingForMobile: () => dispatch(actions.setTimeTrackingForMobile()),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(IdleActivityDialogContainer);
