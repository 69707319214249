/* eslint-disable */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import base64 from "base-64";

import Signin, { MyForm } from "./Signin";
import RequestStates from "../../../../utils/request-states";
import * as actions from "../../redux/actions";
import { required } from "../../../../utils/validators";
import { showNotification } from "../../../../utils/Notifications";
import { noop } from "../../../../utils";

import "./signin.scss";

const SigninContainer = React.memo(
  ({ showErrorModal, LoadingState, userLogin }) => {
    const navigate = useNavigate();
    const [isRememberMe, setRememberMe] = useState(false);

    if (LoadingState) {
      console.log("LoadingState", LoadingState);
    }
    const onSubmit = (values) => {
      userLogin(values.username, values.password)
        .then((res) => {
          if (res.value.data.response_msg === "BAD") {
            showNotification("Login Failed", "error", false);
          } else if (res.value.data.response_msg === "OK") {
            if (isRememberMe) {
              localStorage.setItem(
                "crushftp-username",
                base64.encode(values.username)
              );
              localStorage.setItem(
                "crushftp-password",
                base64.encode(values.password)
              );
            } else if (!isRememberMe) {
              localStorage.removeItem("crushftp-username");
              localStorage.removeItem("crushftp-password");
            }
            navigate("/ticket");
            showErrorModal(false);
          }
        })
        .catch((error) => {
          console.error("Error logging in:", error);
        });
    };

    const validateForm = (values) => {
      const errors = {};
      if (!values.username) {
        errors.username = "Required";
      }
      if (!values.password) {
        errors.password = "Required";
      }
      return errors;
    };

    const handleRememberMe = (isChecked) => {
      setRememberMe(isChecked);
    };

    useEffect(() => {
      const username = localStorage.getItem("crushftp-username");
      const password = localStorage.getItem("crushftp-password");
      if (username && password) {
        setRememberMe(true);
      }
    }, []);

    return (
      <>
        <Helmet>
          <title>Login User</title>
        </Helmet>
        <Signin
          initialValues={{
            username: localStorage.getItem("crushftp-username")
              ? base64.decode(localStorage.getItem("crushftp-username"))
              : "",
            password: localStorage.getItem("crushftp-password")
              ? base64.decode(localStorage.getItem("crushftp-password"))
              : "",
          }}
          onSubmit={onSubmit}
          validate={validateForm}
          handleRememberMe={handleRememberMe}
          isRememberMe={isRememberMe}
          LoadingState={LoadingState}
        />
      </>
    );
  }
);

SigninContainer.propTypes = {
  LoadingState: PropTypes.bool,
  userLogin: PropTypes.func,
  showErrorModal: PropTypes.func,
};

SigninContainer.defaultProps = {
  LoadingState: false,
  userLogin: noop,
  showErrorModal: noop,
};

const mapStateToProps = (state) => ({
  LoadingState: state.auth.requestState === RequestStates.loading,
});

const mapDispatchToProps = (dispatch) => ({
  userLogin: (username, password) =>
    dispatch(actions.userLogin(username, password)),
  showErrorModal: (isOpen) => dispatch(actions.showErrorModal(isOpen)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SigninContainer);
