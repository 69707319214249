import anchorme from 'anchorme';
import moment from 'moment-timezone';

import { codeMatch } from './validators';
import { maxAllowedFileSize } from '../config';

export const serverDateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
export const displayDateTimeFormat = 'YYYY-MM-DD hh:mm:ss a';
export const DateTimeWithoutSecondFormat = 'DD-MM-YYYY hh:mm a';
export const ServerDateTimeWithoutSecondFormat = 'YYYY-MM-DD hh:mm a';
export const OnlyDate = 'DD-MM-YYYY';
export const displayDateTimeFormatShort = 'YYYY-MM-DD';

export const noop = () => { };

export const getDataValue = (obj) => obj && obj.data && obj.data.length > 0 && obj.data[0];

export const deepCopy = (json) => json && JSON.parse(JSON.stringify(json));

export const hasMobileDevice = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

export const ItemFromPayload = (payload,
  item) => (item && payload && payload.data ? payload.data[item] : null);

// methods return a device-type & Orientation
// ------------------------------------------------------------------

export const getDeviceDetector = () => {
  const indicator = document.getElementById('device-detecor');
  return {
    deviceType: window.getComputedStyle(indicator).getPropertyValue('--device-type'),
    orientation: window.matchMedia('(orientation: portrait)').matches ? 'portrait' : 'landscape',
  };
};

// remove all data after certain line
// ------------------------------------------------------------------

export const removeSpecificString = (string) => {
  const filterData = string.indexOf('To view ticket history please follow the link:');
  return string.substring(0, filterData !== -1 ? filterData - 4 : string.length);
};

export const removeStringAfterCertainStr = (str, key) => str.substring(0, str.indexOf(key));

// add UrlLinks when code match
// ------------------------------------------------------------------

export const parseToLink = (value) => {
  const match = value.match(codeMatch);
  if (match && match.length > 0) {
    const uniqueCodes = [...new Set(match)];
    let str = value;

    uniqueCodes.forEach((code) => {
      const regex = new RegExp(code, 'g');
      str = str.replace(
        regex,
        `<a class='ticket-code' href='/#/ticket?q=${code}'>${code}</a>`,
      );
    });
    return str;
  }
  return value;
};

// methods formate html when get from server side
// ------------------------------------------------------------------

export const isHTML = (str) => {
  const doc = new DOMParser().parseFromString(str, 'text/html');
  return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
};

const removeStyleTag = (htmlString) => {
  // Remove style tags using regex
  const regex = /<style\b[^<]*(?:(?!<\/style>)<[^<]*)*<\/style>/gi;
  const cleanedString = htmlString.replace(regex, '');

  return cleanedString;
};

export const cleanWordFormatting = (input) => {
  const str = input.replace(/\\<\\!\[if \\!supportLists\]\\>/g, '').replace(/\\<\\!\[endif\]\\>/g, '').replace(/&gt;/g, '>');
  const converted = removeStyleTag(str);
  return converted;
};

export const extractHTMLTags = (input) => {
  const textDom = document.createElement('div');
  textDom.textContent = input;
  return textDom.innerHTML;
};

export const linkifyString = (string) => anchorme(string, {
  attributes: [
    {
      name: 'target',
      value: '_blank',
    }],
});

export const getTimeValue = (timestamp, type, offset) => {
  const realOffset = -(parseFloat(offset) * 60);
  const dateMoment = moment(timestamp, serverDateTimeFormat).add(
    realOffset,
    'm',
  );
  let labelValue = dateMoment.format(displayDateTimeFormat);
  if (type === 'date') {
    labelValue = dateMoment.format('L');
  } else if (type === 'time') {
    labelValue = dateMoment.format('LT');
  } else if (type === 'datetime') {
    labelValue = dateMoment.format(displayDateTimeFormat);
  } else if (type === 'future-both') {
    const time = dateMoment.format('MM/DD/YYYY hh:mm a');
    // const fromTime = dateMoment.isAter(moment()) ? `(${dateMoment.fromNow()})` : '';
    labelValue = time;
  } else if (type === 'both') {
    const time = dateMoment.format(displayDateTimeFormat);
    const fromTime = dateMoment.fromNow();
    labelValue = `${time}${' '}(${fromTime})`;
  } else if (type === 'fromnow') {
    const fromTime = dateMoment.fromNow();
    labelValue = `(${fromTime})`;
  }
  return labelValue;
};

export const groupChatDateTime = (timestamp, offset, type) => {
  let dateMoment = '';
  const realOffset = -(parseFloat(offset) * 60);
  if (type === 'minus') {
    dateMoment = moment(timestamp, serverDateTimeFormat).add(
      realOffset,
      'm',
    );
  } else if (type === 'plus') {
    dateMoment = moment(timestamp, serverDateTimeFormat);
  }

  let timeStamp = '';
  const today = moment().format('YYYY/MM/DD');
  const date = dateMoment.format('L');
  const time = dateMoment.format('hh:mm a');
  const curentDate = moment(date).isSame(today);
  if (curentDate) {
    timeStamp = 'Today';
  } else {
    timeStamp = dateMoment.format(OnlyDate);
  }
  return { date: timeStamp, time };
};

export const getTimeValuePlus = (timestamp, offset) => {
  const realOffset = -(parseFloat(offset) * 60);
  const dateMoment = moment(timestamp, serverDateTimeFormat).subtract(
    realOffset,
    'm',
  );
  return dateMoment.format(serverDateTimeFormat);
};

export const textEncode = (text) => {
  const newElem = document.createElement('div');
  newElem.innerText = text;
  return newElem.innerHTML;
};

const fetchSeach = (searchValue, messageContent) => {
  const regex = new RegExp(`${searchValue}`, 'gi');
  const match = messageContent.match(regex);
  if (match && match.length > 0) {
    let str = messageContent;
    const uniqueCodes = [...new Set(match)];
    uniqueCodes.map((code) => {
      const regex2 = new RegExp(code, 'g');
      str = str.replace(
        regex2,
        `<span class="highlight_msg" style="background-color: #ffff00c4!important;">${code}</span>`,
      );
      return true;
    });
    return str;
  }
  return messageContent;
};

const formatWithSearch = (searchValue, messageContent) => {
  if (searchValue) {
    return parseToLink(fetchSeach(searchValue, messageContent));
  }
  return parseToLink(messageContent);
};

const replaceAnchorInnerText = (htmlString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  const anchorTags = doc.getElementsByTagName('a');
  for (let i = 0; i < anchorTags.length; i += 1) {
    try {
      anchorTags[i].textContent = decodeURIComponent(anchorTags[i].textContent);
    } catch (error) {
      console.error(`Error decoding URI for anchor tag ${i}: ${error.message}`);
    }
  }

  return doc.documentElement.innerHTML;
};

export const formatHTML = (message, format, offsetTime, searchValue) => {
  let removedString = removeSpecificString(cleanWordFormatting(message));
  if (offsetTime) {
    if (message.indexOf('postponed ticket till') >= 0) {
      const time = message.split('postponed ticket till')[1].trim();
      const momentTime = moment(time);
      removedString = `Postpone until: ${
        getTimeValue(momentTime, 'future-both', offsetTime)}`;
    }
  }
  if (format === 'H') {
    const addUrl = formatWithSearch(searchValue, removedString);
    const linkifyHtmlString = linkifyString(`<div style="white-space: normal" class="message_style_overload">${addUrl}</div>`);
    const finalString = replaceAnchorInnerText(linkifyHtmlString);
    return finalString;
  }

  const parseDemo = textEncode(removedString).replace(
    /(?:\r\n|\r|\n)/g,
    '<br>',
  );
  const addUrl = formatWithSearch(searchValue, parseDemo);
  const linkifyHtmlString = linkifyString(`<div style="white-space: normal" class="message_style_overload">${addUrl}</div>`);
  const finalString = replaceAnchorInnerText(linkifyHtmlString);
  return finalString;
};
// split string by specific key
// ------------------------------------------------------------------

export const splitString = (string, splitKey) => string.split(splitKey)[1];

const extensionsToOpen = ['pdf', 'log', 'txt', 'gif', 'xml', 'rtf', 'ini', 'log.0'];
export const isFileViewable = (fileName) => {
  const ext = fileName.split('.').pop();
  return extensionsToOpen.indexOf(ext.toLowerCase()) >= 0;
};

const extensionsToImage = ['jpg', 'jpeg', 'png'];
export const isFileImage = (fileName) => {
  const ext = fileName.split('.').pop();
  return extensionsToImage.indexOf(ext.toLowerCase()) >= 0;
};

export const isNumeric = (n) => !isNaN(parseFloat(n)) && isFinite(n); //eslint-disable-line

/*eslint-disable*/
export const formatBytes = (size) => {
  if (!isNumeric(size)) return size;
  if (size < 0) return '*';
  const bytes = parseInt(size); //eslint-disable-line
  if ((bytes / 1024).toFixed(0) == 0) { return `${bytes} bytes`; }
  if ((bytes / 1024 / 1024).toFixed(0) == 0) { return `${(bytes / 1024).toFixed(1)} KB`; }
  if ((bytes / 1024 / 1024 / 1024).toFixed(0) == 0) { return `${(bytes / 1024 / 1024).toFixed(1)} MB`; }
  if ((bytes / 1024 / 1024 / 1024 / 1024).toFixed(0) == 0) { return `${(bytes / 1024 / 1024 / 1024).toFixed(1)} GB`; }
  if ((bytes / 1024 / 1024 / 1024 / 1024 / 1024).toFixed(0) == 0) { return `${(bytes / 1024 / 1024 / 1024 / 1024).toFixed(1)} TB`; }
  return bytes;
};
/*eslint-disable-end*/

// viewState
export const normalizeViewState2 = (items) => {
  const viewState = {};
  for (let i = 0; i < items.length; i++) {
      const curItem = items[i];
      for(const key in curItem){
          const tickets = curItem[key] ? curItem[key].split(',') : [];
          for (let j = 0; j < tickets.length; j++) {
              let ticketID = tickets[j];
              viewState[ticketID] = viewState[ticketID] || [];
              viewState[ticketID].push(key);
          }
      }
  }
  return viewState;
}

//Strip down emails
export const stripDownEmails = (emailString) => {
  let simpleEmails = "";
  if (emailString.indexOf(";") < 0) emailString = emailString.replace(/,/g, ";");
  let emails = emailString.split(";");
  for (let x = 0; x < emails.length; x++) {
      let s = emails[x];
      if (s.indexOf("<") >= 0 && s.indexOf(">") >= 0) {
          s = s.substring(s.indexOf("<") + 1, s.indexOf(">"));
      }
      s = s.trim();
      if (s == "") continue;
      if ((s.indexOf("@") > 0 && s.lastIndexOf(".") > s.indexOf("@")) || s.indexOf("{") >= 0) simpleEmails += s + ",";
  }
  if (simpleEmails != "") simpleEmails = simpleEmails.substring(0, simpleEmails.length - 1);
  return simpleEmails || emailString;
}

//Validate emails
export const validateMultiEmails = (string) => {
  const email = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  let emails = [];
  if(string.indexOf(',')>=0) {
    emails = string.split(',');
  }
  let valid = true;
  if(emails.length > 0) {
    for (let i = 0; i < emails.length; i++) {
      if(!email.test(emails[i])) {
        valid = false;
      }
    }
  } else {
    if (!email.test(string)) {
      valid = false;
    }
  }
  return valid;
}

export const moveArrayItemToNewIndex = (arr, old_index, new_index) => {
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
};

export const formatTime = (sec) => {
  var hrs = Math.floor(sec / 3600);
  var min = Math.floor((sec - (hrs * 3600)) / 60);
  var seconds = sec - (hrs * 3600) - (min * 60);
  seconds = Math.round(seconds * 100) / 100

  var result = (hrs < 10 ? "0" + hrs : hrs);
  result += ":" + (min < 10 ? "0" + min : min);
  // result += ":" + (seconds < 10 ? "0" + seconds : seconds);
  return result;
}

export const validateSize = (size) => {
  const uploadSize = size / 1024 / 1024; // data in MB
  if (uploadSize >= maxAllowedFileSize) {
      return false;
  } else {
    return true;
  }
}

const mimeTypes = {
  pdf: "application/pdf",
  log: "text/plain",
  txt: "text/plain",
  xml: "text/plain",
  ini: "text/plain",
  rtf: "text/plain",
  "log.0": "text/plain",
};
export const getMimeType = (ext, type) => {
  return mimeTypes[ext.toLowerCase()] || type;
}
export const getOffset = (el) => {
  const rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}

export const generateHoursInfoTable = (hoursInfo) => {
  const info = hoursInfo.map((hourItem) => {
    const flatHourItem = Object.entries(hourItem).flat();
    const date = flatHourItem[0];
    const fullHour = flatHourItem[1];
    const splitHour = fullHour.split('.');
    const totalHour = formatTime(splitHour[0]);
    const ignoredHour = formatTime(splitHour[1]);

    return `${date} - ${totalHour} - ${ignoredHour}\n `
  });
  return info.toString();
}

export const setWebNotificationUsingrtype = (webNotify, navigate) => {
  if (webNotify.rtype.toLowerCase() === 'j') {
    const time = webNotify.info.messages[0].message.split('##postponed ticket till##')[1].trim();
    return {
      notify: true,
      audible: true,
      title: `${webNotify.title} postponed ${webNotify.code} till ${time}`,
      timeout: 5000,
      audibleType: 'newReply',
      onClickAction: () => {
        console.log("webNotify?.conversationid && webNotify.code", webNotify?.conversationid, webNotify.code);
        if (navigate && webNotify?.conversationid && webNotify.code) {
          navigate(`/ticket/${webNotify.code}/${webNotify.conversationid}`);
        }
      },
    };
  } else if (webNotify.rtype.toLowerCase() === 't') {
    const transferName = webNotify.info.messages[0].message.split('##assigned ticket to##')[1].trim();
    return {
      notify: true,
      audible: true,
      title: `${webNotify.title} transferred ${webNotify.code} to ${transferName}`,
      timeout: 5000,
      audibleType: 'newReply',
      onClickAction: () => {
        console.log("webNotify?.conversationid && webNotify.code", webNotify?.conversationid, webNotify.code);
        if (navigate && webNotify?.conversationid && webNotify.code) {
          navigate(`/ticket/${webNotify.code}/${webNotify.conversationid}`);
        }
      },
    };
  } else if (webNotify.rtype.toLowerCase() === 'm' || webNotify.rtype.toLowerCase() === 'q' || webNotify.rtype.toLowerCase() === 'o') {
    return {
      notify: true,
      audible: true,
      title: `${webNotify.code}: ${webNotify.title} replied`,
      timeout: 5000,
      audibleType: 'newReply',
      onClickAction: () => {
        console.log("webNotify?.conversationid && webNotify.code", webNotify?.conversationid, webNotify.code);
        if (navigate && webNotify?.conversationid && webNotify.code) {
          navigate(`/ticket/${webNotify.code}/${webNotify.conversationid}`);
        }
      },
    };
  } else if (webNotify.rtype.toLowerCase() === 'r') {
    return {
      notify: true,
      audible: true,
      title: `${webNotify.title} resolved: ${webNotify.code}`,
      timeout: 5000,
      audibleType: 'resolved',
      onClickAction: () => {
        console.log("webNotify?.conversationid && webNotify.code", webNotify?.conversationid, webNotify.code);
        if (navigate && webNotify?.conversationid && webNotify.code) {
          navigate(`/ticket/${webNotify.code}/${webNotify.conversationid}`);
        }
      },
    };
  } else if (webNotify.rtype.toLowerCase() === 'i') {
    if (webNotify.groupChat && webNotify.groupChat === 'Yes') {
      return {
        notify: true,
        audible: true,
        title: `${webNotify.code}: ${webNotify.title}`,
        timeout: 5000,
        audibleType: 'newReply',
        onClickAction: () => {
          console.log("webNotify?.conversationid && webNotify.code", webNotify?.conversationid, webNotify.code);
          if (navigate && webNotify?.conversationid && webNotify.code) {
            navigate(`/ticket/${webNotify.code}/${webNotify.conversationid}`);
          }
        },
      };
    } else {
      return {
        notify: true,
        audible: true,
        title: `${webNotify.code}: ${webNotify.title} wrote a note`,
        timeout: 5000,
        audibleType: 'newReply',
        onClickAction: () => {
          console.log("webNotify?.conversationid && webNotify.code", webNotify?.conversationid, webNotify.code);
          if (navigate && webNotify?.conversationid && webNotify.code) {
            navigate(`/ticket/${webNotify.code}/${webNotify.conversationid}`);
          }
        },
      };
    }

  }
}

export const spliceString = (pos, oldString, newString) => oldString.slice(0, pos) + newString + oldString.slice(pos);

const fetchStartingDate = (days) => moment().subtract(days - 1, 'days').format('YYYY-MM-DD');

export const keyGeneration = (search) => {
  const params = new URLSearchParams(search);
  const q = params.get('q');
  const status = params.get('status');
  const agentid = params.get('agentid');
  const unassigned = params.get('unassigned');
  const invert = params.get('invert');
  const dateStart = params.get('date_start');
  const dateEnd = params.get('date_end');
  const lastDays = params.get('last_x_days');
  var result = '';  
  if (lastDays) {
    result = (q || '') + (status || '') + (agentid ? (agentid.replace(/,/g, '') || '') : '') + (unassigned || '') + (invert || '') + (fetchStartingDate(lastDays) || '') + (dateEnd || '') + (lastDays || '');
  } else {
    result = (q || '') + (status || '') + (agentid ? (agentid.replace(/,/g, '') || '') : '') + (unassigned || '') + (invert || '') + (dateStart || '') + (dateEnd || '');
  }
  return result;
}

export const hiddenFeatureOptions = ['isHiddenFeatures', 'isTodo', 'isSorting', 'isFlag', 'isTheme', 'isFlagOnTop', 'isTodoOnTop', 'isOpenTicket'];
