import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Auth from '../../hoc/auth'; // Ensure this is correctly imported
import MainTemplate from '../../shared/template/MainTemplate/MainTemplateContainer';
import { actions } from '../../shared/components/AppNavigation';
import { TicketListViewContainer } from '../../modules/tickets';
import { noop } from '../../utils';

const Tickets = ({ setSideNavbarItem }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    setSideNavbarItem('/ticket');
    return () => setSideNavbarItem('');
  }, []);

  return (
    <MainTemplate>
      <TicketListViewContainer />
    </MainTemplate>
  );
};

Tickets.propTypes = {
  setSideNavbarItem: PropTypes.func,
};

Tickets.defaultProps = {
  setSideNavbarItem: noop,
};

const mapDispatchToProps = (dispatch) => ({
  setSideNavbarExpand:
    (isExpanded) => dispatch(actions.setSideNavbarExpand(isExpanded)),
  setSideNavbarItem:
    (selectedSideNavbarItem) => dispatch(actions.setSideNavbarItem(selectedSideNavbarItem)),
});

export default connect(null, mapDispatchToProps)(Auth(Tickets));