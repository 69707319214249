import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { withDeviceType } from '../../../../../hoc';
import DefaultNote from './DefaultNote';
import * as actions from '../../../redux/actions';
import { noop } from '../../../../../utils';
import useHotkeys from '../../../../../utils/useHotkeys';

import './default_note.scss';

const DefaultNoteContainer = ({
  setButtonInputClick = noop,
  setInputSubmitType = noop,
  setInputType = noop,
  deviceType = '',
  chatInfo = {},
}) => {
  const matchParams = useParams();
  const handleReply = () => {
    setInputType('M');
    setInputSubmitType('');
    setButtonInputClick('OK');
  };

  const handleNote = () => {
    setInputType('N');
    setInputSubmitType('');
    setButtonInputClick('OK');
  };

  useHotkeys('ctrl+w', () => {
    if (chatInfo[matchParams.conversationid].previewInfo.emails) {
      handleReply();
      setTimeout(() => {
        const textEditor = document.getElementById('msg-text-editor');
        textEditor.focus();
        textEditor.selectionStart = 5;
        textEditor.selectionEnd = 5;
      }, 500);
    }
  }, [chatInfo[matchParams.conversationid].previewInfo]);

  useHotkeys('ctrl+r', () => {
    handleNote();
    setTimeout(() => {
      document.getElementById('note-editor').focus();
    }, 500);
  });

  return (
    <DefaultNote
      handleNote={handleNote}
      deviceType={deviceType}
      handleReply={handleReply}
      previewInfo={chatInfo[matchParams.conversationid]?.previewInfo}
    />
  );
};

const mapStateToProps = (state) => ({
  chatInfo: state.tickets.chat,
});

const mapDispatchToProps = (dispatch) => ({
  setInputType: (type) => dispatch(actions.setInputType(type)),
  setInputSubmitType: (type) => dispatch(actions.setInputSubmitType(type)),
  setButtonInputClick: (isclick) => dispatch(actions.setButtonInputClick(isclick)),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(withDeviceType(DefaultNoteContainer));
