/* eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import IdleTimerSection from "./IdleTimerSection";
import { actions as formActions } from "../../../modules/authForms";
import { actions as ticketActions } from "../../../modules/tickets";
import { hasMobileDevice, noop } from "../../../utils";
import { idleActivityTimeoutInMilliSeconds } from "../../../config";

let timerId;
const debounce = (fn, delay) => {
  if (timerId) {
    clearTimeout(timerId);
  }

  return new Promise((resolve) => {
    timerId = setTimeout(() => {
      resolve(fn());
      clearTimeout(timerId);
      timerId = null;
    }, delay);
  });
};

const IdleTimerSectionContainer = React.memo(
  ({
    setWebNotification,
    setTrackingModal,
    setPauseTracking,
    pauseTracking,
    setTimeTracking,
    setTimeTrackingForMobile,
  }) => {
    const lastActivityRef = React.useRef(Date.now());
    const refreshCountRef = React.useRef(0);
    const lastRefreshTimeRef = React.useRef(Date.now());
    const isUserActiveRef = React.useRef(false);
    const lastTabFocusRef = React.useRef(Date.now());

    const ACTIVITY_THRESHOLD = 5000; // 5 seconds
    const REFRESH_THRESHOLD = 10000; // 10 seconds
    const TAB_SWITCH_THRESHOLD = 2000; // 2 seconds
    const MAX_REFRESH_COUNT = 3;

    const logActivity = (action, details) => {
      const now = new Date();
      const timestamp = now.toLocaleString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      });

      // Enhanced action titles with reasons and specific events
      let actionTitle = action;
      if (action === "Action Rejected") {
        actionTitle = `❌ Activity Ignored: ${details.reason}`;
      } else if (action === "Activity Skipped") {
        actionTitle = `⏭️ Activity Skipped: ${details.reason}`;
      } else if (action === "Activity Logged") {
        const eventMapping = {
          mousemove: "Mouse Movement",
          keydown: "Keyboard Input",
          mousedown: "Mouse Click",
          wheel: "Mouse Wheel",
          mousewheel: "Mouse Wheel",
          touchstart: "Touch Start",
          touchmove: "Touch Movement",
          scroll: "Page Scroll",
        };

        // Only proceed with logging if it's a known event type
        const eventType = eventMapping[details.eventType];
        if (!eventType) return;

        actionTitle = `✅ Activity Tracked: ${eventType}`;
      } else if (action === "Suspicious Activity") {
        actionTitle = `⚠️ Suspicious: ${details.type}`;
      } else if (action === "User Idle") {
        actionTitle = `💤 User Idle: No activity for ${details.totalIdleTime}`;
      }

      const actionColor =
        {
          "❌ Activity Ignored": "#F44336",
          "✅ Activity Tracked": "#4CAF50",
          "⏭️ Activity Skipped": "#FF9800",
          "⚠️ Suspicious": "#FF5722",
          "💤 User Idle": "#9C27B0",
          "Visibility Change": "#00BCD4",
        }[actionTitle.split(":")[0]] || "#2196F3";

      console.groupCollapsed(
        `%c[${timestamp}] [ATT] ${actionTitle}`,
        `color: ${actionColor}; font-weight: bold; font-size: 12px;`
      );

      // Create detailed table for event information
      const relevantDetails = { ...details };
      console.table(relevantDetails);

      // Show state snapshot only for significant events
      if (actionTitle.includes("✅") || actionTitle.includes("💤")) {
        console.log("%cCurrent State:", "font-weight: bold;");
        console.table({
          "User Active": isUserActiveRef.current,
          "Last Activity": new Date(
            lastActivityRef.current
          ).toLocaleTimeString(),
          "Refresh Count": refreshCountRef.current,
          "Page Visible": document.visibilityState === "visible",
        });
      }

      console.groupEnd();
    };

    // Handle tab visibility and focus changes
    React.useEffect(() => {
      const handleVisibilityChange = () => {
        const isVisible = document.visibilityState === "visible";
        const now = Date.now();

        logActivity("Visibility Change", {
          isVisible,
          timeSinceLastFocus: now - lastTabFocusRef.current,
          refreshCount: refreshCountRef.current,
        });

        if (isVisible) {
          if (now - lastTabFocusRef.current > REFRESH_THRESHOLD) {
            logActivity("Resetting Counters", {
              reason: "Long absence from tab",
              oldRefreshCount: refreshCountRef.current,
            });
            refreshCountRef.current = 0;
            lastRefreshTimeRef.current = now;
          }
          lastTabFocusRef.current = now;
        }
      };

      const handleFocus = () => {
        const now = Date.now();
        logActivity("Tab Focus", {
          timeSinceLastFocus: now - lastTabFocusRef.current,
        });

        if (now - lastTabFocusRef.current > REFRESH_THRESHOLD) {
          logActivity("Resetting Counters", {
            reason: "Tab refocus after threshold",
            oldRefreshCount: refreshCountRef.current,
          });
          refreshCountRef.current = 0;
          lastRefreshTimeRef.current = now;
        }
        lastTabFocusRef.current = now;
      };

      window.addEventListener("visibilitychange", handleVisibilityChange);
      window.addEventListener("focus", handleFocus);

      return () => {
        window.removeEventListener("visibilitychange", handleVisibilityChange);
        window.removeEventListener("focus", handleFocus);
      };
    }, []);

    // Reset refresh counter periodically
    React.useEffect(() => {
      const resetRefreshCount = () => {
        refreshCountRef.current = 0;
        lastRefreshTimeRef.current = Date.now();
      };

      const intervalId = setInterval(resetRefreshCount, REFRESH_THRESHOLD);
      return () => clearInterval(intervalId);
    }, []);

    React.useEffect(() => {
      const handleBeforeUnload = (event) => {
        const now = Date.now();

        logActivity("Page Unload", {
          isUserActive: isUserActiveRef.current,
          timeSinceLastFocus: now - lastTabFocusRef.current,
          refreshCount: refreshCountRef.current,
        });

        if (now - lastTabFocusRef.current < TAB_SWITCH_THRESHOLD) {
          logActivity("Ignoring Refresh", {
            reason: "Tab switch detected",
          });
          return;
        }

        if (!isUserActiveRef.current) {
          if (now - lastRefreshTimeRef.current < REFRESH_THRESHOLD) {
            refreshCountRef.current += 1;
            logActivity("Refresh Counter Increased", {
              newCount: refreshCountRef.current,
              threshold: MAX_REFRESH_COUNT,
            });
          }

          if (refreshCountRef.current > MAX_REFRESH_COUNT) {
            logActivity("Suspicious Activity", {
              type: "Excessive Refreshes",
              count: refreshCountRef.current,
              timeWindow: REFRESH_THRESHOLD,
            });
          }
        }
      };

      window.addEventListener("beforeunload", handleBeforeUnload);
      return () =>
        window.removeEventListener("beforeunload", handleBeforeUnload);
    }, []);

    const isLegitimateUserAction = (event) => {
      if (!event?.isTrusted) {
        logActivity("Action Rejected", {
          reason: "Untrusted event source",
          eventType: event?.type,
        });
        return false;
      }

      const isRealUserAction =
        event.type !== "focus" &&
        event.type !== "blur" &&
        event.type !== "visibilitychange";

      const isSuspiciousPattern =
        event.clientX === 0 &&
        event.clientY === 0 &&
        event.screenX === 0 &&
        event.screenY === 0;

      if (!isRealUserAction || isSuspiciousPattern) {
        logActivity("Action Rejected", {
          reason: isSuspiciousPattern
            ? "Suspicious coordinates"
            : "System generated event",
          eventType: event.type,
        });
      }

      return isRealUserAction && !isSuspiciousPattern;
    };

    const onAction = (event) => {
      const now = Date.now();
      const timeSinceLastActivity = now - lastActivityRef.current;

      isUserActiveRef.current = timeSinceLastActivity < ACTIVITY_THRESHOLD;

      if (!isLegitimateUserAction(event)) {
        return;
      }

      // Define known events
      const eventMapping = {
        mousemove: "Mouse Movement",
        keydown: "Keyboard Input",
        mousedown: "Mouse Click",
        wheel: "Mouse Wheel",
        mousewheel: "Mouse Wheel",
        touchstart: "Touch Start",
        touchmove: "Touch Movement",
        scroll: "Page Scroll",
      };

      // Return early if event type is unknown
      if (!eventMapping[event?.type]) {
        return;
      }

      if (timeSinceLastActivity >= ACTIVITY_THRESHOLD) {
        lastActivityRef.current = now;

        logActivity("Activity Logged", {
          eventType: event?.type,
          timeSinceLastActivity: `${(timeSinceLastActivity / 1000).toFixed(
            2
          )}s`,
          isMobile: hasMobileDevice(),
        });

        debounce(() => {
          if (hasMobileDevice()) {
            setTimeTrackingForMobile();
          } else {
            setTimeTracking()
              .then(() => logActivity("Activity Logged", { status: "Success" }))
              .catch((error) =>
                logActivity("Action Rejected", {
                  reason: "API Error",
                  error: error.message,
                })
              );
          }
        }, 1000);
      } else {
        logActivity("Activity Skipped", {
          reason: `Too soon (${(timeSinceLastActivity / 1000).toFixed(
            2
          )}s since last activity)`,
          eventType: event?.type,
        });
      }
    };

    const onIdle = () => {
      const idleTime = (Date.now() - lastActivityRef.current) / 1000;
      logActivity("User Idle", {
        totalIdleTime: `${idleTime.toFixed(2)}s`,
        lastActiveTime: new Date(lastActivityRef.current).toLocaleTimeString(),
        wasActive: isUserActiveRef.current,
      });

      isUserActiveRef.current = false;
      if (!pauseTracking) {
        setPauseTracking(!pauseTracking);
        setTrackingModal(true);
        setWebNotification({
          notify: true,
          audible: true,
          title: "Due to inactivity your time tracking is stopped",
          timeout: 5000,
          audibleType: "idle",
        });

        logActivity("Tracking Paused", {
          reason: "User idle timeout reached",
        });
      }
    };

    return (
      <IdleTimerSection
        debounce={500}
        pauseTracking={pauseTracking}
        onIdle={onIdle}
        timeout={idleActivityTimeoutInMilliSeconds}
        onAction={onAction}
      />
      // <div>hu   </div>
    );
  }
);

IdleTimerSectionContainer.propTypes = {
  pauseTracking: PropTypes.bool,
  setTrackingModal: PropTypes.func,
  setWebNotification: PropTypes.func,
  setPauseTracking: PropTypes.func,
  setTimeTracking: PropTypes.func,
  setTimeTrackingForMobile: PropTypes.func,
};

const mapStateToProps = (state) => ({
  pauseTracking: state.auth.pauseTracking,
});

const mapDispatchToProps = (dispatch) => ({
  setTrackingModal: (isOpen) => dispatch(formActions.setTrackingModal(isOpen)),
  setPauseTracking: (isPause) =>
    dispatch(formActions.setPauseTracking(isPause)),
  setWebNotification: (webInfo) =>
    dispatch(ticketActions.setWebNotification(webInfo)),
  setTimeTracking: () => dispatch(formActions.setTimeTracking()),
  setTimeTrackingForMobile: () =>
    dispatch(formActions.setTimeTrackingForMobile()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IdleTimerSectionContainer);
