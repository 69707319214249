import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import App from './App';
import * as serviceWorker from './serviceWorker';
import reduxStore from './store';

export const store = reduxStore; // eslint-disable-line

window.onload = () => {
  const container = document.getElementById('root');
  const root = createRoot(container);

  root.render(
    <Provider store={reduxStore}>
      <App />
    </Provider>
  );

  window.addEventListener('dragover', (e) => {
    e.preventDefault();
  }, false);

  window.addEventListener('dragenter', (e) => {
    e.preventDefault();
  }, false);

  window.addEventListener('dragleave', (e) => {
    e.preventDefault();
  }, false);

  window.addEventListener('dragend', (e) => {
    e.preventDefault();
  }, false);

  window.addEventListener('drop', (e) => {
    e.preventDefault();
  }, false);
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
