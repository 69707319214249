import React from "react";
import PropTypes from "prop-types";
import BlockUi from "@availity/block-ui";

import ActionFooter from "../ActionFooter/ActionFooterContainer";
import MessageHeader from "./MessageHeader/MessageHeaderContainer";
import TypingInfo from "./TypingInfo/TypingInfoContainer";
import MessageWrapper from "./MessageWrapper/MessageWrapperContainer";
import { SpinnerLoader } from "../../../../shared/components/Loader";
import { noop } from "../../../../utils";
import { useParams } from "react-router";

const MessageList = ({
  onScrollMessageList = noop,
  setMessageReadable = noop,
  scrollToBottom = noop,
  typingLoading = true,
  chatLoading = true,
  msgListRef = {},
  // match = {},
}) => {
  const params = useParams();
  return (
    <div className="ticket_message_section">
      <div className="ticket_messages_container" id="ticket-messages-container">
        {!chatLoading && <MessageHeader />}
        <BlockUi
          message=" "
          tag="div"
          blocking={chatLoading}
          className="chat_content_wrapper block-ui-background"
          renderChildren={false}
          loader={<SpinnerLoader />}
        >
          <div
            className="ticket_list"
            id="ticket-messages"
            onClick={() => setMessageReadable()}
            role="presentation"
            ref={msgListRef}
            onScroll={onScrollMessageList}
          >
            <MessageWrapper key="message-wrapper-list" />
          </div>
          {!chatLoading && !typingLoading && (
            <TypingInfo conversationid={params.conversationid} />
          )}
          <div className="text_editor" style={{display: 'flex',
justifyContent: 'center'}} id="text_editor_container">
            {typingLoading ? 
             <SpinnerLoader width={60} height={60} />
             :  <ActionFooter scrollToBottom={scrollToBottom} />}
          </div>
        </BlockUi>
      </div>
    </div>
  );
};

MessageList.propTypes = {
  chatLoading: PropTypes.bool,
  typingLoading: PropTypes.bool,
  scrollToBottom: PropTypes.func,
  setMessageReadable: PropTypes.func,
  onScrollMessageList: PropTypes.func,
  msgListRef: PropTypes.instanceOf(Object),
  match: PropTypes.instanceOf(Object).isRequired,
};

export default MessageList;
