import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import ActivityTimer from "./ActivityTimer";
import { actions } from "../../../modules/authForms";

import "./activity_timer.scss";
import { noop } from "../../../utils";

// Define the component directly with memo and default parameters
const ActivityTimerContainer = React.memo(function ActivityTimerContainer({
  serverTotalTimeTracking = undefined,
  serverTotalMobileSecs = undefined,
  handleTimeTracking = noop,
  serverTimeTracking = undefined,
  serverMobileSecs = undefined,
  lastActivityTime = null,
  pauseTracking = false,
  getTotalTime = noop,
}) {
  // Convert lastActivityTime to string if it's a Date object or any other type
  const formattedLastActivityTime = lastActivityTime
    ? new Date(lastActivityTime).toISOString()
    : null;

  return (
    <ActivityTimer
      getTotalTime={getTotalTime}
      lastActivityTime={formattedLastActivityTime}
      pauseTracking={pauseTracking}
      serverTimeTracking={serverTimeTracking?.toString()}
      serverMobileSecs={serverMobileSecs?.toString()}
      handleTimeTracking={handleTimeTracking}
      serverTotalTimeTracking={serverTotalTimeTracking?.toString()}
      serverTotalMobileSecs={serverTotalMobileSecs?.toString()}
    />
  );
});

ActivityTimerContainer.propTypes = {
  serverTimeTracking: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  serverTotalTimeTracking: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  serverTotalMobileSecs: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  serverMobileSecs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lastActivityTime: PropTypes.any, // Accept any type and convert it in the component
  pauseTracking: PropTypes.bool,
  handleTimeTracking: PropTypes.func,
  getTotalTime: PropTypes.func,
};

const mapStateToProps = (state) => ({
  serverTimeTracking: state.auth.serverTimeTracking,
  serverMobileSecs: state.auth.serverMobileSecs,
  serverTotalTimeTracking: state.auth.serverTotalTimeTracking,
  serverTotalMobileSecs: state.auth.serverTotalMobileSecs,
  pauseTracking: state.auth.pauseTracking,
  lastActivityTime: state.auth.lastActivityTime,
});

const mapDispatchToProps = (dispatch) => ({
  getTotalTime: () => dispatch(actions.getTotalTime()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityTimerContainer);
