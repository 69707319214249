/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import base64 from 'base-64';
import union from 'lodash.union';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useParams, useNavigate } from 'react-router';

import api from '../../../../utils/api';
import MessageList from './MessageList';
import TicketsContainer from '../TicketsContainer';
import * as actions from '../../redux/actions';
import { noop, deepCopy } from '../../../../utils';
import { showNotification } from '../../../../utils/Notifications';

import './message_list.scss';

let timerId;
const debounce = (fn, delay) => { // eslint-disable-line
  return ((...args) => {
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      fn(...args);
      clearTimeout(timerId);
      timerId = null;
    }, delay);
  })();
};

const preventRendering = (prevProps, nextProps) => {
  const { chatInfo: prevChatInfo } = prevProps;
  const { chatInfo: nextChatInfo } = nextProps;

  return JSON.stringify(prevChatInfo) === JSON.stringify(nextChatInfo);
};


const MessageListContainer = React.memo(({
  getViewStatusOfAgent = noop,
  getChatPreviewInfo = noop,
  setViewStatusAgent = noop,
  isHiddenFeatures = false,
  setScrollPostion = noop,
  getTypingMessage = noop,
  setTicketLoading = noop,
  setMessagesRead = noop,
  setChatInfoList = noop,
  getParticipants = noop,
  pushActiveTabs = noop,
  maxDateChanged = '',
  setActiveTabs = noop,
  setMaxDate = noop,
  activeTabsInfo = [],
  chatInfo = {},
  footerView = {},
  userInfo = {},
  match = {},
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const [typingLoading, setTypingLoading] = useState(false);
  const [maxDateState, setMaxDateState] = useState('');
  const msgListRef = useRef(null);
  const chatDetails = chatInfo[params.conversationid];
  let typingTimeOut;
  let liveStatusTimeOut;
  let userScrolled;

  const scrollToBottom = (onlyIfNotScrolled) => {
    if (!msgListRef.current) return;
    if (onlyIfNotScrolled && userScrolled) return;
    msgListRef.current.scrollTop = msgListRef.current.scrollHeight;
    const { clientHeight, scrollHeight } = msgListRef.current;
    msgListRef.current.scrollTop = scrollHeight - clientHeight;
  };

  const setPreviousScrollPosition = () => {
    if (!msgListRef.current) return;
    const x = document.getElementsByClassName('new_messagges');
    if (x && x.length > 0) {
      msgListRef.current.scrollTop = x[0].offsetTop;
    } else {
      msgListRef.current.scrollTop = chatDetails
        && chatDetails.scrollTo;
    }
  };

  const onScrollDebounce = () => {
    if (!chatDetails.messageLoading) {
      setScrollPostion('ticket', params.conversationid,
        msgListRef.current && msgListRef.current.scrollTop);
    }
  };

  const onScrollMessageList = () => {
    userScrolled = true;
    debounce(() => onScrollDebounce(), 500);
  };

  const setActiveBadge = (code, conversationid, title) => {
    const copyOfTabsInfo = deepCopy(activeTabsInfo) || [];
    const tabData = copyOfTabsInfo.filter((tabItem) => tabItem.key !== '');
    let prevTabInfo = tabData.map(({
      ...rest
    }) => ({ ...rest }));
    const index = prevTabInfo.findIndex((ticketInfo) => ticketInfo.key === conversationid);
    if (index === -1) {
      prevTabInfo = [
        ...prevTabInfo,
        {
          type: 'ticket', key: conversationid, code, conversationid, subject: title,
        },
      ];
      const metaBase64 = base64.encode(
        encodeURIComponent(JSON.stringify(prevTabInfo)),
      );
      if (metaBase64.length < 50000) {
        const encodeUrl = (metaBase64);
        setActiveTabs(encodeUrl).then((res) => {
          if (res.value.data.response_msg === 'SAVED') {
            pushActiveTabs({
              type: 'ticket', key: conversationid, code, conversationid, subject: title,
            });
          }
        });
      } else {
        showNotification('You reach maximum tab Limit', 'error', 5000);
      }
    }
  };

  const fetchMaxDate = (newdate) => {
    const isTimeStamp = moment(maxDateState).isAfter(moment(newdate));
    if (!isTimeStamp) {
      setMaxDateState(newdate);
    }
  };

  const fetchChatInfo = () => {
    setTicketLoading(params.conversationid, 'M', true);
    setMaxDateState(maxDateChanged);
    api.get(`command.jsp?command=ticket&conversationid=${params.conversationid}`)
      .then(async (messageInfoRes) => {
        api.get(`command.jsp?command=ticket_item&conversationid=${params.conversationid}&message_groupid=`)
          .then((resMsgGroup) => {
            Promise.all(messageInfoRes.data.map(async (messageItem) => {
              fetchMaxDate(messageItem.datechanged);
              const messageList = resMsgGroup.data.filter((groupMsgItem) => groupMsgItem
                .message_groupid === messageItem.message_groupid);
              return Promise.all(messageList.map(async (fileInfo) => {
                if (fileInfo.rtype === 'F' || fileInfo.rtype === 'L') {
                  const fileRes = await api.get(`command.jsp?command=file_link_info&file=${fileInfo.message}`);
                  return { ...fileInfo, attachment: fileRes.data };
                } return fileInfo;
              })).then((msgres) => ({ ...messageItem, messages: msgres }));
            })).then((messages) => {
              setChatInfoList(params.conversationid, messages, navigate).then(() => {
                setMaxDate(maxDateState);
                setTicketLoading(params.conversationid, 'M', false);
              });
            });
          });
      });
  };

  const fetchViewStatusOfAgentPolling = () => {
    if (window.liveStatusPolling) { clearTimeout(window.liveStatusPolling); }
    getViewStatusOfAgent().then(() => {
      window.liveStatusPolling = setTimeout(() => {
        if (window.liveStatusPolling) {
          fetchViewStatusOfAgentPolling();
        }
      }, 30000);
    });
  };

  const fetchLiveTicketStatus = async () => {
    const email = userInfo.email;
    getViewStatusOfAgent().then((res) => {
      const viewStatusList = (res.value.data && res.value.data[0] && res.value.data[0][email])
      || [];
      let viewAgentInfo = [];
      if (viewStatusList && viewStatusList.length > 0) {
        viewAgentInfo = viewStatusList && viewStatusList.split(',');
      }
      setViewStatusAgent(union(viewAgentInfo, [params.code]).join(','));
    });
  };

  const fetchReadableTicket = (date) => {
    const readableTicket = localStorage.getItem('readableTicket');
    if (readableTicket) {
      const ticketObject = JSON.parse(readableTicket);
      ticketObject[params.conversationid] = { date };
      localStorage.setItem('readableTicket', JSON.stringify(ticketObject));
    } else {
      const readableTicket2 = {};
      readableTicket2[params.conversationid] = { date };
      localStorage.setItem('readableTicket', JSON.stringify(readableTicket2));
    }
  };

  const fetchTicketPreviewInfo = () => {
    setTicketLoading(params.conversationid, 'P', true);
    getParticipants(params.conversationid).then(() => {
      getChatPreviewInfo(params.code).then((res) => {
        const previewRes = res.value.data[0];
        if (isHiddenFeatures) {
          fetchReadableTicket(previewRes.datechanged);
        }
        setActiveBadge(previewRes.code, previewRes.conversationid, previewRes.subject);
        fetchLiveTicketStatus();
        setTicketLoading(params.conversationid, 'P', false);
      });
    });
  };

  const fetchTypingMessage = () => {
    if (window.typingPolling) { clearTimeout(window.typingPolling); }
    getTypingMessage(params.conversationid)
      .then(() => {
        setTypingLoading(false);
        window.typingPolling = setTimeout(() => {
          if (window.typingPolling) { fetchTypingMessage(); }
        }, 1500);
      });
  };

  const handleActionsPolling = () => {
    setTypingLoading(true);
    window.typingPolling = 1;
    if (window.typingPolling) { clearTimeout(window.typingPolling); }
    typingTimeOut = setTimeout(() => {
      if (window.typingPolling) { fetchTypingMessage(); }
    }, 1500);
    window.liveStatusPolling = 1;
    if (window.liveStatusPolling) { clearTimeout(window.liveStatusPolling); }
    liveStatusTimeOut = setTimeout(() => {
      if (window.liveStatusPolling) { fetchViewStatusOfAgentPolling(); }
    }, 1500);
  };

  const setMessageReadable = () => {
    const newMessage = activeTabsInfo && activeTabsInfo.filter((tabItem) => tabItem.messageCount);
    if (newMessage && newMessage.length > 0) { setMessagesRead(params.conversationid); }
  };

  const confirmExit = () => {
    if (footerView.isLoading) {
      return 'If you navigate away, you will lose your unsaved changes. Do you want to continue?';
    }
    return null;
  };

  window.onbeforeunload = confirmExit;
  useEffect(() => {
    if (!chatDetails || !chatDetails.messageInfo
      || (chatDetails && chatDetails.messageInfo && chatDetails.messageInfo.length === 0)) {
      fetchChatInfo();
    } 
    if (!chatDetails || !chatDetails.previewInfo) {
      fetchTicketPreviewInfo();
    } else {
      const activeTabs = activeTabsInfo && activeTabsInfo.filter((tab) => tab.type === 'ticket');
      const findIndex = activeTabs
        ? activeTabs.findIndex((tab) => tab.code === params.code)
        : -1;
      if (findIndex === -1) { fetchTicketPreviewInfo(); }
    }
    handleActionsPolling();
    setPreviousScrollPosition();
    return () => {
      clearTimeout(window.typingPolling);
      clearTimeout(window.liveStatusPolling);
      clearTimeout(typingTimeOut);
      clearTimeout(liveStatusTimeOut);
      window.typingPolling = false;
      window.liveStatusPolling = false;
    };
  }, [params.conversationid]);

  useEffect(() => {
    if ((chatDetails && !chatDetails.messageLoading
      && (chatDetails.scrollTo === undefined
          || chatDetails.scrollTo === null))) {
      const chatList = document.getElementById('ticket-messages');
      if (chatList && chatList.lastChild) {
        msgListRef.current.scrollTop = chatList.lastChild.offsetTop;
      }
    }
  });

  return (
    <>
      <TicketsContainer>
        <Helmet>
          <title>
            {chatDetails && chatDetails.previewInfo && chatDetails.previewInfo.subject}
          </title>
        </Helmet>
        <MessageList
          match={match}
          msgListRef={msgListRef}
          typingLoading={typingLoading}
          scrollToBottom={scrollToBottom}
          setMessageReadable={setMessageReadable}
          onScrollMessageList={onScrollMessageList}
          chatLoading={chatDetails && (chatDetails.messageLoading
            || chatDetails.previewLoading)}
        />
      </TicketsContainer>
    </>
  );
},
(prevProps, nextProps) => preventRendering(prevProps, nextProps));


const mapStateToProps = (state) => ({
  activeTabsInfo: state.tickets.activeTabsInfo,
  chatInfo: state.tickets.chat,
  maxDateChanged: state.tickets.maxDateChanged,
  footerView: state.tickets.footerView,
  userInfo: state.auth.userInfo,
  isHiddenFeatures: state.localInfo.isHiddenFeatures,
});

const mapDispatchToProps = (dispatch) => ({
  getChatPreviewInfo: (code) => dispatch(actions.getChatPreviewInfo(code)),
  setViewStatusAgent: (idList) => dispatch(actions.setViewStatusAgent(idList)),
  getParticipants: (id) => dispatch(actions.getParticipants(id)),
  getViewStatusOfAgent: () => dispatch(actions.getViewStatusOfAgent()),
  setChatInfoList: (id, messages, navigate) => dispatch(actions.setChatInfoList(id, messages, navigate)),
  setMaxDate: (date) => dispatch(actions.setMaxDate(date)),
  setMessagesRead: (conversationid) => dispatch(actions.setMessagesRead(conversationid)),
  setActiveTabs: (tabs) => dispatch(actions.setActiveTabs(tabs)),
  pushActiveTabs: (tabInfo) => dispatch(actions.pushActiveTabs(tabInfo)),
  setScrollPostion: (type, key, position) => dispatch(actions
    .setScrollPostion(type, key, position)),
  getTypingMessage: (conversationid) => dispatch(actions.getTypingMessage(conversationid)),
  setTicketLoading: (key, type, isLoading) => dispatch(actions
    .setTicketLoading(key, type, isLoading)),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(MessageListContainer);