import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ExternalTicketForm from './ExternalTicketForm';
import { actions } from '../../../../../modules/tickets';
import { matchRegEx, email as emailRegEx } from '../../../../../utils/validators';
import { noop } from '../../../../../utils';

import './external_ticket_form.scss';

const ExternalTicketFormContainer = ({
  setNewTicketModal,
  setNewExternalTicket,
  createNewTicketLoading,
  userInfo,
}) => {
  const navigate = useNavigate();
  const [toEmailRef, setToEmailRef] = useState(null);
  const [focus, setFocus] = useState(true);
  const [ccemail, setCcEmail] = useState(false);
  const [bccemail, setBccEmail] = useState(false);

  const onSubmit = (values) => {
    createNewTicketLoading(true);
    setNewExternalTicket(
      userInfo.name,
      values.To,
      values.Subject,
      values.BCC,
      values.CC,
    ).then((externalRes) => {
      if (externalRes.value.data) {
        const res = externalRes.value.data[0];
        navigate(`/ticket/${res.code}/${res.conversationid}`);
        setNewTicketModal(false, undefined);
        createNewTicketLoading(false);
      }
    });
  };

  const validateForm = (values) => {
    const errors = {};
    if (!values.To) {
      errors.To = "To is required";
    }
    if (!values.Subject) {
      errors.Subject = "Subject is required";
    }
    if (values.To && !matchRegEx(values.To, emailRegEx)) {
      errors.To = "Invalid email address";
    }
    return errors;
  };

  const handleEmail = (type) => {
    if (type === 'CC') {
      setCcEmail(!ccemail);
    } else if (type === 'BCC') {
      setBccEmail(!bccemail);
    }
  };

  useEffect(() => {
    if (toEmailRef && focus) {
      toEmailRef.focus();
      setFocus(false);
    }
  }, [toEmailRef, focus]);

  return (
    <ExternalTicketForm
      onSubmit={onSubmit}
      validate={validateForm}
      setToEmailRef={setToEmailRef}
      handleEmail={handleEmail}
      ccemail={ccemail}
      bccemail={bccemail}
      initialValues={{ To: '', Subject: '', CC: '', BCC: '' }}
    />
  );
};

ExternalTicketFormContainer.propTypes = {
  setNewTicketModal: PropTypes.func,
  setNewExternalTicket: PropTypes.func,
  createNewTicketLoading: PropTypes.func,
  setButtonInputClick: PropTypes.func,
  setInputType: PropTypes.func,
  userInfo: PropTypes.instanceOf(Object),
};

ExternalTicketFormContainer.defaultProps = {
  setNewTicketModal: noop,
  setNewExternalTicket: noop,
  createNewTicketLoading: noop,
  setButtonInputClick: noop,
  setInputType: noop,
  userInfo: {},
};

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
});

const mapDispatchToProps = (dispatch) => ({
  setNewTicketModal: (isOpen, type) => dispatch(actions.setNewTicketModal(isOpen, type)),
  setNewExternalTicket: (name, to, subject, bcc, cc) => dispatch(
    actions.setNewExternalTicket(name, to, subject, bcc, cc),
  ),
  createNewTicketLoading: (isLoading) => dispatch(actions.createNewTicketLoading(isLoading)),
  setButtonInputClick: (isclick) => dispatch(actions.setButtonInputClick(isclick)),
  setInputType: (type) => dispatch(actions.setInputType(type)),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(ExternalTicketFormContainer);
