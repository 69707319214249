import React from 'react';

import CustomDialog from '../../CustomDialog/CustomDialog';

const HiddenFeatureDialog = ({
  removeLocalStorage = () => {},
  isHiddenFeatures = false,
  handleHiddenFeatures = () => {},
  handleModal = () => {},
  isSorting = false,
  isTheme = false,
  isModal = false,
  isTodo = false,
  isFlag = false,
  isOpenTicket = false,
  isFlagOnTop = false,
  isTodoOnTop = false,
}) => (
  <CustomDialog
    isOpen={isModal}
    toggle={handleModal}
    title="Hidden Features"
    classNames="hidden_feature_dialog"
  >
    <label>
      <input
        type="checkbox"
        checked={isHiddenFeatures}
        onChange={() => {
          localStorage.setItem('isHiddenFeatures', !isHiddenFeatures);
          handleHiddenFeatures('isHiddenFeatures', !isHiddenFeatures);
        }}
      />
      <span>Enable hidden features</span>
    </label>
    <label className="ml_1">
      <input
        type="checkbox"
        checked={isTheme}
        onChange={() => {
          localStorage.setItem('isTheme', !isTheme);
          handleHiddenFeatures('isTheme', !isTheme);
        }}
      />
      <span>Enable Themes</span>
    </label>
    <label className="ml_1">
      <input
        type="checkbox"
        checked={isFlag}
        onChange={() => {
          localStorage.setItem('isFlag', !isFlag);
          handleHiddenFeatures('isFlag', !isFlag);
        }}
      />
      <span>Enable Flags</span>
    </label>
    <label className="ml_2">
      <input
        type="checkbox"
        checked={isFlagOnTop}
        onChange={() => {
          localStorage.setItem('isFlagOnTop', !isFlagOnTop);
          handleHiddenFeatures('isFlagOnTop', !isFlagOnTop);
        }}
      />
      <span>Show flagged items on top</span>
    </label>
    <label className="ml_1">
      <input
        type="checkbox"
        checked={isTodo}
        onChange={() => {
          localStorage.setItem('isTodo', !isTodo);
          handleHiddenFeatures('isTodo', !isTodo);
        }}
      />
      <span>Enable Todo</span>
    </label>
    <label className="ml_2">
      <input
        type="checkbox"
        checked={isTodoOnTop}
        onChange={() => {
          localStorage.setItem('isTodoOnTop', !isTodoOnTop);
          handleHiddenFeatures('isTodoOnTop', !isTodoOnTop);
        }}
      />
      <span>Show Todo items on top</span>
    </label>
    <label className="ml_1">
      <input
        type="checkbox"
        checked={isSorting}
        onChange={() => {
          localStorage.setItem('isSorting', !isSorting);
          handleHiddenFeatures('isSorting', !isSorting);
        }}
      />
      <span>Enable sorting</span>
    </label>
    <label className="ml_1">
      <input
        type="checkbox"
        checked={isOpenTicket}
        onChange={() => {
          localStorage.setItem('isOpenTicket', !isOpenTicket);
          handleHiddenFeatures('isOpenTicket', !isOpenTicket);
        }}
      />
      <span>Indicate open tickets</span>
    </label>
    <div onClick={removeLocalStorage} role="presentation" className="remove_btn">
      Reset all
    </div>
  </CustomDialog>
);

export default HiddenFeatureDialog;
