import React, { useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import base64 from "base-64";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { connect } from "react-redux";
import TabRendered from './TabRendered';
import RequestStates from '../../../../utils/request-states';
import * as actions from '../../redux/actions';
import { noop, deepCopy, keyGeneration } from '../../../../utils';
import useHotkeys from '../../../../utils/useHotkeys';

import './tab_rendered.scss';

const preventRendering = (prevProps, nextProps) => {
  const prevState = prevProps.activeTabsInfo;
  const nextState = nextProps.activeTabsInfo;
  if (
    JSON.stringify(nextProps.homeTabLoading) !== JSON.stringify(prevProps.homeTabLoading)
  ) {
    return false;
  }
  if (nextState && JSON.stringify(nextState) !== JSON.stringify(prevState)) {
    return false;
  }
  return false;
};

const TabRenderedContainer = React.memo(
  ({
    getViewStatusOfAgent,
    setViewStatusAgent,
    setNewTicketModal,
    lastActiveTabKey,
    removeActiveTab,
    activeTabsInfo,
    homeTabLoading,
    assignTickets,
    setActiveTabs,
    userInfo,
  }) => {
    const matchParams = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const [, updateState] = React.useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const { search, pathname } = location;

    const handleActiveTab = (tabItem) => {
      if (tabItem.code) {
        navigate(`/ticket/${tabItem.code}/${tabItem.conversationid}`);
      } else if (tabItem.meta === "d") {
        if (tabItem.lastDays) {
          navigate(
            `/ticket?q=${tabItem.subject}&status=${
              tabItem.status || ''
            }&agentid=${tabItem.agentid || ''}&unassigned=${
              tabItem.unassigned
            }&invert=${tabItem.invert}&date_start=${
              tabItem.dateStart
            }&date_end=${tabItem.dateEnd}&meta=d&tab_name=${
              tabItem.tabName || ''
            }&last_x_days=${tabItem.lastDays || ''}`,
          );
        } else {
          navigate(
            `/ticket?q=${tabItem.subject}&status=${
              tabItem.status || ''
            }&agentid=${tabItem.agentid || ''}&unassigned=${
              tabItem.unassigned
            }&invert=${tabItem.invert}&date_start=${
              tabItem.dateStart
            }&date_end=${tabItem.dateEnd}&meta=d&tab_name=${
              tabItem.tabName || ''
            }`,
          );
        }
      } else if (tabItem.meta === "nd") {
        navigate(
          `/ticket?q=${tabItem.subject}&status=${
            tabItem.status || ''
          }&agentid=${tabItem.agentid || ''}&unassigned=${
            tabItem.unassigned
          }&invert=${tabItem.invert}&meta=nd&tab_name=${tabItem.tabName || ''}`,
        );
      } else {
        navigate(
          `/ticket?q=${tabItem.subject}&tab_name=${tabItem.tabName || ""}`
        );
      }
    };

    const handleSwitchTab = (type, activeTabList) => {
      if (location.pathname === '/ticket' && !location.search) {
        if (type === 'left') return;
        if (type === 'right') {
          const tabInfo = activeTabList[0];
          if (tabInfo) handleActiveTab(tabInfo);
          return;
        }
      }

      const uniqueKey = matchParams.conversationid || keyGeneration(search);
      const activeTabIndex = activeTabList.findIndex(
        (tab) => tab.key === uniqueKey,
      );
      if (activeTabIndex === 0 && type === 'left') {
        navigate('/ticket');
        return;
      }
      if ((activeTabIndex + 1) === activeTabList.length && type === 'right') {
        return;
      }

      const tabIndex = type === 'left' ? activeTabIndex - 1 : activeTabIndex + 1;
      const tabInfo = activeTabList[tabIndex];
      handleActiveTab(tabInfo);
    };

    const setActivetabUrlInfo = (tabInfo) => {
      if (tabInfo.type === 'ticket') {
        handleActiveTab({
          code: tabInfo.code,
          conversationid: tabInfo.conversationid,
        });
      } else if (tabInfo.type === 'search' && tabInfo.meta) {
        handleActiveTab({
          subject: tabInfo.subject,
          meta: tabInfo.meta,
          status: tabInfo.status,
          agentid: tabInfo.agentid,
          unassigned: tabInfo.unassigned,
          invert: tabInfo.invert,
          dateStart: tabInfo.dateStart,
          dateEnd: tabInfo.dateEnd,
          tabItem: tabInfo.tabName,
          lastDays: tabInfo.lastDays,
        });
      } else {
        handleActiveTab({ subject: tabInfo.subject, tabName: tabInfo.tabName });
      }
    };

    const tabRedirectHandler = (key) => {
      if (lastActiveTabKey) {
        if (!search && pathname === "/ticket") {
          navigate("/ticket");
        } else if (
          lastActiveTabKey && lastActiveTabKey === `${userInfo.agentid}HomeTab`
        ) {
          navigate("/ticket");
        } else if (lastActiveTabKey && lastActiveTabKey !== key) {
          const activeTabs = deepCopy(activeTabsInfo);
          const tab = activeTabs.find(
            (tabItem) => tabItem.key === lastActiveTabKey,
          );
          if (tab) {
            setActivetabUrlInfo(tab);
          } else {
            navigate("/ticket");
          }
        } else {
          navigate("/ticket");
        }
      } else {
        navigate("/ticket");
      }
    };

    const removeLiveTicketStatus = (code) => {
      const email = userInfo.email;
      getViewStatusOfAgent().then((res) => {
        const viewStatusList = res.value.data[0][email];
        if (viewStatusList) {
          const viewAgentInfo = viewStatusList.split(',');
          const viewIndex = viewAgentInfo.filter(
            (viewItem) => viewItem !== code,
          );
          setViewStatusAgent(viewIndex.join(','));
        }
      });
    };

    const removeTicket = (key, type) => {
      if (key) {
        const prevTabInfo = activeTabsInfo.map(
          ({ ...rest }) => ({ ...rest })
        );
        const tabsInfo = prevTabInfo.find((tabItem) => tabItem.key === key);
        const remainingTicket = prevTabInfo.filter(
          (ticketInfo) => ticketInfo.key !== key,
        );
        setActiveTabs(
          base64.encode(encodeURIComponent(JSON.stringify(remainingTicket))),
        );
        tabRedirectHandler(key);
        if (tabsInfo && tabsInfo.type === 'ticket') {
          removeLiveTicketStatus(tabsInfo.code);
        }
        removeActiveTab(key, type);
      }
      forceUpdate();
    };

    const isActiveTab = (key) => {
      const keygen = keyGeneration(search);
      if (matchParams.conversationid) {
        if (matchParams.conversationid === key) return true;
      }
      if (search && key === keygen) return true;
      return false;
    };

    const handleCreateTicket = (e, type) => {
      e.stopPropagation();
      e.preventDefault();
      setNewTicketModal(true, type);
      return false;
    };

    const activeTabNode = document.querySelector(
      '.tab_rendered_seciton .active',
    );

    const scrollToTab = () => {
      if (activeTabNode) {
        setTimeout(() => {
          activeTabNode.scrollIntoView({ block: 'center', behavior: 'smooth' });
        });
      }
    };

    useEffect(() => {
      scrollToTab();
    }, [activeTabNode]);

    useHotkeys('ctrl+a', () => handleSwitchTab('left', activeTabsInfo), [activeTabsInfo]);
    useHotkeys('ctrl+d', () => handleSwitchTab('right', activeTabsInfo), [activeTabsInfo]);
    return (
      <TabRendered
        location={location}
        removeTicket={removeTicket}
        isActiveTab={isActiveTab}
        assignTickets={assignTickets}
        activeTabsInfo={activeTabsInfo}
        homeTabLoading={homeTabLoading}
        handleActiveTab={handleActiveTab}
        handleCreateTicket={handleCreateTicket}
      />
    );
  },
  (prevProps, nextProps) => preventRendering(prevProps, nextProps),
);

TabRenderedContainer.propTypes = {
  lastActiveTabKey: PropTypes.string,
  homeTabLoading: PropTypes.bool,
  setViewStatusAgent: PropTypes.func,
  getViewStatusOfAgent: PropTypes.func,
  removeActiveTab: PropTypes.func,
  setActiveTabs: PropTypes.func,
  setNewTicketModal: PropTypes.func,
  activeTabsInfo: PropTypes.instanceOf(Array),
  assignTickets: PropTypes.instanceOf(Array),
  userInfo: PropTypes.instanceOf(Object),
};

TabRenderedContainer.defaultProps = {
  lastActiveTabKey: '',
  homeTabLoading: false,
  setViewStatusAgent: noop,
  getViewStatusOfAgent: noop,
  removeActiveTab: noop,
  setActiveTabs: noop,
  setNewTicketModal: noop,
  activeTabsInfo: [],
  assignTickets: [],
  userInfo: {},
};

const mapStateToProps = (state) => ({
  activeTabsInfo: state.tickets.activeTabsInfo,
  assignTickets: state.tickets.assignTickets,
  lastActiveTabKey: state.tickets.lastActiveTabKey,
  userInfo: state.auth.userInfo,
  homeTabLoading: state.tickets.assignTicketsState === RequestStates.loading,
});

const mapDispatchToProps = (dispatch) => ({
  removeActiveTab: (key, type) => dispatch(actions.removeActiveTab(key, type)),
  setActiveTabs: (tabs) => dispatch(actions.setActiveTabs(tabs)),
  setViewStatusAgent: (idList) => dispatch(actions.setViewStatusAgent(idList)),
  getViewStatusOfAgent: () => dispatch(actions.getViewStatusOfAgent()),
  setNewTicketModal: (isOpen, type) => dispatch(actions.setNewTicketModal(isOpen, type)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TabRenderedContainer);
