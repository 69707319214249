import * as actionTypes from './actionTypes';

export const setSideNavbarItem = (itemId) => ({
  type: actionTypes.SET_SIDE_NAVBAR_ITEM,
  payload: itemId,
});

export const setMobileSideNavbar = (isMobileExpanded) => ({
  type: actionTypes.SET_MOBILE_SIDE_NAVBAR,
  payload: isMobileExpanded,
});

export const setSideNavbarExpand = (isMobileExpanded) => ({
  type: actionTypes.SET_MOBILE_SIDE_NAVBAR,
  payload: isMobileExpanded,
});

