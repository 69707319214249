import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Auth from '../../hoc/auth';
import MainTemplate from '../../shared/template/MainTemplate/MainTemplateContainer';
import { actions } from '../../shared/components/AppNavigation'; // Import actions
import { MessageListContainer } from '../../modules/tickets';
import { noop } from '../../utils';

const { setMobileSideNavbar, setSideNavbarItem } = actions; // Destructure actions

const TicketDetails = ({ setSideNavbarItem }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    setSideNavbarItem('/ticket');
    return () => setSideNavbarItem('');
  }, [setSideNavbarItem]);

  return (
    <MainTemplate>
      <MessageListContainer />
    </MainTemplate>
  );
};

TicketDetails.propTypes = {
  setSideNavbarItem: PropTypes.func,
};

TicketDetails.defaultProps = {
  setSideNavbarItem: noop,
};

const mapDispatchToProps = (dispatch) => ({
  setSideNavbarItem: (selectedSideNavbarItem) => dispatch(setSideNavbarItem(selectedSideNavbarItem)), // Updated action
  setMobileSideNavbar: (isExpanded) => dispatch(setMobileSideNavbar(isExpanded)), // Added available action
});

export default connect(null, mapDispatchToProps)(Auth(TicketDetails));