import React, { useEffect } from 'react';
import { SigninContainer } from '../../modules/authForms';

const SignIn = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <SigninContainer />;
};

export default SignIn;
