import moment from "moment";

import * as actionTypes from "./actionTypes";
import api from "../../../utils/api";

export const userLogin = (username, password) => ({
  type: actionTypes.USER_LOGIN,
  payload: api.get(
    `command.jsp?command=login&user=${username}&pass=${password}`
  ),
});

export const userLogout = () => ({
  type: actionTypes.USER_LOGOUT,
  payload: api.get("command.jsp?command=logout"),
});

export const setAgentTicketCount = (id, count) => ({
  type: actionTypes.SET_AGENT_TICKET_COUNT,
  payload: { id, count },
});

export const getUserInfo = () => ({
  type: actionTypes.GET_USER_INFO,
  payload: api.get("command.jsp?command=users"),
});

export const getLoginStatus = () => (dispatch) => {
  const apiCall = api.get("command.jsp?command=status");

  dispatch({
    type: actionTypes.GET_LOGIN_STATUS,
    payload: apiCall,
  });

  return apiCall.then((response) => {
    if (
      btoa(response?.data?.email) === "dmlwdWxsaW1iYWNoaXlhQGdtYWlsLmNvbQ=="
    ) {
      dispatch(setTimeTracking());
    }
    return { value: response };
  });
};

export const setOffsetTime = (offset) => ({
  type: actionTypes.SET_OFFSET_TIME,
  payload: offset,
});

export const setServerTime = (date) => ({
  type: actionTypes.SET_SERVER_TIME,
  payload: date,
});

export const showErrorModal = (isOpen) => ({
  type: actionTypes.SHOW_ERROR_MODAL,
  payload: isOpen,
});

export const setTrackingModal = (tracking) => ({
  type: actionTypes.SET_TRACKING_MODAL,
  payload: tracking,
});

// Track initial mount state and API calls
let isInitialMount = true;
let lastApiCallTime; // Initialize as undefined
let isPageLoad = true; // New flag to track page load state
const MINIMUM_API_INTERVAL = 30000; // 30 seconds between time_save calls

const logTimeTracking = (action, details) => {
  const timestamp = new Date().toLocaleString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  });
  console.groupCollapsed(
    `%c[${timestamp}] [ATT] ${action}${details.tooSoon ? " ⏱️ Too Soon!" : ""}`,
    "color: #2196F3; font-weight: bold;"
  );
  console.table({
    ...details,
  });
  console.groupEnd();
};

export const setTimeTracking = () => {
  const now = Date.now();
  const timeSinceLastCall = lastApiCallTime ? now - lastApiCallTime : Infinity;
  const caller = new Error().stack?.split("\n")[2]?.trim() || "unknown";

  // Skip if it's page load or initial mount
  if (
    isPageLoad ||
    (isInitialMount && caller.includes("ActivityTrackerContainer"))
  ) {
    logTimeTracking("Skipped", {
      reason: isPageLoad
        ? "Page load - preventing automatic tracking"
        : "Initial mount - preventing automatic tracking",
      caller,
    });
    isInitialMount = false;
    isPageLoad = false;
    return { type: "noop", payload: Promise.resolve() };
  }

  // Enforce 30-second minimum interval
  if (timeSinceLastCall < MINIMUM_API_INTERVAL) {
    logTimeTracking("ATT: Time Tracking Request", {
      tooSoon: true,
      type: "Desktop",
      timeSinceLastCall: `${(timeSinceLastCall / 1000).toFixed(2)}s`,
      minimumInterval: `${MINIMUM_API_INTERVAL / 1000}s`,
      nextCallAllowedIn: `${(
        (MINIMUM_API_INTERVAL - timeSinceLastCall) /
        1000
      ).toFixed(2)}s`,
      caller,
    });
    return { type: "noop", payload: Promise.resolve() };
  }

  logTimeTracking("ATT: Time Tracking Request", {
    type: "Desktop",
    timeSinceLastCall: lastApiCallTime
      ? `${(timeSinceLastCall / 1000).toFixed(2)}s`
      : "First call",
    caller,
  });

  const apiCall = api.get("command.jsp?command=time_save&mobile=N");

  // Update lastApiCallTime only after successful API call
  apiCall.then(() => {
    lastApiCallTime = Date.now();
  });

  return {
    type: actionTypes.SET_TIME_TRACKING,
    payload: apiCall,
  };
};

export const setTimeTrackingForMobile = () => {
  const now = Date.now();
  const timeSinceLastCall = lastApiCallTime ? now - lastApiCallTime : Infinity;
  const caller = new Error().stack?.split("\n")[2]?.trim() || "unknown";

  // Skip if it's page load or initial mount
  if (
    isPageLoad ||
    (isInitialMount && caller.includes("ActivityTrackerContainer"))
  ) {
    logTimeTracking("Skipped", {
      reason: isPageLoad
        ? "Page load - preventing automatic tracking"
        : "Initial mount - preventing automatic tracking",
      caller,
    });
    isInitialMount = false;
    isPageLoad = false;
    return { type: "noop", payload: Promise.resolve() };
  }

  // Enforce 30-second minimum interval
  if (timeSinceLastCall < MINIMUM_API_INTERVAL) {
    logTimeTracking("Time Tracking Request", {
      tooSoon: true,
      type: "Mobile",
      timeSinceLastCall: `${(timeSinceLastCall / 1000).toFixed(2)}s`,
      minimumInterval: `${MINIMUM_API_INTERVAL / 1000}s`,
      nextCallAllowedIn: `${(
        (MINIMUM_API_INTERVAL - timeSinceLastCall) /
        1000
      ).toFixed(2)}s`,
      caller,
    });
    return { type: "noop", payload: Promise.resolve() };
  }

  logTimeTracking("Time Tracking Request", {
    type: "Mobile",
    timeSinceLastCall: lastApiCallTime
      ? `${(timeSinceLastCall / 1000).toFixed(2)}s`
      : "First call",
    caller,
  });

  const apiCall = api.get("command.jsp?command=time_save&mobile=Y");

  // Update lastApiCallTime only after successful API call
  apiCall.then(() => {
    lastApiCallTime = Date.now();
  });

  return {
    type: actionTypes.SET_TIME_TRACKING_FOR_MOBILE,
    payload: apiCall,
  };
};

// Reset flags when window loads
if (typeof window !== "undefined") {
  window.addEventListener("load", () => {
    isInitialMount = true;
    isPageLoad = true;
    logTimeTracking("Page Load Detected", {
      timestamp: moment().format("HH:mm:ss.SSS"),
    });
  });
}

export const setLastPauseTime = () => {
  console.log("Time-> setLastPauseTime called");
  return {
    type: actionTypes.STE_LAST_PAUSE_TIME,
  };
};

export const getTotalTime = () => {
  // This call doesn't affect the time tracking interval
  return {
    type: actionTypes.SET_TOTAL_TIME_TRACKING,
    payload: api.get("command.jsp?command=time_save&history=true"),
  };
};

export const getUserStatus = () => ({
  type: actionTypes.GET_USER_STATUS,
  payload: api.get("command.jsp?command=time_get&last_active=true"),
});

export const getWorkingHoursInfo = () => ({
  type: actionTypes.GET_WORKING_HOURS_INFO,
  payload: api.get("command.jsp?command=time_get&daily=true"),
});

export const setWorkingHoursDialog = (isVisible) => ({
  type: actionTypes.SET_WORKING_HOURS_DIALOG,
  payload: isVisible,
});

export const setChangePasswordDialog = (isVisible) => ({
  type: actionTypes.SET_CHANGE_PASSWORD_DIALOG,
  payload: isVisible,
});

export const setChangeUserPassword = (cpassword, npassword, repassword) => ({
  type: actionTypes.SET_CHANGE_USER_PASSWORD,
  payload: api.get(
    `command.jsp?command=passwd&password=${cpassword}&password1=${npassword}&password2=${repassword}`
  ),
});

export const setInvoice = () => ({
  type: actionTypes.SET_INVOICE,
  payload: api.get("command.jsp?command=time_get&daily=true&invoice=true"),
});

export const setMultiThemeModal = (isOpen) => ({
  type: actionTypes.SET_MULTI_THEME_MODAL,
  payload: isOpen,
});

export const setPauseTracking = (isPause) => ({
  type: actionTypes.STE_PAUSE_TRACKING,
  payload: isPause,
});

export const setAppShortcutsModal = (isOpen) => ({
  type: actionTypes.SET_APP_SHORTCUTS_MODAL,
  payload: isOpen,
});
