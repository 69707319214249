export const BASE_URL = window.location
  .toString()
  .match(/(localhost|127\.0\.0\.1)/)
  ? "http://127.0.0.1:3001/support.crushftp.com:443"
  : "/";

export const idleActivityTimeoutInMilliSeconds = 900000;
export const activityTrackerUpdateIntervalMilliSeconds = 60000;
export const activityTrackerTotalTimeUpdateIntervalMilliSeconds = 3600000;

export const maxAllowedFileSize = 20; // in MB
